import { HTMLAttributes } from "react";
import { SelectWrapperStyled } from "./styles";

interface OptionProps {
  key: any;
  value: any;
  label: string;
}

export interface TextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  options?: OptionProps[];
  value: string;
  width?: string;
}

const TextArea = ({
  label,
  options,
  width = "100%",
  ...rest
}: TextAreaProps) => {
  return (
    <SelectWrapperStyled width={width}>
      {label && <label>{label}</label>}
      <textarea {...rest}>sdsdsd</textarea>
    </SelectWrapperStyled>
  );
};

export default TextArea;
