import api from "../services/api";

type TTableName =
  | "natura_scenery"
  | "natura_result"
  | "natura_relativity"
  | "processed_scenery"
  | "processed_details";

type TCallback = (data: {
  error?: boolean;
  order: number[];
  layout: { Header: string; accessor: string }[];
  sticky_pos: number;
}) => void;

const getTableLayout = (tableName: TTableName, callback: TCallback) => {
  api
    .get(`/price/scenery-layout?table_name=${tableName}`)
    .then((res) => {
      callback({ ...res.data });
    })
    .catch(() => {
      callback({ error: true, order: [], layout: [], sticky_pos: 1 });
    });
};

export default getTableLayout;
