import { useCallback, useState } from "react";
import moment from "moment";
import api from "../../../../../services/api";
import Input from "../../../../../components/Input";
import { SearchBtn, Container } from "./styles";
import { RiSearchLine } from "react-icons/ri";
const requester = (user: any) => {
  return `${user.firstName} ${user.lastName}`;
};

const base_origin = (so: any) => {
  return so.condition && so.level
    ? `${so.condition}-${so.level.slice(so.level.length - 2, so.level.length)}`
    : "";
};

const filial = (name: string) => {
  return name?.replace(/,/g, "; ");
};

const date_request = (date: Date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

const SearchInput: React.FC<any> = ({
  setPage,
  setTotal,
  setLoading,
  setTotalPage,
  setTableData,
}) => {
  const [value, setValue] = useState("");

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onBlur = useCallback(() => {
    const url = `price/list/service-orders/search?input=${value}&limit=${20}&page=1`;

    api.get(url).then((response) => {
      setPage(1);
      setTotal(response.data.data.total);
      setTotalPage(response.data.data.totalPages);
      setTableData(
        response.data.data.service_orders.map((so: any) => ({
          id: so.id,
          requester: requester(so.users.creation.user),
          base_origin: base_origin(so),
          filial: filial(so.filial.name),
          date_request: date_request(so.users.creation.date),
          adicionais: so.adicionais,
          status: so.order_status,
          edit: "-",
          observation: so.reason || "-",
          base: so,
        }))
      );

      setLoading(false);
    });
  }, [value, setPage, setTotal, setTotalPage, setTableData, setLoading]);

  return (
    <Container>
      <Input
        name="search"
        onChange={onChange}
        placeholder="Buscar"
        containerStyle={{ height: "4rem", width: "20rem", float: "right" }}
      />
      <SearchBtn onClick={() => onBlur()}>
        <RiSearchLine />
      </SearchBtn>
    </Container>
  );
};

export default SearchInput;
