import moment from "moment";

import { getDateLimits } from "../../../actions";

export const validateInputs = (
  rowOriginal: any,
  tabRequest: any,
  callback: any
) => {
  const { newPrice, dt_inicio, dt_fim } = rowOriginal;

  const [, endDateLimit] = getDateLimits(tabRequest, dt_inicio); // End date limit based on dt_inicio input
  const [defaultStartDateLimit] = getDateLimits(tabRequest);
  const minStartDate = moment(defaultStartDateLimit, "DD/MM/YYYY");
  const maxEndDate = moment(endDateLimit, "DD/MM/YYYY");

  if (dt_inicio) {
    const startDate = moment(dt_inicio, "DD/MM/YYYY");

    if (startDate.isBefore(minStartDate)) {
      return callback(
        "invalid",
        `Data início mínima é ${defaultStartDateLimit}`
      );
    }
  }

  if (dt_fim) {
    const endDate = moment(dt_fim, "DD/MM/YYYY");

    if (maxEndDate.isBefore(endDate)) {
      return callback("invalid", `Data final máxima é ${endDateLimit}`);
    }
  }

  if (dt_inicio && dt_fim) {
    const startDate = moment(dt_inicio, "DD/MM/YYYY");
    const endDate = moment(dt_fim, "DD/MM/YYYY");

    if (endDate.isBefore(startDate)) {
      return callback("invalid", "Data final menor que inicial");
    }
  }

  if (newPrice) {
    return callback("valid", "");
  }

  return callback("missing_inputs", "");
};
