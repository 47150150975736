import { Checkbox, Input } from "./components";

export const limit = 30;

export const columnsDefaultStart = [
  {
    Header: "",
    accessor: ({
      row,
      checkedRows,
      setCheckedRows,
      allRowsChecked,
      setAllRowsChecked,
    }: any) => (
      <Checkbox
        row={row}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
        allRowsChecked={allRowsChecked}
        setAllRowsChecked={setAllRowsChecked}
      />
    ),
  },
];

export const columnsToReplace: { [key: string]: any } = {
  price_goal: {
    Header: "Preço Meta",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="price_goal"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
  price_challenge: {
    Header: "Preço Desafio",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="price_challenge"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
};
