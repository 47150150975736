const def = (data: any, label: string, value: string) => {
  return data.map((item: any, index: number) => ({
    key: index,
    label: {
      label: +item[label] ? +item[label] + "" : item[label],
      value: +item[value] ? +item[value] + "" : item[value],
    },
  }));
};

const clientGroups = (data: any) => {
  return data.map((item: any, index: number) => ({
    key: index,
    label: {
      label: `${item.id} - ${item.description}`,
      value: item.id,
    },
  }));
};

const hierarchy = (data: any) => {
  return data.map((item: any, index: number) => ({
    key: index,
    label: {
      label: item.hierarquia,
      value: item.hierarquia,
    },
  }));
};

const distributionChannel = (data: any) => {
  return [
    { key: 0, label: "Selecione", value: "" },
    ...data.map((item: any) => ({
      key: item.id,
      label: `${item.description} - ${item.distributionchannelgrid}`,
      value: item.description,
    })),
  ];
};

export const responsesToOptions = (responses: any) => {
  return {
    category: def(responses[0].data.material, "name", "name"),
    material: def(responses[1].data.material, "sku", "sku"),
    clientGroups: clientGroups(responses[2].data.clientGroups),
    hierarchy: hierarchy(responses[3].data),
    distributionChannel: distributionChannel(responses[4].data),
  };
};
