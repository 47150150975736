import { useMemo, forwardRef, useRef, useCallback, useEffect } from "react";
import { matchSorter } from "match-sorter";
import {
  useTable,
  useSortBy,
  useFilters,
  useRowState,
  useRowSelect,
  usePagination,
  useGlobalFilter,
  useMountedLayoutEffect,
} from "react-table";
import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Table as MUITable,
} from "@material-ui/core";
import {
  MdArrowDropUp as UpSvg,
  MdArrowDropDown as DownSvg,
} from "react-icons/md";

import Row from "./Row";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function EnhancedTable({
  data,
  columns,
  checkbox,
  currentPage,
  rowsPerPage,
  updateTableData,
  checkboxOnChange,
}) {
  // Filter setup for react-table
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  // react-table setup
  const {
    page,
    gotoPage,
    prepareRow,
    setPageSize,
    headerGroups,
    getTableProps,
    selectedFlatRows,
  } = useTable(
    {
      data,
      columns,
      defaultColumn,
      autoResetPage: false,
      autoResetFilters: false,
      updateTableData,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState
  );

  // #1 Connects <Pagination /> to react-table
  const connectPaginationToTable = useCallback(() => {
    setPageSize(rowsPerPage);
  }, [setPageSize, rowsPerPage]);

  useEffect(() => {
    connectPaginationToTable();
  }, [connectPaginationToTable]);

  // #2 Updates react-table when changing pages via <Pagination />
  const handleChangePage = useCallback(() => {
    gotoPage(currentPage);
  }, [gotoPage, currentPage]);

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage]);

  // #3 Gets selected rows by the <Checkbox />
  useMountedLayoutEffect(() => {
    checkboxOnChange(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <TableContainer>
      <MUITable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell key={column.Header} width={column.width}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      userSelect: "none",
                      position: "relative",
                    }}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "1rem",
                        paddingTop: "2px",
                        cursor: "pointer",
                      }}
                    >
                      {column.render("Header")}
                      <span style={{ fontSize: 20, paddingTop: 3 }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DownSvg />
                          ) : (
                            <UpSvg />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => (
            <Row key={row.id} row={row} prepareRow={prepareRow} />
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}
