import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import Paper from "../../../../../../components/Paper";

import { Table } from "./styles";
import { columns } from "./constants";

const TableSkuXCapitao: React.FC<any> = () => {
  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow></TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TableSkuXCapitao;
