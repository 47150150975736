import { memo, useMemo } from "react";
import { TableCell } from "@material-ui/core";

import StickyCell from "./StickyCell";

interface ICellProps {
  children: React.ReactNode;
  colIndex: number;
  rowIndex?: number;
  cellsRef: React.MutableRefObject<any[]>;
  stickyPos: number;
}

const Cell: React.FC<ICellProps> = ({
  children,
  colIndex,
  rowIndex,
  cellsRef,
  stickyPos,
}) => {
  const sticky = useMemo(() => {
    return colIndex < stickyPos;
  }, [colIndex, stickyPos]);

  if (!sticky) return <TableCell>{children}</TableCell>;
  return (
    <StickyCell colIndex={colIndex} cellsRef={cellsRef} rowIndex={rowIndex}>
      {children}
    </StickyCell>
  );
};

export default memo(Cell);
