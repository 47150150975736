import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../../services/api";
import Radio from "../../../../../../../components/Radio";
import { Multiselect } from "../../../../../../../components/AutoComplete";

import { Container, Radios } from "./styles";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsMateriais: React.FC<IProps> = ({ setInputs }) => {
  const [sku, setSku] = useState([]);
  const [nivel01, setNivel01] = useState([]);
  const [nivel02, setNivel02] = useState([]);
  const [nivel03, setNivel03] = useState([]);
  const [nivel04, setNivel04] = useState([]);
  const [nivel05, setNivel05] = useState([]);

  const [skuOpt, setSkuOpt] = useState([]);
  const [nivel01Opt, setNivel01Opt] = useState([]);
  const [nivel02Opt, setNivel02Opt] = useState([]);
  const [nivel03Opt, setNivel03Opt] = useState([]);
  const [nivel04Opt, setNivel04Opt] = useState([]);
  const [nivel05Opt, setNivel05Opt] = useState([]);

  const [enabled, setEnabled] = useState("all");

  // Sku stuff
  const getSku = useCallback(() => {
    api
      .post("/material/list/all/filters", { field: "sku", type: "natura" })
      .then((response) => {
        setSkuOpt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: +item.sku, value: +item.sku },
          }))
        );
      });
  }, []);
  useEffect(getSku, [getSku]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      sku: sku.map((item: any) => item.value),
    }));
  }, [setInputs, sku]);

  // Nivel01 stuff
  const getNivel01 = useCallback(() => {
    api
      .post("/material/list/all/filters", { field: "nivel01", type: "natura" })
      .then((response) => {
        setNivel01Opt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: item.nivel01, value: item.nivel01 },
          }))
        );
      });
  }, []);
  useEffect(getNivel01, [getNivel01]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      nivel01: nivel01.map((item: any) => item.value),
    }));
  }, [setInputs, nivel01]);

  // Nivel02 stuff
  const getNivel02 = useCallback(() => {
    api
      .post("/material/list/all/filters", { field: "nivel02", type: "natura" })
      .then((response) => {
        setNivel02Opt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: item.nivel02, value: item.nivel02 },
          }))
        );
      });
  }, []);
  useEffect(getNivel02, [getNivel02]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      nivel02: nivel02.map((item: any) => item.value),
    }));
  }, [setInputs, nivel02]);

  // Nivel03 stuff
  const getNivel03 = useCallback(() => {
    api
      .post("/material/list/all/filters", { field: "nivel03", type: "natura" })
      .then((response) => {
        setNivel03Opt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: item.nivel03, value: item.nivel03 },
          }))
        );
      });
  }, []);
  useEffect(getNivel03, [getNivel03]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      nivel03: nivel03.map((item: any) => item.value),
    }));
  }, [setInputs, nivel03]);

  // Nivel04 stuff
  const getNivel04 = useCallback(() => {
    api
      .post("/material/list/all/filters", { field: "nivel04", type: "natura" })
      .then((response) => {
        setNivel04Opt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: item.nivel04, value: item.nivel04 },
          }))
        );
      });
  }, []);
  useEffect(getNivel04, [getNivel04]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      nivel04: nivel04.map((item: any) => item.value),
    }));
  }, [setInputs, nivel04]);

  // Nivel05 stuff
  const getNivel05 = useCallback(() => {
    api
      .post("/material/list/all/filters", {
        field: "subcategory",
        type: "natura",
      })
      .then((response) => {
        setNivel05Opt(
          response.data.material.map((item: any, index: number) => ({
            key: index,
            label: { label: item.subcategory, value: item.subcategory },
          }))
        );
      });
  }, []);
  useEffect(getNivel05, [getNivel05]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      subcategory: nivel05.map((item: any) => item.value),
    }));
  }, [setInputs, nivel05]);

  // Active stuff
  useEffect(() => {
    setInputs((current: any) => {
      const clone = { ...current };

      if (enabled === "all") {
        delete clone.enabled;
        return clone;
      }

      if (enabled === "active") {
        clone.enabled = true;
        return clone;
      }

      if (enabled === "inactive") {
        clone.enabled = false;
        return clone;
      }
    });
  }, [setInputs, enabled]);

  return (
    <>
      <Container>
        <Multiselect
          label="Nível 01"
          value={nivel01}
          options={nivel01Opt}
          setValue={setNivel01}
        />
        <Multiselect
          label="Nível 02"
          value={nivel02}
          options={nivel02Opt}
          setValue={setNivel02}
        />
      </Container>
      <Container>
        <Multiselect
          label="Nível 03"
          value={nivel03}
          options={nivel03Opt}
          setValue={setNivel03}
        />
        <Multiselect
          label="Nível 04"
          value={nivel04}
          options={nivel04Opt}
          setValue={setNivel04}
        />
      </Container>
      <Container>
        <Multiselect
          label="Sku"
          value={sku}
          options={skuOpt}
          setValue={setSku}
        />
        <Multiselect
          label="Nível 05"
          value={nivel05}
          options={nivel05Opt}
          setValue={setNivel05}
        />
      </Container>
      <Container>
        <Radios>
          <Radio
            label="Ativos e inativos"
            checked={enabled === "all"}
            onChange={() => setEnabled("all")}
          />
          <Radio
            label="Somente ativos"
            checked={enabled === "active"}
            onChange={() => setEnabled("active")}
          />
          <Radio
            label="Somente inativos"
            checked={enabled === "inactive"}
            onChange={() => setEnabled("inactive")}
          />
        </Radios>
      </Container>
    </>
  );
};

export default InputsMateriais;
