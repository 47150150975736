import React from "react";
import { Grid } from "@material-ui/core";
import { FaHome } from "react-icons/fa";

import Paper from "../../components/Paper";
import PageTitle from "../../components/PageTitle";
import PageContainer from "../../components/PageContainer";
import HeaderContainer from "../../components/HeaderContainer";

const Dashboard: React.FC = () => {
  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle icon={<FaHome />} title="Seja bem-vindo" />
      </HeaderContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper containerStyle={{ height: "100%", minHeight: "70vh" }}></Paper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Dashboard;
