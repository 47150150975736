import { shade } from "polished";
import styled from "styled-components";

import { ReactComponent as EditIconSVG } from "../../assets/icons/edit.svg";
import { Link } from "react-router-dom";

interface StatusContainerProps {
  isActive: boolean;
}

export const EditIcon = styled(EditIconSVG)`
  background: #f2f3f5;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0.4rem;
  margin-right: 0.8rem;

  transition: background 0.3s;

  cursor: pointer;

  margin-right: 0.8rem;

  &:hover {
    background: ${shade(0.1, "#f2f3f5")};
  }
`;

export const NewClientLink = styled(Link)`
  margin-left: 1rem;
  width: 15rem;

  button {
    width: 15rem;
  }
`;

export const StatusContainer = styled.div<StatusContainerProps>`
  padding: 0.5rem 2rem;
  // margin: 0 auto;
  width: 10rem;
  border: 1px solid ${({ isActive }) => (isActive ? "#2AC769" : "#FF2929")};
  border-radius: 20px;
  color: ${({ isActive }) => (isActive ? "#2AC769" : "#FF2929")};

  display: flex;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`;
