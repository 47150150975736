import {
  FaCheck as SuccessSvg,
  FaThumbsDown as ErrorSvg,
} from "react-icons/fa";

export const svgs = {
  success: SuccessSvg,
  error: ErrorSvg,
};

export const portal = document.getElementById("portal")!;
