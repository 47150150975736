import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  Container,
  UserImg,
  DropdownBtn,
  DropdownContent,
  IconContainer,
  ArrowDownIcon,
  ArrowUpIcon,
  PlaceHolderText,
  DropdownItem,
} from "./styles";

interface SelectedItemProps {
  id: string;
  value: string;
}

interface DropdownProps {
  userImg?: string;
  width?: string;
  placeholder: string;
  containerStyle?: object;
  btnStyle?: object;
  items: Array<{ id: string; value: string }>;
  setSelectedItem(item: SelectedItemProps): void;
  selectedItem: SelectedItemProps;
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  setSelectedItem,
  selectedItem,
  width,
  userImg,
  placeholder,
  containerStyle = {},
  btnStyle = {},
}) => {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(selectedItem);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container style={containerStyle} onClick={() => setOpen(!open)}>
        <DropdownBtn style={btnStyle} width={width} open={open} type="button">
          {userImg && <UserImg src={userImg} />}
          {currentItem.value ? (
            currentItem.value
          ) : (
            <PlaceHolderText>{placeholder}</PlaceHolderText>
          )}
          <IconContainer>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconContainer>
        </DropdownBtn>

        <DropdownContent width={width} open={open}>
          {/* {children} */}

          {items.map((item) => (
            <DropdownItem
              key={item.id}
              type="button"
              onClick={() => {
                setSelectedItem(item);
                setCurrentItem(item);
              }}
            >
              {item.value}
            </DropdownItem>
          ))}
        </DropdownContent>
      </Container>
    </ClickAwayListener>
  );
};

export default Dropdown;
