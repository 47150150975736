import { useCallback } from "react";
import { Box } from "@material-ui/core";

import Modal from "../../../../../../components/Modal/";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import { usePriceManagement } from "../../../../hooks";

const clearedInputs = {
  newPrice: "",
  dt_inicio: "",
  dt_fim: "",
  validation: "missing_inputs",
};

const ClearButton: React.FC<any> = ({ setTableData }) => {
  const { tabRequest } = usePriceManagement();

  const [isOpen, toggleOpen] = useToggle();

  const clearPrices = useCallback(() => {
    setTableData((current: any) =>
      current.map((row: any) => ({ ...row, ...clearedInputs }))
    );
    toggleOpen();
  }, [setTableData, toggleOpen]);

  if (tabRequest.requestId) {
    return null;
  }

  return (
    <>
      <Button onClick={toggleOpen} containerStyle={{ marginRight: "1rem" }}>
        Limpar Dados
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "420px" }}
        children={
          <Box
            textAlign="center"
            color="red"
            sx={{
              margin: "auto 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <h1>Essa ação não poderá ser desfeita.</h1>
            <Button
              onClick={clearPrices}
              containerStyle={{ marginTop: "4rem", float: "right" }}
            >
              Limpar Dados
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ClearButton;
