import { useState, useCallback } from "react";

type TUseToggle = (defaultValue?: boolean) => [boolean, () => void, () => void];

const useToggle: TUseToggle = (defaultValue: boolean = false) => {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = useCallback(() => setValue((current) => !current), []);
  const resetValue = useCallback(() => setValue(defaultValue), [defaultValue]);

  return [value, toggleValue, resetValue];
};

export default useToggle;
