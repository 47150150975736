import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { FaUpload as UploadSvg } from "react-icons/fa";

import Radio from "../Radio";
import Paper from "../Paper";
import PageTitle from "../PageTitle";
import PageContainer from "../PageContainer";
import HeaderContainer from "../HeaderContainer";

import * as S from "./styles";
import FilterInput from "./FilterInput";
import UploadButton from "./UploadButton";
import DownloadExampleButton from "./DownloadExampleButton";
import { IProps } from "./types";

const PriceBaseUpload: React.FC<IProps> = ({ options }) => {
  const [filters, setFilters] = useState<{ [key: string]: any[] }>({});
  const [optionKey, setOptionKey] = useState(Object.keys(options)[0]);

  const selected = useMemo(() => {
    return options[optionKey];
  }, [options, optionKey]);

  useEffect(() => {
    if (selected.filters) {
      const newFilters: { [key: string]: any[] } = {};

      selected.filters.forEach((f) => {
        newFilters[f.accessor] = [];
      });

      setFilters(newFilters);
    } else {
      setFilters({});
    }
  }, [selected]);

  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle title="Envio de excel" icon={<UploadSvg />} />
      </HeaderContainer>
      <Paper containerStyle={{ padding: "2rem", gap: "1rem" }}>
        <S.ChooseText>Escolha uma opção de upload</S.ChooseText>
        <S.Radios>
          {Object.keys(options).map((opt) => (
            <Radio
              key={opt}
              label={opt}
              checked={optionKey === opt}
              onChange={() => setOptionKey(opt)}
            />
          ))}
        </S.Radios>
        {!_.isEmpty(filters) && (
          <>
            <S.ChooseText>Filtros para exportação</S.ChooseText>
            <S.Filters>
              {selected.filters?.map((filter) => (
                <FilterInput
                  key={filter.accessor}
                  filter={filter}
                  setFilters={setFilters}
                />
              ))}
            </S.Filters>
          </>
        )}
        {selected && (
          <S.Buttons>
            <DownloadExampleButton filters={filters} selected={selected} />
            <UploadButton selected={selected} />
          </S.Buttons>
        )}
        {selected.buttons.uploadText === "Upload nova base" && (
          <S.WarningText>
            Atenção, essa opção de upload irá <b>apagar</b> e <b>substituir</b>{" "}
            a base de dados atual (<b>{optionKey}</b>).
          </S.WarningText>
        )}
        {selected.buttons.uploadText === "Upload materiais ativos" && (
          <S.WarningText>
            Atenção, essa opção de upload irá <b>ativar</b> skus contidos no
            arquivo e <b>desativar</b> os que <b>não</b> estão contidos (
            <b>{optionKey}</b>).
          </S.WarningText>
        )}
      </Paper>
    </PageContainer>
  );
};

export default PriceBaseUpload;
