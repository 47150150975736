import styled from "styled-components";

export const Title = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
`;

export const Value = styled.p`
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #057c1d;
`;

export const Info = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    justify-content: space-between;

    font-size: 1.1rem;

    span:last-child {
      font-weight: bold;
    }
  }
`;
