import { InputDeflator } from "./components";

export const columns = [
  {
    Header: "UF",
    accessor: "region",
  },
  {
    Header: "Sku",
    accessor: "sku",
  },
  {
    Header: "Tipo de Base",
    accessor: ({ row }: any) => <InputDeflator row={row} />,
  },
];
