import moment from "moment";

import { TTabList } from "./types";
import { validateRow } from "./validateRow";

const blockEditing = (row: any) => {
  if (row.order_status?.match(/aguardando/i)) {
    return true;
  }

  return false;
};

const formatDate = (date: any) => {
  if (!date) return "";
  return moment.utc(date).format("DD/MM/YYYY");
};

export const tabListToTableData = (data: TTabList, tabRequest: any) => {
  return data.map((row: any) => ({
    id: row.id,
    pk: row.material || row.hierarquia || "-",
    branch: row.regiao_vendas_nome || "-",
    empresa: row.empresa || "-",
    cliente: row.cliente || "-",
    categoria: row.category || "-",
    raiz_cnpj: row.raiz_cnpj || "-",
    canal_dist: row.canal_dist || "-",
    description: row.description || "-",
    grupo_preco: row.grupo_preco || "-",
    canal_pricing: row.canal_pricing || "-",
    regiao_vendas: row.regiao_vendas || "-",
    categoryItems: row.categoria_item || "-",
    lista_de_precos: row.lista_preco || "-",
    grupo_de_cliente: row.grupo_cliente || "-",
    documento_de_venda: row.tipo_doc_vendas || "-",
    distributionChannel: row.canal_pricing_rvendas_rcnpj || "-",
    condicao_de_expedicao: row.cond_expedicao || "-",

    // Dates
    data_ini: formatDate(row.data_ini),
    data_fim: formatDate(row.data_fim),
    next_data_ini: formatDate(row.next_data_ini),
    next_data_fim: formatDate(row.next_data_fim),

    // Price
    y500_valor: row.y500_valor || "-",
    n1_valor: row.valor_n1 || "-",
    currentDmx: row.dmx_atual || "-",
    currentPrice: row.valor_atual || "-",
    currentDiscount: row.desconto_atual || "-",
    currentPriceDmx: row.y500_valor || "-",
    n1_valor_next: row.n1_valor_next || "-",
    nextDmx: row.dmx_next || "-",
    nextPrice: row.valor_next || "-",
    nextDiscount: row.desconto_next || "-",
    nextPriceDmx: row.y500_valor_next || "-",

    // Inputs
    newPrice: row.new_price || "",
    dt_inicio: formatDate(row.new_data_ini),
    dt_fim: formatDate(row.new_data_fim),
    validation: validateRow(row, tabRequest),
    blockEditing: !!tabRequest.requestId || blockEditing(row) || false,
    order_status: row.order_status,
  }));
};
