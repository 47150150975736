import { useState, useEffect, useCallback } from "react";

import api from "../../services/api";
import { Multiselect } from "../../components/AutoComplete";

const InputChannel: React.FC<any> = ({ value, setValue }) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchChannel = useCallback(() => {
    api
      .get("/price/list/channels")
      .then((response) => {
        setOptions(
          response.data.channels.map((item: any, index: number) => {
            return {
              key: index,
              label: { label: item.channel, value: item.channel },
            };
          })
        );
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, []);

  useEffect(fetchChannel, [fetchChannel]);

  return (
    <Multiselect
      label="Canal Pricing"
      value={value}
      options={options}
      setValue={setValue}
      errorMessage={errorMessage}
    />
  );
};

export default InputChannel;
