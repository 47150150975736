import styled from "styled-components";

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    gap: 1.2rem;
    flex-direction: row;
  }
  label.radio__box {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 1rem;
    padding: 1.5rem 0;
    input {
      width: 20px !important;
      height: 20px;
    }
    font-size: 1.5rem;
  }
  h5 {
    padding-top: 2rem;
    font-size: 1.2rem;
    color: #003b74;
    font-weight: bold;
    display: block;
  }
  label {
    color: #003b74;
  }

  input {
    width: 100% !important;
  }
`;

export const Container = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const Dates = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;
