import { RadioLabel } from "./styles";

interface IProps {
  name: string;
  inputType: string;
  handleRadio: (e: any) => void;
}

const Radio: React.FC<IProps> = ({ name, inputType, handleRadio }) => {
  return (
    <>
      <input
        type="radio"
        name={name}
        checked={inputType === name}
        onChange={handleRadio}
      />
      <RadioLabel selected={inputType === name}>{name}</RadioLabel>
    </>
  );
};

export default Radio;
