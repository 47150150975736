import api from "../../../../../services/api";

import { responsesToOptions } from "./responsesToOptions";
import { endpoints, defOptions } from "./constants";

export const getInitialState = (callback: (args1: any) => void) => {
  const promises = endpoints.map((url) => api.get(url));

  Promise.all(promises)
    .then((responses) =>
      callback({ ...defOptions, ...responsesToOptions(responses) })
    )
    .catch((error) => callback({ error: true, message: error }));
};
