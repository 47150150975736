export const conditions: string[] = ["Y500", "Y501", "Y540", "Y546", "YVPR"];

export const nonHqConditions: string[] = ["Y500", "Y501"];

export const niveis: { [key: string]: string[] } = {
  Y500: ["N1", "N2", "N5", "N6", "NP"],
  Y501: ["N1", "N2", "N4", "N5", "N6", "N7", "N8", "NP"],
  Y540: ["N1", "N2", "N3", "N4", "N5"],
  Y546: ["N1", "N2", "N3", "N4", "N5"],
  YVPR: ["N1", "N2", "N3", "N4", "N5", "N6", "N7"],
};

export const nonHqNiveis: { [key: string]: string[] } = {
  Y500: ["N2", "N5", "N6", "NP"],
  Y501: ["N2", "N4", "N5", "N6", "N7", "NP"],
};
