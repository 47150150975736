import styled from "styled-components";

interface IRadioLabelProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span<IRadioLabelProps>`
  margin-top: 0.15rem;
  margin-left: 0.5rem;

  font-size: 1.2rem;
  user-select: none;

  color: ${({ selected }) => (selected ? "#003b74" : "rgb(142, 141, 141)")};
`;
