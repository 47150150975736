import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1rem 1.5rem;

  b {
    margin: 10px 0;
    font-size: 1.3rem;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  padding: 0.5rem 1rem;

  background: #f2f3f5;
  font-weight: 500;
  border-radius: 8px;
`;

interface IRowProps {
  isAwaitingApproval: boolean;
}
export const Row = styled.div<IRowProps>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  padding: 0.25rem 1rem;

  color: ${({ isAwaitingApproval }) => isAwaitingApproval && "#664d03"};
`;
