export const columns = [
  {
    Header: "Capitão",
    accessor: "",
  },
  {
    Header: "UF",
    accessor: "",
  },
  {
    Header: "Categoria Nielsen",
    accessor: "",
  },
  {
    Header: "Marca Ajustada",
    accessor: "",
  },
  {
    Header: "Descrição",
    accessor: "",
  },
  {
    Header: " ",
    accessor: "",
  },
];
