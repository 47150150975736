import { memo, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Button from "../../../../../components/Button";
import SceneryFilters from "../../../../../components/SceneryFilters";
import ButtonExportExcelViaUrl from "../../../../../components/ButtonExportExcelViaUrl";

import { Container } from "./styles";

const Buttons: React.FC<any> = ({ columns, filters, setFilters }) => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { sceneryId } = useParams<any>();

  const goToRelativity = useCallback(() => {
    history.push({
      pathname: `/price-base/relativity/${sceneryId}`,
      state,
    });
  }, [history, sceneryId, state]);

  return (
    <Container>
      <SceneryFilters
        url={`/price/service-orders/scenery/step-two/table-item-filters?scenery_id=${sceneryId}`}
        columns={columns}
        currentFilters={filters}
        setCurrentFilters={setFilters}
      />
      <ButtonExportExcelViaUrl
        url={`/price/service-orders/scenery/step-two/export?scenery_id=${sceneryId}`}
      />
      <Button width="12rem" onClick={goToRelativity}>
        Relatividade
      </Button>
    </Container>
  );
};

export default memo(Buttons);
