import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4rem;
  gap: 1rem;

  display: flex;
  flex-direction: column;
`;

export const SectionText = styled.p`
  color: #003b74;
  font-size: 1.5rem;
  font-weight: bold;
`;

export const Cols = styled.div`
  gap: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  display: flex;

  overflow-x: scroll;
`;

interface ColProps {
  borderColor?: string;
}
export const Col = styled.div<ColProps>`
  gap: 1rem;
  padding: 1rem;

  display: flex;
  align-items: center;

  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ borderColor }) => borderColor};

  color: #003b74;
  font-size: 1rem;

  white-space: nowrap;

  svg {
    cursor: pointer;
    font-size: 16px;

    &:hover {
      filter: brightness(150%);
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
