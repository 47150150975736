import styled from "styled-components";

export const SearchBtn = styled.button`
  border: 0;
  background-color: transparent;
  width: 50px;
  height: 45px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: rgba(73, 129, 194, 1);
  svg {
    width: 30px;
    height: 20px;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

