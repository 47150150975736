import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1rem 1rem 2rem;

  max-width: 1920px !important;
`;

interface IProps {
  children: React.ReactNode;
}

const PageContainer: React.FC<IProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PageContainer;
