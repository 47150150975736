import { useState, useMemo, useCallback, useEffect } from "react";

import { extractPrice } from "../../../../../utils/regex";
import { usePriceManagement } from "../../../hooks";

import { IProps, TTextColor, TCalcVariation, TGetVariation } from "./types";

const CellVariation: React.FC<IProps> = ({ row }) => {
  const { tabRequest } = usePriceManagement();

  const [variation, setVariation] = useState<string>("-");

  const textColor: TTextColor = useMemo(() => {
    const variationNum = +variation.substring(0, variation.length - 1);

    if (variationNum < 0) return "red";
    if (variationNum > 0) return "green";
    if (variationNum === 0) return "tan";
    return "#003b74";
  }, [variation]);

  const calcVariation: TCalcVariation = useCallback(
    (currentPrice: number, newPrice: number) => {
      if (!currentPrice || !newPrice) return "";

      const decreaseValue = newPrice - currentPrice;
      const result = (decreaseValue / currentPrice) * 100;

      if (result === 0) return "0%";
      return result.toFixed(2) + "%";
    },
    []
  );

  const getVariaton: TGetVariation = useCallback(() => {
    if (!tabRequest.requestId && row.original.blockEditing) {
      return setVariation("-");
    }

    const newPrice = +row.original.newPrice;
    const currentPrice = +row.original.currentPrice
      ?.match(extractPrice)?.[0]
      ?.replace(",", ".");

    const resultStr = calcVariation(currentPrice, newPrice);

    return setVariation(resultStr);
  }, [tabRequest, row, calcVariation]);

  useEffect(() => {
    getVariaton();
  }, [getVariaton]);

  return <span style={{ color: textColor }}>{variation}</span>;
};

export default CellVariation;
