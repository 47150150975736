import { useMemo } from "react";
import { useParams } from "react-router-dom";

import PageContainer from "../../../../components/PageContainer";

import {
  Header,
  TableIpTarget,
  TableMateriais,
  TableMargemMinMax,
  TableInvestimento,
  TableRangeDePreço,
  TableRelaçãoNielsen,
  TableRelatividadeDeCanais,
  TableRelatividadeDeProdutos,
} from "./components";

const PriceBaseParameters: React.FC = () => {
  const { tableCoded } = useParams<any>();

  const [table, inputs] = useMemo(() => {
    const t = window.atob(window.atob(tableCoded.split("@")[0]));
    const i = JSON.parse(window.atob(window.atob(tableCoded.split("@")[1])));
    return [t, i];
  }, [tableCoded]);

  const component = useMemo(() => {
    return {
      "Relatividade de Produtos": <TableRelatividadeDeProdutos />,
      "Relatividade de Canais": <TableRelatividadeDeCanais inputs={inputs} />,
      Investimento: <TableInvestimento inputs={inputs} />,
      "IP Target": <TableIpTarget inputs={inputs} />,
      "Margem Min & Max": <TableMargemMinMax inputs={inputs} />,
      "Range de Preço": <TableRangeDePreço inputs={inputs} />,
      Materiais: <TableMateriais inputs={inputs} />,
      "Relação Nielsen": <TableRelaçãoNielsen />,
    }[table];
  }, [table, inputs]);

  return (
    <PageContainer>
      <Header table={table} />
      {component}
    </PageContainer>
  );
};

export default PriceBaseParameters;
