import { memo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { TableHead, TableRow } from "@material-ui/core";

import Cell from "../Cell";
import IColumn from "../types/IColumn";

interface ITHeadProps {
  cols: IColumn[];
  stickyPos: number;
  cellProps: { [key: string]: any };
}

const Head: React.FC<ITHeadProps> = ({ cols, stickyPos, cellProps }) => {
  const cellsRef = useRef<any[]>([]);

  return (
    <TableHead>
      <TableRow>
        {cols.map((column, colIndex) => (
          <Cell
            key={uuid()}
            colIndex={colIndex}
            cellsRef={cellsRef}
            stickyPos={stickyPos}
          >
            {typeof column.Header === "string"
              ? column.Header
              : column.Header({ column, colIndex, ...cellProps })}
          </Cell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default memo(Head);
