import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../../services/api";
import { Multiselect } from "../../../../../../../components/AutoComplete";

import { Container } from "./styles";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsSkuXCapitao: React.FC<IProps> = ({ setInputs }) => {
  const [category, setCategory] = useState([]);
  const [categoryOpt, setCategoryOpt] = useState([]);

  const getCategory = useCallback(() => {
    api.get("/material/natura/category").then((response) => {
      setCategoryOpt(
        response.data.material.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.name },
        }))
      );
    });
  }, []);
  useEffect(getCategory, [getCategory]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      category: category.map((item: any) => item.value),
    }));
  }, [setInputs, category]);

  return (
    <Container>
      <Multiselect
        label="Categoria"
        value={category}
        options={categoryOpt}
        setValue={setCategory}
      />
    </Container>
  );
};

export default InputsSkuXCapitao;
