import { memo } from "react";

import Button from "../../../../../components/Button";
import { usePriceManagement } from "../../../hooks";

import { Container, Divider } from "./styles";

const Buttons: React.FC = () => {
  const { showComponent, navigate } = usePriceManagement();

  if (showComponent !== "main") return null;
  return (
    <Container>
      <Button onClick={navigate.visualize}>Consultar</Button>
      <Divider />
      <Button
        containerStyle={{
          backgroundColor: "#dfac03",
          border: "1px solid #dfac03",
        }}
        onClick={navigate.upload}
      >
        Upload
      </Button>
      <Divider />
      <Button onClick={navigate.inputOptions}>Adicionar</Button>
    </Container>
  );
};

export default memo(Buttons);
