import { useEffect, useState } from "react";

import api from "../../../../../../services/api";
import Paper from "../../../../../../components/Paper";
import TableBackend from "../../../../../../components/TableBackendV2";
import LoadingScreen from "../../../../../../components/LoadingScreen";

import { columns } from "./constants";

const limit = 20;

const TableMargemMinMax: React.FC<any> = ({ inputs }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  useEffect(() => {
    api.post("/param/list/margin", { ...inputs, limit, page }).then((res) => {
      setRows(
        res.data.table.map((r: any) => ({
          ...r,
          min_rule: String(Number(r.min_rule).toFixed(2)) || "",
          max_rule: String(Number(r.max_rule).toFixed(2)) || "",
          min_margin: String(Number(r.min_margin).toFixed(2)) || "",
          max_margin: String(Number(r.max_margin).toFixed(2)) || "",
        }))
      );
      setTotal(res.data.total);
      setTotalPage(res.data.totalPages);
      setLoading(false);
    });
  }, [inputs, page]);

  if (loading) return <LoadingScreen />;
  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true);
          setPage(newPage);
        }}
        totalPages={totalPages}
      />
    </Paper>
  );
};

export default TableMargemMinMax;
