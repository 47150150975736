import { HTMLAttributes, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { AutocompleteStyled } from "./styles";

interface OptionProps {
  key: any;
  value: any;
  label: any;
}

export interface SelectProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: any;
  options: OptionProps[];
  setValue: any;
  filter?: boolean;
}

export const Autocomplete = ({
  label,
  value,
  options,
  setValue,
  filter = false,
  ...rest
}: SelectProps) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  const onChange = useCallback(
    (_e, next) => {
      setValue(next?.value || "");
    },
    [setValue]
  );

  const renderInput = useCallback(
    (params: any) => {
      return (
        <>
          {label && <label className="label-input">{label + ""}</label>}
          <TextField {...params} variant="outlined" />
        </>
      );
    },
    [label]
  );

  return (
    <AutocompleteStyled
      value={options.find((option) => option.value === value) || ""}
      onChange={onChange}
      renderInput={renderInput}
      filterOptions={filterOptions}
      options={options}
      style={{ ...rest.style, marginTop: label ? 32 : 0 }}
      noOptionsText="Sem opções disponíveis"
    />
  );
};
