export const columns = [
  {
    Header: "Canal Pricing",
    accessor: "",
  },
  {
    Header: "Adicional Financeiro Médio",
    accessor: "",
  },
];
