import { useEffect, useState, useCallback, memo } from "react";
import moment from "moment";

import Input from "../../../../../components/Input";
import { date } from "../../../../../utils/masks";
import { getDateLimits } from "../../actions";
import { usePriceManagement } from "../../../hooks";

const CellInputDateInicio: React.FC<any> = ({
  row,
  column,
  updateTableData,
}) => {
  const { tabRequest } = usePriceManagement();

  const [value, setValue] = useState<any>("");

  useEffect(() => {
    if (row.original.dt_inicio) {
      return setValue(row.original.dt_inicio);
    }
    return setValue("");
  }, [row.original.dt_inicio]);

  const placeholder = useCallback(() => {
    const [startDateLimit] = getDateLimits(tabRequest, row.original.dt_inicio);

    // Client wants same day + 1 on start date but allow input same day + 0 so I came up with this working gambiarra:
    // Basically the system stills works on same day + 0 but I updated the input placeholder
    // and approve button only to same day + 1. I mean, it works, don't judge me.
    // Look for this same comment on vscode to find all places implemented.
    if (tabRequest.nivel.match(/N1/i)) {
      return moment(startDateLimit, "DD/MM/YYYY")
        .add(1, "days")
        .format("DD/MM/YYYY");
    }

    return startDateLimit;
  }, [tabRequest, row.original.dt_inicio]);

  const onChange = useCallback((e) => {
    setValue(date(e.target.value));
  }, []);

  const onBlur = useCallback(() => {
    if (value === row.original.dt_inicio) return;

    if (!moment(value, "DD/MM/YYYY", true).isValid()) {
      updateTableData(row.index, column.id, "");
      return setValue("");
    }

    updateTableData(row.index, column.id, value);
  }, [updateTableData, row, column.id, value]);

  if (tabRequest.requestId) {
    return <span>{row.original.dt_inicio || "-"}</span>;
  }

  if (row.original.blockEditing) {
    return <span>-</span>;
  }

  return (
    <Input
      name="startDate"
      placeholder={placeholder()}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      containerStyle={{
        width: "76px",
        height: "20px",
      }}
      inputStyle={{
        width: "100%",
        color: "#003b74",
        fontSize: "1rem !important",
        padding: 8,
      }}
    />
  );
};

export default memo(CellInputDateInicio);
