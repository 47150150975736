import styled from "styled-components";
import {
  withStyles,
  TablePagination as MUITablePagination,
  TableCell,
} from "@material-ui/core";
import { Switch as MUISwitch, Toolbar as MUIToolbar } from "@material-ui/core";

import { Pagination as MUIPagination } from "@material-ui/lab";
import { shade } from "polished";

interface ContainerProps {
  borderBottom?: boolean;
}

export const Container = styled.div<ContainerProps>`
  table {
    border-spacing: 0;
    width: 100%;

    text-align: center;

    overflow: visible;

    tr {
      :last-child {
        td {
          border-bottom: ${({ borderBottom }) => !borderBottom && "0"};
        }
      }
    }

    td {
      margin: 0;
      padding: 1.7rem 1rem;
      font-size: 1.3rem;
      border-bottom: 1px solid #ddd7e6;
      text-align: center;

      :last-child {
        border-right: 0;
      }
    }

    th {
      margin: 0;
      padding: 0.8rem 1rem;
      text-align: unset;
      background: #f2f3f5;
      font-size: 1.4rem;
      border-bottom: none;
      text-transform: capitalize;
      color: #003b74;
      font-weight: 500;
      line-height: inherit;

      :last-child {
        border-bottom-right-radius: 7.5px;
        border-top-right-radius: 7.5px;
      }
      :first-child {
        border-bottom-left-radius: 7.5px;
        border-top-left-radius: 7.5px;
      }
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: #003b74;
    }
  }
`;
export const TablePagination = styled(MUITablePagination)`
  width: 100%;
  display: flex;
  border: none;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.6rem;

    > p {
      font-size: 1.4rem;
    }

    .MuiTablePagination-spacer {
      display: none;
    }
  }
`;

export const Switch = styled(MUISwitch)`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #003b74;
  }
  span.MuiSwitch-track {
    background-color: #003b74 !important;
  }
`;

export const Pagination = styled(MUIPagination)`
  div {
    font-size: 1.8rem;
    padding: 0 4px;

    width: 2.6rem;
    min-width: unset;
  }

  button {
    font-size: 1.5rem;
    height: 3rem;
    min-width: 3rem;
  }

  button.Mui-selected {
    background-color: #003b74;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${shade(0.3, "#003b74")};
    }
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Toolbar = styled(MUIToolbar)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div > p {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    margin-left: 1rem;
  }
`;

export const DropdownItem = styled.button`
  font-size: 1.3rem;
  padding: 1.6rem 0;
  width: 100%;
  background: none;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;

  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const ExportText = styled.h2`
  font-size: 1.4rem;
  color: #003b74;
  font-weight: 900;
`;

export const ExportButton = styled.button`
  font-size: 1.3rem;
  background: #f2f3f5;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;
  min-width: 4rem;
  color: #003b74;
  padding: 0.6rem;
  margin-left: 1.2rem;
  font-weight: 500;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: ${shade(0.1, "#f2f3f5")};
  }
`;

export const TableCellStyled = withStyles({
  root: {
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg": {
        cursor: "pointer",
      },
    },
  },
})(TableCell);
