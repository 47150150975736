import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import Input from "../../../../../../../../components/Input";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
  type: "target_otimizador" | "target";
}

const InputDeflator: React.FC<IProps> = ({ row, type }) => {
  const { isUserSupport } = useAuth();

  const [target, setTarget] = useState(row[type]);

  const onChange = useCallback((e) => {
    setTarget(e.target.value);
  }, []);

  const onBlur = useCallback(
    (e) => {
      let newVal = e.target.value || "0";
      setTarget(newVal);

      const url = "/param/update/ip-target";
      const req = {
        sku_capitao: row.sku_capitao,
        region: row.region,
        channel: row.channel,
        category: row.category,
        description: row.description,
        target: row.target,
        target_otimizador: row.target_otimizador,
        [type]: newVal,
      };
      console.log({ req });

      api.post(url, req).catch(() => {
        setTarget(row.deflator);
      });
    },
    [row, type]
  );

  if (isUserSupport) return target;
  return (
    <Input
      name="target"
      value={target}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default InputDeflator;
