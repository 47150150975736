import { useMemo } from "react";

import { Container } from "./styles";
import {
  InputsBaseSp,
  InputsDeflator,
  InputsMateriais,
  InputsSkuXCapitao,
  InputsRelatividadeCanal,
} from "./components";

interface IProps {
  table: string;
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const Header: React.FC<IProps> = ({ table, setInputs }) => {
  const component = useMemo(() => {
    return {
      "SKU X Capitão": <InputsSkuXCapitao setInputs={setInputs} />,
      Deflator: <InputsDeflator setInputs={setInputs} />,
      "Relatividade Canal": <InputsRelatividadeCanal setInputs={setInputs} />,
      "Base Sp": <InputsBaseSp setInputs={setInputs} />,
      "Código Capitão": null,
      Elasticidade: null,
      "Adicional Financeiro Médio": null,
      Materiais: <InputsMateriais setInputs={setInputs} />,
    }[table];
  }, [table, setInputs]);

  return <Container>{component}</Container>;
};

export default Header;
