import { useMemo } from "react";

import {
  Container,
  Total,
  CurrentPage,
  NeighborPage,
  Ellipsis,
} from "./styles";

interface IProps {
  paginationOptions: any;
}

const Pagination: React.FC<IProps> = ({ paginationOptions }) => {
  const { total, nextPage, prevPage, totalPages, setPage } = paginationOptions;

  const currentPage = useMemo(() => {
    return nextPage ? nextPage - 1 : prevPage ? prevPage + 1 : 1;
  }, [nextPage, prevPage]);

  return (
    <Container>
      <Total>Visualizando {total} resultados</Total>

      {currentPage > 2 && (
        <NeighborPage onClick={() => setPage(1)}>{1}</NeighborPage>
      )}

      {currentPage > 3 && <Ellipsis>...</Ellipsis>}

      {currentPage > 1 && (
        <NeighborPage onClick={() => setPage(currentPage - 1)}>
          {currentPage - 1}
        </NeighborPage>
      )}

      <CurrentPage>{currentPage}</CurrentPage>

      {totalPages && currentPage < totalPages && (
        <NeighborPage onClick={() => setPage(currentPage + 1)}>
          {currentPage + 1}
        </NeighborPage>
      )}

      {totalPages && currentPage < totalPages - 2 && <Ellipsis>...</Ellipsis>}

      {totalPages && currentPage < totalPages - 1 && (
        <NeighborPage onClick={() => setPage(totalPages)}>
          {totalPages}
        </NeighborPage>
      )}
    </Container>
  );
};

export default Pagination;
