import { useCallback, useEffect, useMemo, useState } from "react";

import api from "../../../../../../services/api";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import TableBig from "../../../../../../components/TableBig";
import useToggle from "../../../../../../hooks/useToggle";
import { scenValue } from "../../../../../../utils/scenValue";
import { Multiselect } from "../../../../../../components/AutoComplete";

import { cols } from "./constants";
import { Inputs, ModalContainer } from "./styles";
import { useParams } from "react-router-dom";

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  sku: IOption[];
  nivel02: IOption[];
  nivel03: IOption[];
  protein: IOption[];
  bonus_sales: IOption[];
}

const ButtonResume: React.FC = () => {
  const { sceneryId } = useParams<any>();

  const [open, toggleOpen] = useToggle();

  const [rows, setRows] = useState<any[]>([]);
  const [sku, setSku] = useState<IOption[]>([]);
  const [nivel02, setNivel02] = useState<IOption[]>([]);
  const [nivel03, setNivel03] = useState<IOption[]>([]);
  const [protein, setProtein] = useState<IOption[]>([]);
  const [bonusSales, setBonusSales] = useState<IOption[]>([]);
  const [options, setOptions] = useState<IOptions>({
    sku: [],
    nivel02: [],
    nivel03: [],
    protein: [],
    bonus_sales: [],
  });

  const request = useMemo(() => {
    return {
      scenery_id: +sceneryId,
      sku: sku.map((v) => v.value),
      nivel02: nivel02.map((v) => v.value),
      nivel03: nivel03.map((v) => v.value),
      protein: protein.map((v) => v.value),
      bonus_sales: bonusSales.map((v) => v.value),
    };
  }, [bonusSales, nivel02, nivel03, protein, sceneryId, sku]);

  const getTable = useCallback(() => {
    const url = "/price/scenery/big-number-in-natura";

    open &&
      api.post(url, request).then((response) => {
        setRows(
          response.data.bignumbers.map((row: any) => ({
            ...row,
            nivel02: row.nivel02 || "Todos",
            rob_desafio: scenValue(row.rob_desafio),
            rob_m1: scenValue(row.rob_m1),
            rob_medio: scenValue(row.rob_medio),
            rob_meta: scenValue(row.rob_meta),
            volume_medio: scenValue(row.volume_medio),
            preco_base: scenValue(row.preco_base),
            volume_m1: scenValue(row.volume_m1),
            preco_tecnico: scenValue(row.preco_tecnico),
            pxp_tecnico: scenValue(row.pxp_tecnico),
            preco_meta: scenValue(row.preco_meta),
            pxp_meta: scenValue(row.pxp_meta),
            preco_desafio: scenValue(row.preco_desafio),
            pxp_desafio: scenValue(row.pxp_desafio),
          }))
        );
      });
  }, [open, request]);
  useEffect(getTable, [getTable]);

  const getOptions = useCallback(() => {
    const url = `/price/scenery/big-number-in-natura/levels?scenery_id=${sceneryId}`;

    open &&
      api.get(url).then((response) => {
        const formattedOptions: any = {};

        Object.keys(response.data.response).forEach((key) => {
          formattedOptions[key] = response.data.response[key].map(
            (value: string) => ({
              key: value,
              label: { label: value, value: value },
            })
          );
        });

        setOptions(formattedOptions);
      });
  }, [open, sceneryId]);
  useEffect(getOptions, [getOptions]);

  const columns = useMemo(() => {
    let final = cols;

    if (sku.length === 0) {
      final = final.filter((col) => col.accessor !== "material");
    }

    if (nivel03.length === 0) {
      final = final.filter((col) => col.accessor !== "nivel03");
    }

    if (protein.length === 0) {
      final = final.filter((col) => col.accessor !== "protein");
    }

    if (bonusSales.length === 0) {
      final = final.filter((col) => col.accessor !== "bonus_sales");
    }

    return final;
  }, [sku, nivel03, protein, bonusSales]);

  return (
    <>
      <Button
        width="8rem"
        containerStyle={{
          backgroundColor: "#057C68",
          border: "1px solid #057C68",
        }}
        onClick={toggleOpen}
      >
        Resumo
      </Button>
      <Modal
        containerStyle={{
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        open={open}
        setOpen={toggleOpen}
        children={
          <ModalContainer>
            <Inputs>
              <Multiselect
                label="Material"
                value={sku}
                options={options.sku}
                setValue={setSku}
              />
              <Multiselect
                label="Atividade"
                value={nivel02}
                options={options.nivel02}
                setValue={setNivel02}
              />
              <Multiselect
                label="Categoria"
                value={nivel03}
                options={options.nivel03}
                setValue={setNivel03}
              />
              <Multiselect
                label="Proteína"
                value={protein}
                options={options.protein}
                setValue={setProtein}
              />
              <Multiselect
                label="Cluster"
                value={bonusSales}
                options={options.bonus_sales}
                setValue={setBonusSales}
              />
            </Inputs>
            <TableBig data={rows} columns={columns} />
          </ModalContainer>
        }
      />
    </>
  );
};

export default ButtonResume;
