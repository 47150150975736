import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  row: any;
  checkedRows: Array<string | number>;
  setCheckedRows: React.Dispatch<React.SetStateAction<Array<string | number>>>;
  allRowsChecked: boolean;
  setAllRowsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Checkbox: React.FC<IProps> = ({
  row,
  checkedRows,
  setCheckedRows,
  allRowsChecked,
  setAllRowsChecked,
}) => {
  const { state } = useLocation<any>();

  const checked = useMemo(() => {
    return allRowsChecked || checkedRows.includes(row.id);
  }, [allRowsChecked, checkedRows, row.id]);

  const onChange = useCallback(() => {
    if (allRowsChecked) {
      setCheckedRows([]);
      setAllRowsChecked(false);
    }

    if (checked) {
      return setCheckedRows((current) =>
        current.filter((ids) => ids !== row.id)
      );
    }

    setCheckedRows((current) => [...current, row.id]);
  }, [allRowsChecked, checked, row.id, setAllRowsChecked, setCheckedRows]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={state?.sceneryViewOnly}
      />
    </div>
  );
};

export default Checkbox;
