import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 2.8rem;
  margin-left: 1rem;
  color: #003b74;
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  svg {
    height: 2.3rem;
    width: 2.3rem;
    path {
      fill: #003b74;
    }
  }
`;
