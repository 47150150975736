import axios from "axios";
import { createBrowserHistory } from "history";

const baseURL = process.env.REACT_APP_API_URL ?? "";
const timeout = 60 * 60 * 1000;
export const getToken = () => localStorage.getItem("@PrixsiaBRF:token") || "";

const api = axios.create({ baseURL, timeout });

api.defaults.timeout = timeout;

api.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const history = createBrowserHistory();
      localStorage.clear();
      history.push("/login");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default api;
