import styled from "styled-components";

import { ContainerProps } from "./interfaces";

export const Container = styled.span<ContainerProps>`
  padding: 5px 15px;

  white-space: nowrap;
  border-radius: 50px;

  font-weight: normal;
  text-align: center;
  text-transform: capitalize;

  color: ${({ textColor }) => textColor};
  background: ${({ backgroundColor }) => backgroundColor};
`;
