// ⚠️ THE ENTIRE APPLICATION IS CONTROLLED HERE ⚠️
// ⚠️ EDIT WITH CARE ⚠️

// Why I built this:
// 1) A way to remove 80+ ifs the previous dev did
// 2) Scalability, instatly updates entire application on change or adding new sections instead of having to change every file

// How to use:
// 0: Off, 1: On
// Proposal format: string `condition-nivel`
// Tag format: string exactly as described on truthTableYtags
// Updating will make the section appear on inputs & tables through the application
// If you need to add more sections:
// 1) Add a key to the end of truthTableYTags
// 2) Add another value to end end of all truthTable arrays

// Objects section
// Used to control the application
// ⚠️ Only make changes here ⚠️
export const truthTableYTags: { [key: string]: number } = {
  filial: 0,
  canal_pricing: 1,
  categoria: 2,
  material: 3,
  grupo_de_cliente: 4,
  raiz_cnpj: 5,
  cliente: 6,
  canal_de_distribuicao: 7,
  empresa: 8,
  regiao: 9,
  lista_de_precos: 10,
  condicao_de_expedicao: 11,
  documento_de_venda: 12,
  hierarquia: 13,
  categoria_de_items: 14, // Yeah the portuguese is wrong, I don't care, this will never be rendered to text anyway
  grupo_de_preco: 15,
};

// ⚠️ Only make changes here ⚠️
export const truthTable: { [key: string]: number[] } = {
  // Y500
  "Y500-N1": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
  "Y500-N2": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
  "Y500-N5": [1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
  "Y500-N6": [1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
  "Y500-NP": [1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
  // Y501
  "Y501-N1": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
  "Y501-N2": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
  "Y501-N4": [1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  "Y501-N5": [1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y501-N6": [1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y501-N7": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
  "Y501-N8": [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
  "Y501-NP": [1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
  // Y540
  "Y540-N1": [1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y540-N2": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y540-N3": [1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y540-N4": [1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y540-N5": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  // Y546
  "Y546-N1": [1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y546-N2": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y546-N3": [1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y546-N4": [1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  "Y546-N5": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
  // YVPR
  "YVPR-N1": [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 1, 0, 1],
  "YVPR-N2": [0, 0, 0, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1],
  "YVPR-N3": [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0],
  "YVPR-N4": [0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
  "YVPR-N5": [0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0],
  "YVPR-N6": [0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0],
  "YVPR-N7": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
};

// Regex section
// Used to validate if proposal and tag parameters matches any of it's relative object keys
// If you think .includes() is a better idea leave this job and go study more
// ⚠️ You don't need to change anything here ⚠️
// Or do, I'm just a comment anyway
const keysToRegex = (obj: { [key: string]: number | number[] }): RegExp => {
  const base = Object.keys(obj)
    .map((key) => `(?<!\\w)${key}(?!\\w)`)
    .join("|");

  const regex = new RegExp(`${base}`, "i");

  return regex;
};

const truthTableKeysRegex: RegExp = keysToRegex(truthTable);
const truthTableYTagsKeysRegex: RegExp = keysToRegex(truthTableYTags);

const isValidProposal = (proposal: string): boolean => {
  return truthTableKeysRegex.test(proposal);
};

const isValidTag = (tag: string): boolean => {
  return truthTableYTagsKeysRegex.test(tag);
};

// Final function section
// ⚠️ You don't need to change anything here ⚠️
// Or do, I'm just a comment anyway
const shouldRenderSection = (proposal: string, tag: string): boolean => {
  if (!proposal || proposal.length < 7 || !tag) {
    // Prevents useless memory use
    // Sometimes the info will come from a hook or useMemo, and that takes a few miliseconds
    return false;
  }

  if (!isValidProposal(proposal)) {
    return false;
  }

  if (!isValidTag(tag)) {
    return false;
  }

  const truthTableX = truthTable[proposal];
  const truthTableY = truthTableYTags[tag];
  const truthTableXY = truthTableX[truthTableY];

  return !!truthTableXY;
};

export default shouldRenderSection;
