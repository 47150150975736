import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TableCell } from "@material-ui/core";

interface ICellProps {
  children: React.ReactNode;
  colIndex: number;
  rowIndex?: number;
  cellsRef: React.MutableRefObject<any[]>;
}

const StickyCell: React.FC<ICellProps> = ({
  children,
  colIndex,
  rowIndex,
  cellsRef,
}) => {
  const [left, setLeft] = useState("0");

  const backgroundColor = useMemo(() => {
    if (typeof rowIndex === "number") return rowIndex & 1 ? "#f9f9f9" : "#fff";
    return "#f2f3f5";
  }, [rowIndex]);

  const getLeft = useCallback(() => {
    let newLeft = 0;

    for (let i = 0; i <= colIndex - 1; i += 1) {
      newLeft += cellsRef.current[i]?.offsetWidth - 0.1 || 0;
    }

    setLeft(newLeft + "px");
  }, [cellsRef, colIndex]);
  useEffect(getLeft, [getLeft]);

  return (
    <TableCell
      ref={(el) => (cellsRef.current[colIndex] = el)}
      style={{
        position: "sticky",
        zIndex: 10,
        left,
        backgroundColor,
      }}
    >
      {children}
    </TableCell>
  );
};

export default memo(StickyCell);
