import { useRef, useCallback, memo, useState } from "react";

import api from "../../../services/api";
import AlertModal from "../../AlertModal";
import LoadingModal from "../../LoadingModal";
import { useToast } from "../../../contexts/ToastContext";

import { Upload } from "./styles";
import { IUploadOption } from "../types";

interface IProps {
  selected: IUploadOption;
}

const UploadButton: React.FC<IProps> = ({ selected }) => {
  const { addToast } = useToast();

  const [errorMessage, setErrorMessage] = useState(
    "Erro ao realizar upload, tente novamente."
  );

  const errorRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);
  const successRef = useRef<any>(null);

  const openError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const openSuccess = useCallback(() => {
    successRef.current.toggle();
  }, []);

  const resetFileBugfix = useCallback((event) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  }, []);

  const onUpload = useCallback(
    (e) => {
      const name = e.target?.files?.[0]?.name;

      if (!name || !/\.xlsx$/i.test(name)) {
        return addToast({
          type: "error",
          title: "Erro",
          description:
            "Arquivo inválido. Por favor envie no formato excel (.xlsx).",
        });
      }

      const file = e.target.files[0];

      if (file.size >= 4e7) {
        return addToast({
          type: "error",
          title: "Erro",
          description: "Arquivo inválido. Limite 40mb.",
        });
      }

      const formData = new FormData();

      formData.append("excel_import", file);

      openLoading(
        "Fazendo upload...",
        api
          .post(selected.endpoint, formData)
          .then(() => {
            openSuccess();
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              if (
                /connection terminated unexpectedly/i.test(
                  error.response.data.message
                )
              ) {
                setErrorMessage(
                  "Erro de conexão, aguarde um momento e tente novamente."
                );
                return setTimeout(() => openError(), 2000);
              }

              setErrorMessage(error.response.data.message);
              return setTimeout(() => openError(), 2000);
            }

            setErrorMessage(
              `Erro ao fazer upload, verifique o arquivo e tente novamente.`
            );
            setTimeout(() => openError(), 2000);
          })
      );
    },
    [openLoading, selected.endpoint, addToast, openSuccess, openError]
  );

  return (
    <label htmlFor="uploadExcel">
      <Upload>
        {selected.buttons.uploadSvg()}
        {selected.buttons.uploadText}
      </Upload>
      <input
        type="file"
        id="uploadExcel"
        name="uploadExcel"
        onClick={resetFileBugfix}
        onChange={onUpload}
        style={{ display: "none" }}
      />
      <LoadingModal ref={loadingRef} />
      <AlertModal ref={errorRef} type="error" message={errorMessage} />
      <AlertModal ref={successRef} message="Upload realizado com sucesso!" />
    </label>
  );
};

export default memo(UploadButton);
