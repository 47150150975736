import { useMemo, memo } from "react";

import Select from "../../../../../../components/Select";
import { useUpload } from "../../../hooks";
import { usePriceManagement } from "../../../../hooks";

import { Container } from "./styles";
import { getLabelText } from "./actions";
import { conditions, nonHqConditions, niveis, nonHqNiveis } from "./constants";

const SelectConditionNivel: React.FC = () => {
  const { nivel, setNivel, condition, setCondition } = useUpload();
  const { isUserSDP, isUserHQ } = usePriceManagement();

  const conditionOptions = useMemo(() => {
    let options = isUserHQ ? conditions : nonHqConditions;

    if (isUserSDP) {
      options = options.filter((opt) => ["Y500", "Y501"].includes(opt));
    }

    setCondition(options[0]);

    return options.map((item) => ({
      key: item,
      label: getLabelText(item),
      value: item,
    }));
  }, [isUserHQ, isUserSDP, setCondition]);

  const nivelOptions = useMemo(() => {
    let options: string[] =
      (isUserHQ ? niveis[condition] : nonHqNiveis[condition]) || [];

    if (isUserSDP) {
      options = options.filter((opt) => ["N6", "NP"].includes(opt));
    }

    setNivel(options[0]);

    return options.map((item) => ({ key: item, label: item, value: item }));
  }, [isUserHQ, isUserSDP, condition, setNivel]);

  return (
    <Container>
      <Select
        name="condition"
        value={condition}
        options={conditionOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setCondition(e.target.value)
        }
      />
      <Select
        name="nivel"
        value={nivel}
        options={nivelOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setNivel(e.target.value)
        }
      />
    </Container>
  );
};

export default memo(SelectConditionNivel);
