import { useEffect, useState } from "react";

import api from "../../../../../../services/api";
import Paper from "../../../../../../components/Paper";
import LoadingScreen from "../../../../../../components/LoadingScreen";
import TableBackend from "../../../../../../components/TableBackendV2";

import { columns } from "./constants";

const limit = 20;

const TableBaseSp: React.FC<any> = ({ inputs }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  useEffect(() => {
    api.post("/param/list/base", { ...inputs, limit, page }).then((res) => {
      setRows(res.data.base.table);
      setTotal(res.data.base.total);
      setTotalPage(res.data.base.totalPages);
      setLoading(false);
    });
  }, [inputs, page]);

  if (loading) return <LoadingScreen />;
  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true);
          setPage(newPage);
        }}
        totalPages={totalPages}
      />
    </Paper>
  );
};

export default TableBaseSp;
