import { useCallback } from "react";
import { Backdrop, Fade } from "@material-ui/core";

import Paper from "../Paper";

import { Container, CloseIcon, ModalWrapper, ModalBackground } from "./styles";

interface ModalProps {
  open: boolean;
  setOpen(isOpen: boolean): void;
  children: React.ReactNode;
  containerStyle?: object;
}

const Modal: React.FC<ModalProps> = ({
  open,
  setOpen,
  children,
  containerStyle = {},
}) => {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Container
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <>
          <ModalBackground />
          <ModalWrapper style={containerStyle}>
            <Paper
              containerStyle={{
                position: "relative",
                borderRadius: "10px",
                padding: "3rem 2rem 1.5rem",
                minHeight: "20rem",
                overflow: "auto",
              }}
            >
              <CloseIcon onClick={handleClose} />
              {children}
            </Paper>
          </ModalWrapper>
        </>
      </Fade>
    </Container>
  );
};

export default Modal;
