const labelsTextDictionary: { [key: string]: string } = {
  Y500: "Y500 - Preço",
  Y501: "Y501 - Desconto",
  Y540: "Y540 - DMX Gerar",
  Y546: "Y546 - DMX Receber",
  YVPR: "YVPR - Verba Preço",
};

export const getLabelText: (condition: string) => string = (condition) => {
  return labelsTextDictionary[condition] || condition;
};
