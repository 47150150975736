import Input from "./Input";

import { IProps } from "./types";

const InputClient: React.FC<IProps> = ({
  value,
  region,
  setValue,
  shouldRender,
}) => {
  if (!shouldRender("cliente")) return null;
  return <Input value={value} region={region} setValue={setValue} />;
};

export default InputClient;
