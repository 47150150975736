import { Checkbox, HeaderCheckbox, Input } from "./components";

export const limit = 30;

export const columnsDefaultStart = [
  {
    Header: ({ filters, getRows, allChecked, setAllChecked }: any) => (
      <HeaderCheckbox
        filters={filters}
        getRows={getRows}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
    ),
    accessor: ({ row, getRows }: any) => (
      <Checkbox row={row} getRows={getRows} />
    ),
  },
];

export const columnsToReplace: { [key: string]: any } = {
  ip_final: {
    Header: "IP Final",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="ip_final"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
  ppc_final: {
    Header: "PPC Final",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="ppc_final"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
};
