import { useEffect, useState } from "react";

import api from "../../../../../../services/api";
import Paper from "../../../../../../components/Paper";
import TableBackend from "../../../../../../components/TableBackendV2";
import LoadingScreen from "../../../../../../components/LoadingScreen";

import { Nav } from "./components";
import { columns } from "./constants";

const limit = 30;

const TableMateriais: React.FC<any> = ({ inputs }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  useEffect(() => {
    api
      .post("/material/list/all", {
        ...inputs,
        limit,
        page,
        type: "processados",
      })
      .then((res) => {
        setRows(res.data.dados.table);
        setTotal(res.data.dados.total);
        setTotalPage(res.data.dados.totalPages);
        setLoading(false);
      });
  }, [inputs, page]);

  if (loading) return <LoadingScreen />;
  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <Nav filters={{ ...inputs, type: "processados" }} />
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true);
          setPage(newPage);
        }}
        totalPages={totalPages}
      />
    </Paper>
  );
};

export default TableMateriais;
