import { useCallback, useRef, useState } from "react";
import moment from "moment";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../../../services/api";
import Modal from "../../../../../../components/Modal";
import Input from "../../../../../../components/Input";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import LoadingModal from "../../../../../../components/LoadingModal";
import { date } from "../../../../../../utils/masks";
import { useToast } from "../../../../../../contexts/ToastContext";

const ButtonSendToApprove: React.FC<any> = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { sceneryId } = useParams<any>();

  const [isOpen, toggleOpen] = useToggle();
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState("31/12/9999");
  const [startDate, setStartDate] = useState("");

  const loadingRef = useRef<any>(null);

  const iso = useCallback((date: string) => {
    return date.split("/").reverse().join("-");
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const onSend = useCallback(() => {
    const date_end = iso(endDate);
    const date_start = iso(startDate);

    if (date_start.length < 10 || !moment(date_start).isValid()) {
      return addToast({
        type: "error",
        title: "Erro.",
        description: "Data inicial inválida.",
      });
    }

    if (date_end.length < 10 || !moment(date_end).isValid()) {
      return addToast({
        type: "error",
        title: "Erro.",
        description: "Data final inválida.",
      });
    }

    if (moment(date_end).isBefore(date_start)) {
      return addToast({
        type: "error",
        title: "Erro.",
        description: "Data final menor que inicial.",
      });
    }

    setLoading(true);

    const request = {
      id: sceneryId,
      date_end,
      date_start,
    };

    openLoading(
      "Enviando para aprovação...",
      api
        .post(`/price/service-orders/scenery/submit`, request)
        .then(() => {
          setLoading(false);
          setTimeout(() => {
            addToast({
              type: "success",
              title: "Sucesso!",
              description: "Enviado para aprovação.",
            });
            history.push("/price-base");
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          setTimeout(() => {
            addToast({
              type: "error",
              title: "Erro.",
              description: "Por favor tente novamente.",
            });
          }, 1000);
        })
    );
  }, [addToast, endDate, history, iso, openLoading, sceneryId, startDate]);

  return (
    <>
      <Button width="16rem" onClick={toggleOpen}>
        Enviar para aprovação
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "420px" }}
        children={
          <Box textAlign="center" pt={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Input
                label="Data Início"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(date(e.target.value))}
                containerStyle={{
                  marginRight: "1rem",
                  width: "16rem",
                }}
              />
              <Input
                label="Data Fim"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(date(e.target.value))}
                containerStyle={{
                  marginLeft: "1rem",
                  width: "16rem",
                }}
              />
            </Box>
            <Box
              pt={3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button disable={loading} onClick={onSend}>
                {loading ? "Enviando..." : "Enviar"}
              </Button>
            </Box>
            <LoadingModal ref={loadingRef} />
          </Box>
        }
      />
    </>
  );
};

export default ButtonSendToApprove;
