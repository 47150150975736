import React, { InputHTMLAttributes, ReactNode } from "react";

import { Container, InputText } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: ReactNode;
  borderRadius?: string;
  containerStyle?: object;
  inputStyle?: object;
  label?: string;
  maxLength?: number;
}

const Input: React.FC<InputProps> = ({
  name = "",
  icon,
  containerStyle = {},
  inputStyle = {},
  borderRadius,
  label = "",
  maxLength = 255,
  ...rest
}) => {
  // const inputRef = useRef<HTMLInputElement>(null);

  // const { fieldName, defaultValue, registerField } = useField(name);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: "value",
  //   });
  // }, [fieldName, registerField]);

  return (
    <Container label={label} style={containerStyle} borderRadius={borderRadius}>
      {icon && icon}
      {label && <label>{label}</label>}
      <InputText
        name={name}
        borderRadius={borderRadius}
        style={inputStyle}
        isDisabled={rest.disabled}
        autoComplete="off"
        maxLength={maxLength}
        {...rest}
      />
    </Container>
  );
};

export default Input;
