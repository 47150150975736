import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 2rem; 
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const Inputs = styled.div`
  width: 100%;
  gap: 2rem;
  display: flex;

  label {
    color: #003b74;
  }

  input {
    width: 100% !important;
  }
`;

export const Dates = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;
