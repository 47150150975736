import styled from "styled-components";

export const ChooseText = styled.h1`
  color: #003b74;
  font-weight: bold;
`;

export const RadiosButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Radios = styled.div`
  width: 100%;
  gap: 2rem;
  padding-bottom: 1rem;

  display: flex;

  border-bottom: 1px solid #ddd7e6;

  input[type="radio"] {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Filters = styled.div`
  gap: 2rem;
  width: 100%;

  display: flex;
`;

export const Buttons = styled.div`
  width: 100%;
  gap: 2rem;
  padding-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WarningText = styled.p`
  margin-left: auto;

  color: red;
  font-size: 1.3rem;
`;
