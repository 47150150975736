import { useCallback, useLayoutEffect, useMemo, useState } from "react";

import Paper from "../../components/Paper";
import TableBackend from "../../components/TableBackendV2";
import LoadingScreen from "../../components/LoadingScreen";
import PageContainer from "../../components/PageContainer";

import { Header } from "./components";
import { getColumns, getTableData } from "./actions";

const limit = 20;

const PriceBase: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  const columns = useMemo(() => getColumns(), []);

  const getInitialState = useCallback(() => {
    return getTableData(
      (data) => {
        if (data.error) return console.error(data.message);
        setTotal(data.total);
        setTotalPage(data.totalPages);
        setTableData(data.tableData);
        setLoading(false);
      },
      { page, limit }
    );
  }, [page, setLoading]);

  useLayoutEffect(() => {
    loading && getInitialState();
  }, [loading, getInitialState]);

  if (loading) return <LoadingScreen />;
  return (
    <PageContainer>
      <Header />
      <Paper>
        <TableBackend
          rows={tableData}
          cols={columns}
          page={page}
          limit={limit}
          total={total}
          setPage={(newPage) => {
            setLoading(true);
            setPage(newPage);
          }}
          totalPages={totalPages}
        />
      </Paper>
    </PageContainer>
  );
};

export default PriceBase;
