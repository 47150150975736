import { useMemo } from "react";

import TableSmallViewOnly from "../../../../../../components/TableSmallViewOnly";
import { getColumns } from "../../../actions";
import { usePriceManagement } from "../../../../hooks";

const EditionsTable: React.FC<any> = ({ newPricesOnlyTableData }) => {
  const { tabRequest } = usePriceManagement();

  const columns = useMemo(() => getColumns(tabRequest, true), [tabRequest]);

  return (
    <TableSmallViewOnly
      columns={columns}
      data={newPricesOnlyTableData}
      setData={() => {}}
    />
  );
};

export default EditionsTable;
