import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlusCircle as AddSvg } from "react-icons/ai";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import {
  FaTrash as DeleteSvg,
  FaInfoCircle as ErrorSvg,
  FaPlusCircle as CreateSvg,
  FaTimesCircle as CancelSvg,
} from "react-icons/fa";

import api from "../../../../../../../../services/api";
import Paper from "../../../../../../../../components/Paper";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import { Autocomplete } from "../../../../../../../../components/AutoComplete";

import { columns, addColumns } from "./constants";
import { InputSizeUni, InputRelativity } from "./components";
import { Add, Error, Table, Options, DeleteContainer } from "./styles";

interface IProps {
  uf: string;
  rows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  captain: string;
}

const Body: React.FC<IProps> = ({ uf, rows, setRows, captain }) => {
  const { isUserSupport } = useAuth();

  const [isAdding, setIsAdding] = useState(false);

  const [sku, setSku] = useState("");
  const [skuOpt, setSkuOpt] = useState<any>([]);

  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [sku]);

  const getSku = useCallback(() => {
    api.get("/material/list").then((res) => {
      setSkuOpt(
        res.data.material.map(
          (item: any, index: number) =>
            +item.sku && {
              key: index + 1,
              label: +item.sku + "",
              value: +item.sku + "",
            }
        )
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  const onDelete = useCallback(
    (id) => {
      api.post("/param/delete/sku-processados", { id }).then(() => {
        setRows((current: any) => {
          return current.filter((row: any) => row.id !== id);
        });
      });
    },
    [setRows]
  );

  const onCancel = useCallback(() => {
    setSku("");
    setError("");
    setIsAdding(false);
  }, []);
  useEffect(onCancel, [onCancel, rows]);

  const onAdd = useCallback(() => {
    const url = "/param/add/sku-processados";
    const req = { uf, sku_capitao: captain, sku_material: sku };
    api
      .post(url, req)
      .then((res) => {
        if (res.data.message) {
          setError(res.data.message);
        } else {
          const {
            id,
            relatividade,
            sku_material,
            size_uni,
            uf: dataUf,
            description_capitao: description,
          } = res.data.response;

          onCancel();
          setRows((current) => [
            ...current,
            {
              id,
              description,
              relatividade,
              sku_material,
              uf: dataUf,
              size_uni,
            },
          ]);
        }
      })
      .catch((e) => {
        setError("Erro, tente novamente.");
      });
  }, [uf, sku, captain, onCancel, setRows]);

  if (rows?.length === 0) return null;

  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.sku_material}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                <InputSizeUni row={row} />
              </TableCell>
              <TableCell>{row.uf}</TableCell>
              <TableCell>
                <InputRelativity row={row} />
              </TableCell>
              {!isUserSupport && (
                <TableCell>
                  <DeleteContainer>
                    <DeleteSvg onClick={() => onDelete(row.id)} />
                  </DeleteContainer>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isAdding ? (
        <Table>
          <TableHead>
            <TableRow>
              {addColumns.map((column) => (
                <TableCell key={column.Header}>{column.Header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
              </TableCell>
              <TableCell>
                {error && (
                  <Error>
                    <ErrorSvg /> {error}
                  </Error>
                )}
              </TableCell>
              <TableCell>
                <Options>
                  <CancelSvg onClick={onCancel} />
                  <CreateSvg onClick={onAdd} />
                </Options>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        !isUserSupport && (
          <Add onClick={() => setIsAdding(true)}>
            <AddSvg />
          </Add>
        )
      )}
    </Paper>
  );
};

export default Body;
