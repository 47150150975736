import { memo, useMemo } from "react";
import { useLocation } from "react-router";

import { Container, Month } from "./styles";

const SceneryMonths: React.FC = () => {
  const { state } = useLocation<any>();

  const data = useMemo(() => {
    return (
      state?.sceneryMonths?.map?.(
        (month: string) => month.slice(4) + "/" + month.slice(0, 4)
      ) || []
    );
  }, [state?.sceneryMonths]);

  if (data.length === 0) return null;
  return (
    <Container>
      {data.map((month: string) => (
        <Month key={month}>{month}</Month>
      ))}
    </Container>
  );
};

export default memo(SceneryMonths);
