import { useMemo, forwardRef, useRef, useCallback, useEffect } from "react";
import { matchSorter } from "match-sorter";
import { FaLock as LockedSvg } from "react-icons/fa";
import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Table as MUITable,
} from "@material-ui/core";
import {
  useTable,
  useFilters,
  useExpanded,
  useRowState,
  useRowSelect,
  usePagination,
  useGlobalFilter,
  useMountedLayoutEffect,
} from "react-table";

import Row from "./Row";
import { HeaderCheckbox } from "../styles";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={resolvedRef}
      {...rest}
      style={{ margin: "0.9rem 0 0.9rem 0.9rem", cursor: "pointer" }}
    />
  );
});

export default function EnhancedTable({
  data,
  columns,
  checkbox,
  currentPage,
  rowsPerPage,
  updateTableData,
  checkboxOnChange,
  renderRowSubComponent,
}) {
  // Filter setup for react-table
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  // react-table setup
  const {
    page,
    gotoPage,
    allColumns,
    prepareRow,
    setPageSize,
    headerGroups,
    getTableProps,
    visibleColumns,
    selectedFlatRows,
  } = useTable(
    {
      data,
      columns,
      defaultColumn,
      autoResetPage: false,
      autoResetFilters: false,
      updateTableData,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowState,
    (hooks) =>
      checkbox &&
      hooks.allColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ column, getToggleAllRowsSelectedProps }) => {
            return column.filteredRows.find(
              (row) => !!row.original.blockEditing
            ) ? (
              <LockedSvg
                style={{
                  margin: "1rem 1rem 1.25rem",
                  cursor: "not-allowed",
                  color: "#842029",
                }}
              />
            ) : (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            );
          },

          Cell: ({ row }) =>
            row.original.blockEditing ? (
              <LockedSvg
                style={{
                  margin: "1rem 1rem 0.5rem",
                  cursor: "not-allowed",
                  color: "#842029",
                }}
              />
            ) : (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          width: "8%",
        },
        ...columns,
      ])
  );

  // #1 Connects <Pagination /> to react-table
  const connectPaginationToTable = useCallback(() => {
    setPageSize(rowsPerPage);
  }, [setPageSize, rowsPerPage]);

  useEffect(() => {
    connectPaginationToTable();
  }, [connectPaginationToTable]);

  // #2 Updates react-table when changing pages via <Pagination />
  const handleChangePage = useCallback(() => {
    gotoPage(currentPage);
  }, [gotoPage, currentPage]);

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage]);

  // #3 Gets selected rows by the <Checkbox />
  useMountedLayoutEffect(() => {
    checkboxOnChange(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <TableContainer>
      <HeaderCheckbox>
        {allColumns.slice(3, allColumns.length - 1).map((column) => (
          <span key={column.id}>
            <input type="checkbox" {...column.getToggleHiddenProps()} />
            {column.Header}
          </span>
        ))}
      </HeaderCheckbox>
      <MUITable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell key={column.Header} width={column.width}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      userSelect: "none",
                      position: "relative",
                    }}
                  >
                    <div
                      {...column.getHeaderProps()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "1rem",
                        paddingTop: "2px",
                      }}
                    >
                      {column.render("Header")}
                    </div>
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => (
            <Row
              key={row.id}
              row={row}
              prepareRow={prepareRow}
              visibleColumns={visibleColumns}
              renderRowSubComponent={renderRowSubComponent}
            />
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}
