import { useCallback, useState } from "react";

import api from "../../../../../../../../../../services/api";
import Input from "../../../../../../../../../../components/Input";
import { money } from "../../../../../../../../../../utils/masks";
import { useAuth } from "../../../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputSizeUni: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [sizeUni, setSizeUni] = useState(row.size_uni);

  const onChange = useCallback((e) => {
    setSizeUni(money(e.target.value));
  }, []);

  const onBlur = useCallback(
    (e) => {
      let newVal = e.target.value || "0";
      setSizeUni(newVal);

      const url = "/param/update/relative-processados";
      const req = { id: row.id, size_uni: newVal };

      api.post(url, req).catch(() => {
        setSizeUni(row.size_uni);
      });
    },
    [row]
  );

  if (isUserSupport) return sizeUni;
  return (
    <Input
      name="sizeUni"
      value={sizeUni}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default InputSizeUni;
