export const cols = [
  {
    Header: "Atividade",
    accessor: "nivel02",
  },
  {
    Header: "Material",
    accessor: "material",
  },
  {
    Header: "Categoria",
    accessor: "nivel03",
  },
  {
    Header: "Proteína",
    accessor: "protein",
  },
  {
    Header: "Cluster",
    accessor: "bonus_sales",
  },
  {
    Header: "Preço Base",
    accessor: "preco_base",
  },
  {
    Header: "Preço Desafio",
    accessor: "preco_desafio",
  },
  {
    Header: "Preço Meta",
    accessor: "preco_meta",
  },
  {
    Header: "Preço Técnico",
    accessor: "preco_tecnico",
  },
  {
    Header: "PxP Desafio",
    accessor: "pxp_desafio",
  },
  {
    Header: "PxP Meta",
    accessor: "pxp_meta",
  },
  {
    Header: "PxP Técnico",
    accessor: "pxp_tecnico",
  },
  {
    Header: "Rob Desafio",
    accessor: "rob_desafio",
  },
  {
    Header: "Rob M1",
    accessor: "rob_m1",
  },
  {
    Header: "Rob Médio",
    accessor: "rob_medio",
  },
  {
    Header: "Rob Meta",
    accessor: "rob_meta",
  },
  {
    Header: "Volume M1",
    accessor: "volume_m1",
  },
  {
    Header: "Volume Médio",
    accessor: "volume_medio",
  },
];
