import { InputDeflator } from "./components";

export const columns = [
  {
    Header: "Filial",
    accessor: "region",
  },
  {
    Header: "Canal Agregado",
    accessor: "aggregate_channel",
  },
  {
    Header: "Canal Pricing",
    accessor: "channel",
  },
  {
    Header: "Material",
    accessor: "material",
  },
  {
    Header: "Investimento %",
    accessor: ({ row }: any) => <InputDeflator row={row} />,
  },
];
