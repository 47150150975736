import styled from "styled-components";
import {
  withStyles,
  TablePagination as MUITablePagination,
  TableCell,
} from "@material-ui/core";
import { Switch as MUISwitch, Toolbar as MUIToolbar } from "@material-ui/core";

import { Pagination as MUIPagination } from "@material-ui/lab";
import { shade } from "polished";

export const Container = styled.div`
  padding: 1rem 1rem 0;

  table {
    overflow: visible !important;

    input {
      font-size: 1rem;
    }

    td,
    th {
      width: auto;
      white-space: nowrap;
      height: 3rem;

      // Checkbox, History
      &:nth-child(1),
      &:nth-child(2) {
        width: 10px;
        padding: 0;
      }

      // Sku || Hierarchy
      &:nth-child(3) {
        left: 0px;
        position: sticky;
        z-index: 1;
      }

      filter: brightness(100%); // DON'T TOUCH
    }

    td {
      z-index: 1;
      position: relative;
      &:nth-child(3) {
        z-index: 51;
      }
    }

    th {
      z-index: 2;
      position: relative;
      &:nth-child(3) {
        z-index: 52;
      }
    }

    th {
      margin: 0;
      padding: 0 0.75rem;

      color: #003b74;
      font-size: 1rem;

      border-bottom: none;
      text-transform: capitalize;

      font-weight: 500;
      background-color: #f2f3f5;

      :first-child {
        border-top-left-radius: 8px;
      }

      :last-child {
        border-top-right-radius: 8px;
      }
    }
  }
`;
export const TablePagination = styled(MUITablePagination)`
  width: 100%;
  display: flex;
  border: none;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.6rem;

    > p {
      font-size: 1.4rem;
    }

    .MuiTablePagination-spacer {
      display: none;
    }
  }
`;

export const Switch = styled(MUISwitch)`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #003b74;
  }
  span.MuiSwitch-track {
    background-color: #003b74 !important;
  }
`;

export const Pagination = styled(MUIPagination)`
  div {
    font-size: 1.8rem;
    padding: 0 4px;

    width: 2.6rem;
    min-width: unset;
  }

  button {
    font-size: 1.5rem;
    height: 3rem;
    min-width: 3rem;
  }

  button.Mui-selected {
    background-color: #003b74;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${shade(0.3, "#003b74")};
    }
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Toolbar = styled(MUIToolbar)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div > p {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    margin-left: 1rem;
  }
`;

export const DropdownItem = styled.button`
  font-size: 1.3rem;
  padding: 1.6rem 0;
  width: 100%;
  background: none;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;

  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const ExportText = styled.h2`
  font-size: 1.4rem;
  color: #003b74;
  font-weight: 900;
`;

export const ExportButton = styled.button`
  font-size: 1.3rem;
  background: #f2f3f5;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;
  min-width: 4rem;
  color: #003b74;
  padding: 0.6rem;
  margin-left: 1.2rem;
  font-weight: 500;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: ${shade(0.1, "#f2f3f5")};
  }
`;
interface StatusLabelProps {
  status: string;
}

export const StatusLabel = styled.span<StatusLabelProps>`
  color: ${({ status }) =>
    status === "aprovado"
      ? "#0f5132"
      : status === "aguardando"
      ? "#664d03"
      : "#842029"};
  background: ${({ status }) =>
    status === "aprovado"
      ? "#d1e7dd"
      : status === "aguardando"
      ? "#fff3cd"
      : "#f8d7da"};
  padding: 5px 15px;
  border-radius: 50px;
`;

export const TableCellStyled = withStyles({
  root: {
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg": {
        cursor: "pointer",
      },
    },
  },
})(TableCell);

export const HeaderCheckbox = styled.span`
  display: flex;
  margin-bottom: 1rem;

  user-select: none;

  input {
    margin-right: 0.25rem;
  }

  span {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-size: 1rem;
  }
`;
