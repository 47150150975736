import { useMemo } from "react";
import { useParams } from "react-router-dom";

import PageContainer from "../../../../components/PageContainer";

import {
  Header,
  TableBaseSp,
  TableDeflator,
  TableMateriais,
  TableSkuXCapitao,
  TableElasticidade,
  TableCodigoCapitao,
  TableRelatividadeCanal,
  TablePrecoFinalComAdicional,
} from "./components";

const PriceBaseParameters: React.FC = () => {
  const { tableCoded } = useParams<any>();

  const [table, inputs] = useMemo(() => {
    const t = window.atob(window.atob(tableCoded.split("@")[0]));
    const i = JSON.parse(window.atob(window.atob(tableCoded.split("@")[1])));
    return [t, i];
  }, [tableCoded]);

  const component = useMemo(() => {
    return {
      "SKU X Capitão": <TableSkuXCapitao inputs={inputs} />,
      Deflator: <TableDeflator inputs={inputs} />,
      "Relatividade Canal": <TableRelatividadeCanal inputs={inputs} />,
      "Base Sp": <TableBaseSp inputs={inputs} />,
      "Código Capitão": <TableCodigoCapitao inputs={inputs} />,
      Elasticidade: <TableElasticidade inputs={inputs} />,
      "Adicional Financeiro Médio": (
        <TablePrecoFinalComAdicional inputs={inputs} />
      ),
      Materiais: <TableMateriais inputs={inputs} />,
    }[table];
  }, [table, inputs]);

  return (
    <PageContainer>
      <Header table={table} />
      {component}
    </PageContainer>
  );
};

export default PriceBaseParameters;
