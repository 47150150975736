import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";

type TExpanderColumn = {
  Header: () => null;
  id: string;
  Cell: ({ row }: any) => JSX.Element;
};

type TExpander = TExpanderColumn[];

type TGetExpander = (disableInputs: boolean) => TExpander;

const expander: TExpander = [
  {
    Header: () => null,
    id: "expander",
    Cell: ({ row }: any) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <RiArrowDownSLine size={16} />
        ) : (
          <RiArrowRightSLine size={16} />
        )}
      </span>
    ),
  },
];

export const getExpander: TGetExpander = (disableInputs: boolean) => {
  if (disableInputs) return [];

  return expander;
};
