import { getEndDateLimit } from "./getEndDateLimit";
import { getStartDateLimit } from "./getStartDateLimit";

type TGetDateLimist = (
  tabRequest: any,
  customStartDate?: any
) => [string, string];

export const getDateLimits: TGetDateLimist = (
  tabRequest: any,
  customStartDate: any = null
) => {
  const startDate = customStartDate || getStartDateLimit(tabRequest);
  const endDate = getEndDateLimit(tabRequest, startDate);

  return [startDate, endDate];
};
