import Status from "../../../../../components/Status";

export const getColumns = () => {
  return [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Solicitante",
      accessor: "requester",
    },
    {
      Header: "Origem Base",
      accessor: "base_origin",
    },
    {
      Header: "Filial",
      accessor: "filial",
    },
    {
      Header: "Adicionais",
      accessor: "adicionais",
    },
    {
      Header: "Observação",
      accessor: "observation",
    },
    {
      Header: "Status",
      accessor: ({ row }: any) => <Status status={row.status} />,
    },
    {
      Header: "Data de solicitação",
      accessor: "date_request",
    },
    {
      Header: "Histórico",
      accessor: "edit",
    },
  ];
};
