import styled from "styled-components";

export const HeaderModal = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  h1,
  p {
    margin-left: 2rem;
  }

  h1 {
    font-size: 18px;
    color: #003b74;
    font-weight: 900;
    margin-bottom: 2.5rem;
    b {
      margin-right: 1rem;
    }
  }

  p {
    font-size: 16px;
    b {
      color: #000;
      margin-right: 1rem;
    }
  }
`;
