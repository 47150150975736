import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 48px;
  padding: 30px;
  overflow: hidden;
  z-index: 99999;
`;
