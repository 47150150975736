import { scenValue } from "../../../../utils/scenValue";

export const taxCalculation = (row: any, key: string) => {
  if (!+row.antecipado) {
    return scenValue(row[key] - row.frete - row[key] * (row.imposto / 100));
  }

  return scenValue(row[key] - row.frete - row.preco_tab * (row.imposto / 100));
};

export const formatData = (data: any[]) => {
  return data.map((row: any) => ({
    ...row,
    volume_medio: scenValue(row.volume_medio),
    rob: scenValue(row.rob),
    preco_base: scenValue(row.preco_base),
    imposto: scenValue(row.imposto),
    mercado: (row.mercado * 100).toFixed(3).replace(".", ","),
    elasticity: row.elasticity.replace(".", ","),
    price_tecn: scenValue(row.price_tecn),
    rob_goal: scenValue(row.rob_goal),
    additional: scenValue(row.additional),
    price_goal: scenValue(row.price_goal),
    volume_m1: scenValue(row.volume_m1),
    delta:
      +row.relatividade_atual_com_capitao - +row.relatividade === 0
        ? scenValue("0.00")
        : scenValue(+row.relatividade_atual_com_capitao - +row.relatividade),
    relatividade_atual_com_capitao: row.relatividade_atual_com_capitao
      ? scenValue(row.relatividade_atual_com_capitao)
      : scenValue("0.00"),
    frete: row.frete?.replace(".", ","),
    relatividade: row.relatividade?.replace(".", ","),
    preco_tabela: scenValue(row.preco_tabela),
    preco_tab: scenValue(row.preco_tab),
    // preco_base_sp: taxCalculation(row, "price_challenge"),
    preco_base_sp: scenValue(row.preco_base_sp),
    // Inputs
    price_challenge: scenValue(row.price_challenge, true).replace(".", ","),
  }));
};
