import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from "react";
import { useHistory } from "react-router";
import api from "../services/api";

interface UserProps {
  id: string;
  created_at: string;
  email: string;
  firstName: string;
  lastName: string;
  profile_type: string;
  ativo: string;
  profileName: string;
  region: Array<{
    idRegion: number;
    nameRegion: string;
  }>;
  permissions: string[];
}

interface AuthState {
  token: string;
  user: UserProps;
}

interface AuthContextData {
  user: UserProps;
  isUserHQ: boolean;
  isUserSDP: boolean;
  isUserSupport: boolean;
  signIn(token: string): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@PrixsiaBRF:token");
    const user = localStorage.getItem("@PrixsiaBRF:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const isUserHQ = useMemo(() => {
    const HQregions = ["BRF HQ", "BRF HQ - In Natura", "BRF HQ - Processados"];
    return !!data.user?.region?.find((region: any) =>
      HQregions.includes(region.nameRegion)
    );
  }, [data.user?.region]);

  const isUserSDP = useMemo(() => {
    return data.user?.profile_type === "Input SDP";
  }, [data.user?.profile_type]);

  const isUserSupport = useMemo(() => {
    return /suporte/i.test(data.user?.profile_type || "");
  }, [data.user?.profile_type]);

  const signOut = useCallback(() => {
    localStorage.removeItem("@PrixsiaBRF:token");
    localStorage.removeItem("@PrixsiaBRF:user");

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(
    async (token) => {
      try {
        const response = await api.get(`users/token/${token}`);

        const { user, status } = response.data;

        if (!user) throw new Error("Erro ao retornar o usuário.");

        if (status === "error") throw new Error("Erro ao retornar o usuário.");

        localStorage.setItem("@PrixsiaBRF:token", token);
        localStorage.setItem("@PrixsiaBRF:user", JSON.stringify(user));

        setData({ token, user });

        history.push("/dashboard");
      } catch (err) {
        signOut();
        history.push("/login");
      }
    },
    [signOut, history]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        isUserHQ,
        isUserSDP,
        isUserSupport,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
};

export default AuthProvider;
