import { tabListToTableData } from "./tabListToTableData";

import { THandleSetTableData, TTableData } from "./types";

export const handleSetTableData: THandleSetTableData = (
  tabList,
  tabRequest,
  callback
) => {
  tabList.sort((a: any, b: any) => a.material - b.material);
  tabList.sort((a: any, b: any) => a.hierarquia - b.hierarquia);

  const tableData: TTableData = tabListToTableData(tabList, tabRequest);

  callback({ tableData });
};
