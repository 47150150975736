type TConditionLabels = (label: string) => string;

export const conditionLabels: TConditionLabels = (label) =>
  ({
    Y500: "Y500 - Preço",
    Y501: "Y501 - Desconto",
    Y540: "Y540 - DMX Gerar",
    Y546: "Y546 - DMX Receber",
    YVPR: "YVPR - Verba Preço",
  }[label] || label);
