import { InputDeflator } from "./components";

export const columns = [
  {
    Header: "Sku Capitão",
    accessor: "sku_capitao",
  },
  {
    Header: "UF",
    accessor: "region",
  },
  {
    Header: "Canal Agregador",
    accessor: "channel",
  },
  {
    Header: "IP Meta",
    accessor: ({ row }: any) => <InputDeflator row={row} type="target" />,
  },
  {
    Header: "IP Otimizador",
    accessor: ({ row }: any) => (
      <InputDeflator row={row} type="target_otimizador" />
    ),
  },
];
