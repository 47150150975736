import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin 0 0.75rem;
    height: 3rem;
  }
`;

export const Divider = styled.div`
  height: 3rem;
  margin 0 0.75rem;
  border-left: 1px solid #ddd7e6;
`;
