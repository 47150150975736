import styled from "styled-components";

import { ContainerProps } from "./interfaces";

export const Container = styled.span<ContainerProps>`
  width: 100%;
  max-width: 16rem;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;
  border-radius: 50px;

  color: ${({ textColor }) => textColor};
  background: ${({ backgroundColor }) => backgroundColor};

  span {
    padding: 0 15px;

    font-size: 1rem;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
  }
`;
