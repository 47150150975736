import { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../../../../components/Button";
import ButtonNewScenery from "./ButtonNewScenery";

import { Container, Divider } from "./styles";

const Buttons: React.FC = () => {
  const history = useHistory();

  const goToParameters = useCallback(() => {
    history.push("/price-base/parameters");
  }, [history]);

  return (
    <Container>
      <Button
        containerStyle={{
          backgroundColor: "transparent",
          border: "1px solid #003b74",
          color: "#003b74",
        }}
        onClick={goToParameters}
      >
        Alterar Parâmetros
      </Button>
      <Divider />
      <ButtonNewScenery />
    </Container>
  );
};

export default memo(Buttons);
