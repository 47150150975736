import styled from "styled-components";

export const Container = styled.div`
  gap: 1rem;
  margin: 1rem 0;
  display: flex;
`;

export const Month = styled.div`
  width: auto;
  padding: 0.5rem 1rem;

  display: flex;
  alignitems: center;
  textalign: center;

  color: gray;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 8px;
`;
