import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import api from "../../../../../../services/api";
import InputComponent from "../../../../../../components/Input";
import { moneyV2 } from "../../../../../../utils/masks";
import { formatData } from "../../actions";

const Input: React.FC<any> = ({
  row,
  param,
  getRows,
  updateRow,
  toggleBigDataReset,
}) => {
  const { state } = useLocation<any>();

  const [value, setValue] = useState(row[param]);
  const [focusValue, setFocusValue] = useState(row[param]);

  const onBlur = useCallback(() => {
    if (!value) return setValue(focusValue);
    if (value === row[param]) return;

    const url = "/price/service-orders/scenery";
    const req = { id: row.id, [param]: value.replace(",", ".") };

    api.put(url, req).then((res) => {
      const formattedRow: any = formatData([{ ...res.data }]);
      updateRow({ ...row, ...formattedRow[0], [param]: value });
      getRows();
      toggleBigDataReset();
    });
  }, [focusValue, getRows, param, row, toggleBigDataReset, updateRow, value]);

  const onFocus = useCallback(() => {
    setFocusValue(value);
    setValue("");
  }, [value]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(moneyV2(e.target.value));
  }, []);

  if (state?.sceneryViewOnly) return row[param];
  return (
    <InputComponent
      name={param}
      placeholder={focusValue}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default Input;
