import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowLeft as ParametersSvg } from "react-icons/fa";

import Button from "../../../../../components/Button";
import PageTitle from "../../../../../components/PageTitle";
import HeaderContainer from "../../../../../components/HeaderContainer";

const Header: React.FC = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push("/price-base");
  }, [history]);

  const goToUpload = useCallback(() => {
    history.push("/price-base/parameters/upload");
  }, [history]);

  return (
    <HeaderContainer>
      <PageTitle
        title="Alteração de parâmetros"
        onClick={goBack}
        icon={<ParametersSvg />}
        containerStyle={{ cursor: "pointer" }}
      />
      <Button
        width="14rem"
        containerStyle={{
          height: "4rem",
          border: "1px solid #dfac03",
          backgroundColor: "#dfac03",
        }}
        onClick={goToUpload}
      >
        Upload de Arquivo
      </Button>
    </HeaderContainer>
  );
};

export default Header;
