import { scenValue } from "../../../../utils/scenValue";

export const formatData = (data: any[]) => {
  return data.map((row: any) => ({
    ...row,
    elasticidade: scenValue(row.elasticidade),
    deflator: scenValue(row.deflator),
    volume_medio: scenValue(row.volume_medio),
    preco_base: scenValue(row.preco_base),
    mercado: scenValue(row.mercado * 100),
    price_tecn: scenValue(row.price_tecn),
    rob_goal: scenValue(row.rob_goal),
    additional: scenValue(row.additional),
    custo: scenValue(row.custo),
    price_goal: scenValue(row.price_goal),
    price_challenge: scenValue(row.price_challenge),
    preco_tabela: scenValue(row.preco_tabela),
    preco_tab: scenValue(row.preco_tab),
    ppv_simulado: scenValue(row.ppv_simulado),
    final_price: scenValue(row.final_price),
    valor_final_com_relatividade_com_financeiro: scenValue(
      row.valor_final_com_relatividade_com_financeiro
    ),
    delta: scenValue(row.delta),
    preco_vc_vd: scenValue(row.preco_vc_vd),
    relatividade_atual: scenValue(row.relatividade_atual),
    preco_base_sp: scenValue(row.preco_base_sp),
    volume: scenValue(row.volume),
    rob: scenValue(row.rob),
    rob_volume: scenValue(row.rob_volume),
    volume_m1: scenValue(row.volume_m1),
    volume_pxp: scenValue(row.volume_pxp),
    rob_base: scenValue(row.rob_base),
    rob_final: scenValue(row.rob_final),
    pxp_final: scenValue(row.pxp_final),
    rob_tabela: scenValue(row.rob_tabela),
    // Inputs
    valor_final_com_relatividade: scenValue(
      row.valor_final_com_relatividade,
      true
    ).replace(".", ","),
  }));
};
