import { useState, useEffect, useCallback } from "react";

import api from "../../../../../services/api";
import { Multiselect } from "../../../../../components/AutoComplete";

import { IInputProps, ICategoryItem, TOptionsCategoryItem } from "./types";

const Input: React.FC<IInputProps> = ({ value, proposal, setValue }) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    api
      .get("/price/list/category-items")
      .then((response) => {
        const formattedOptions = response.data.map((item: any) => ({
          key: item.pstyv,
          label: { label: `${item.pstyv} - ${item.vtext}`, value: item.pstyv },
        }));

        setOptions(formattedOptions);
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, []);

  const filterOptions = useCallback(
    (itemsRgx: RegExp) => {
      return options.filter((categoryItem: ICategoryItem) => {
        return itemsRgx.test(categoryItem.label.label);
      });
    },
    [options]
  );

  const optionsCategoryItem: TOptionsCategoryItem = useCallback(() => {
    if (/Y540-N5|Y546-N5/i.test(proposal)) {
      return filterOptions(/Y106|Y107|Y108|Y111|Y115|Z098|Z101|Z102/i);
    }

    if (/Y501-N4/i.test(proposal)) {
      return filterOptions(/Y107|Y108|Y111|Y115|Z101|Z102/i);
    }

    if (/Y501-N7/i.test(proposal)) {
      return filterOptions(/Y106|Z098|Y105|Z097/i);
    }

    return options;
  }, [proposal, options, filterOptions]);

  return (
    <Multiselect
      label="Categoria de Itens"
      value={value}
      options={optionsCategoryItem()}
      setValue={setValue}
      errorMessage={errorMessage}
    />
  );
};

export default Input;
