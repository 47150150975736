import Input from "./Input";

import { IProps } from "./types";

const InputCategoryItems: React.FC<IProps> = ({
  value,
  proposal,
  setValue,
  shouldRender,
}) => {
  if (!shouldRender("categoria_de_items")) return null;
  return <Input value={value} proposal={proposal} setValue={setValue} />;
};

export default InputCategoryItems;
