import {
  FaCheckDouble as UploadActiveSku,
  FaFileSignature as UploadUpdateLinesSvg,
  FaFileDownload as DownloadBaseSvg,
} from "react-icons/fa";

import { IUploadOption } from "../../../../components/UploadParam/types";

export const options: {
  [key: string]: IUploadOption;
} = {
  "Relatividade De Produtos": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-relatividade-de-produtos",
    exportEndpoint: "/param/export?param=relatividade_de_produtos",
    roleType: "processed",
  },
  "Relatividade de Canais": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-relative-channel",
    exportEndpoint: "/param/export?param=relatividade_de_canais",
    roleType: "processed",
  },
  Investimento: {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-investimento",
    exportEndpoint: "/param/export?param=investimento",
    roleType: "processed",
  },
  "Ip Target": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-ip-target",
    exportEndpoint: "/param/export?param=ip_target",
    roleType: "processed",
  },
  "Margem Min & Max": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-margem",
    exportEndpoint: "/param/export?param=margem_min_e_max",
    roleType: "processed",
  },
  "Range de Preço": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-range",
    exportEndpoint: "/param/export?param=range_de_preco",
    roleType: "processed",
  },
  Materiais: {
    buttons: {
      uploadSvg: UploadActiveSku,
      uploadText: "Upload materiais ativos",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar materiais ativos",
    },
    endpoint: "/param/upload-material?role=processed",
    exportEndpoint: "/param/export?param=materiais",
    roleType: "processed",
  },
};
