import { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import HeaderContainer from "../../../../components/HeaderContainer";
import api from "../../../../services/api";

import Buttons from "./Buttons";
import PageTitle from "./PageTitle";
import { Scenery } from "./styles";

const Header: React.FC<any> = ({ columns, filters, setFilters }) => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { sceneryId, type } = useParams<any>();

  const [disabled, setDisabled] = useState(false);

  const getSceneryStepTwoStatus = useCallback(() => {
    const url = "price/scenery/check-etapa-2?scenery_id=" + sceneryId;

    return api.get(url).then((res) => {
      const { data } = res;

      if (data) {
        setDisabled(data.status);
      }
    });
  }, [sceneryId]);

  useEffect(() => {
    getSceneryStepTwoStatus();
  }, [getSceneryStepTwoStatus]);

  const updateType = useCallback(
    (typeLetter: string) => {
      if (disabled) return;

      history.push({
        pathname: `/price-base/new-scenery/${sceneryId}/${typeLetter}`,
        state,
      });
    },
    [disabled, history, sceneryId, state]
  );

  return (
    <HeaderContainer>
      <PageTitle />
      <div style={{ display: "flex" }}>
        {["A", "B", "C"].map((typeLetter) => (
          <Scenery
            disabled={disabled}
            selected={type === typeLetter}
            onClick={() => updateType(typeLetter)}
          >
            {typeLetter}
          </Scenery>
        ))}
      </div>
      <Buttons columns={columns} filters={filters} setFilters={setFilters} />
    </HeaderContainer>
  );
};

export default memo(Header);
