import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaArrowLeft as ParametersSvg } from "react-icons/fa";

import Button from "../../../../../components/Button";
import PageTitle from "../../../../../components/PageTitle";
import HeaderContainer from "../../../../../components/HeaderContainer";

const Header: React.FC = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push("/processed");
  }, [history]);

  return (
    <HeaderContainer>
      <PageTitle
        title="Alteração de parâmetros"
        onClick={goBack}
        icon={<ParametersSvg />}
        containerStyle={{ cursor: "pointer" }}
      />
      <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
        <Link to="/processed/parameters/validate">
          <Button
            width="14rem"
            containerStyle={{
              height: "4rem",
            }}
          >
            Validar Parâmetros
          </Button>
        </Link>
        <Link to="/processed/parameters/upload">
          <Button
            width="14rem"
            containerStyle={{
              height: "4rem",
              border: "1px solid #dfac03",
              backgroundColor: "#dfac03",
            }}
          >
            Upload de Arquivo
          </Button>
        </Link>
      </div>
    </HeaderContainer>
  );
};

export default Header;
