import { Checkbox, InputDeflator } from "./components";

export const columns = [
  {
    Header: "Filial",
    accessor: "region",
  },
  {
    Header: "Canal Agregado",
    accessor: "aggregate_channel",
  },
  {
    Header: "Pai / Canal Pricing",
    accessor: ({ row }: any) => <Checkbox row={row} />,
  },
  {
    Header: "Relatividade de Canais",
    accessor: ({ row }: any) => <InputDeflator row={row} />,
  },
];
