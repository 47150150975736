import isDmxOrigin from "../../../../../utils/isDmxOrigin";
import isDiscountOrigin from "../../../../../utils/isDiscountOrigin";
import {
  CellInput,
  CellValidate,
  CellVariation,
  CellInputDateFim,
  CellInputPercent,
  CellInputDateInicio,
} from "../../components";

type TInputColumn = {
  Header: string;
  accessor: string;
  Cell: (props: any) => JSX.Element;
};

type TInputColumns = TInputColumn[];

type TGetInputColumns = (
  proposal: string,
  disableInputs: boolean
) => TInputColumns;

const priceColumns = [
  {
    Header: "Novo preço R$",
    accessor: "newPrice",
    Cell: (props: any) => <CellInput {...props} />,
  },
  {
    Header: "Variação %",
    accessor: "variation",
    Cell: (props: any) => <CellVariation {...props} />,
  },
  {
    Header: "Nova Data Início",
    accessor: "dt_inicio",
    Cell: (props: any) => <CellInputDateInicio {...props} />,
  },
  {
    Header: "Nova Data Fim",
    accessor: "dt_fim",
    Cell: (props: any) => <CellInputDateFim {...props} />,
  },
  {
    Header: " ",
    accessor: "validation",
    Cell: (props: any) => <CellValidate {...props} />,
  },
];

const discountColumns = [
  {
    Header: "Novo Desconto %",
    accessor: "newPrice",
    Cell: (props: any) => <CellInputPercent {...props} />,
  },
  {
    Header: "Nova Data Início",
    accessor: "dt_inicio",
    Cell: (props: any) => <CellInputDateInicio {...props} />,
  },
  {
    Header: "Nova Data Fim",
    accessor: "dt_fim",
    Cell: (props: any) => <CellInputDateFim {...props} />,
  },
  {
    Header: " ",
    accessor: "validation",
    Cell: (props: any) => <CellValidate {...props} />,
  },
];

const dmxColumns = [
  {
    Header: "Novo DMX %",
    accessor: "newPrice",
    Cell: (props: any) => <CellInputPercent {...props} />,
  },
  {
    Header: "Nova Data Início",
    accessor: "dt_inicio",
    Cell: (props: any) => <CellInputDateInicio {...props} />,
  },
  {
    Header: "Nova Data Fim",
    accessor: "dt_fim",
    Cell: (props: any) => <CellInputDateFim {...props} />,
  },
  {
    Header: " ",
    accessor: "validation",
    Cell: (props: any) => <CellValidate {...props} />,
  },
];

export const getInputColumns: TGetInputColumns = (
  proposal: string,
  disableInputs: boolean
) => {
  let finalColumns: TInputColumns = priceColumns;

  if (isDmxOrigin(proposal)) {
    finalColumns = dmxColumns;
  }

  if (isDiscountOrigin(proposal)) {
    finalColumns = discountColumns;
  }

  if (disableInputs) {
    finalColumns = finalColumns
      .filter((col: TInputColumn) => col.accessor !== "validation")
      .map((col: TInputColumn) => ({
        ...col,
        Cell: ({ row }: any) => (
          <span>{row.original[col.accessor] || "-"}</span>
        ),
      }));
  }

  return [...finalColumns];
};
