import styled from "styled-components";

interface SelectWrapperStyledProps {
  width: string;
}

export const SelectWrapperStyled = styled.div<SelectWrapperStyledProps>`
  width: ${({ width }) => width};

  margin-top: 32px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #ddd7e6;

  label {
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 14px;
    font-size: 1.3rem;
    color: #003b74;
  }
  textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0.5rem 0.3rem;
    border-radius: 24px;
    background: #fff;
    font-size: 1.3rem;
    color: #7e7e7e;
    cursor: pointer;
  }
`;
