// I made these, so they may be explosive
// ⚠️ Use with care ⚠️

// Works with "13,50", "13.50", "13", "13,50 - N1", "Y500 - 13,50", etc.
export const extractPrice = /(?<![\w,.])\d+([,.]\d{2})?(?![\w,.])/i;

// Returns base url even if it doesn't include https://, www, etc
export const extractBaseUrl = /^(https?:\/\/)?(www\.)?([\w\d-:.]*\/?)/i;

export const isInt = /^\d+$/;
