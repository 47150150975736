import { memo } from "react";

import ClearButton from "./ClearButton";
import ApproveButton from "./ApproveButton";
import AlterPricesButton from "./AlterPricesButton";

import { Container } from "./styles";

const Options: React.FC<any> = ({
  tableData,
  setTableData,
  selectedRows = [],
}) => {
  return (
    <Container>
      <ClearButton setTableData={setTableData} />
      <AlterPricesButton
        tableData={tableData}
        setTableData={setTableData}
        selectedRows={selectedRows}
      />
      <ApproveButton tableData={tableData} />
    </Container>
  );
};

export default memo(Options);
