import moment from "moment";

import api from "../../../../services/api";

const requester = (upload: any) => {
  return `${upload.firstName} ${upload.lastName}`;
};

const base_origin = (upload: any) => {
  return upload.condition && upload.nivel
    ? `${upload.condition}-${upload.nivel}`
    : "";
};

// const date_request = (date: Date) => {
//   return moment.utc(date).format("DD/MM/YYYY HH:mm");
// };

const date_request = (date: Date) => {
  const gmtPlus3 = moment.utc(date).utcOffset("-03:00");
  return gmtPlus3.format("DD/MM/YYYY HH:mm");
};

export const getTableData = (callback: (args1: any) => void) => {
  api
    .get("/price/upload/list")
    .then((response: any) => {
      const formattedData = response.data.map((upload: any) => {
        return {
          id: upload.id,
          requester: requester(upload),
          base_origin: base_origin(upload),
          date_request: date_request(upload.date_created),
          uploadStatus: upload.request_status,
          observation: "",
          edit: true,
        };
      });

      callback({ error: false, tableData: formattedData });
    })
    .catch((error) => {
      return {
        error: true,
        message: error,
      };
    });
};
