import { memo, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Button from "../../../../../components/Button";
import SceneryFilters from "../../../../../components/SceneryFilters";
import ButtonExportExcelViaUrl from "../../../../../components/ButtonExportExcelViaUrl";

import ButtonSendToApprove from "./ButtonSendToApprove";
import { Container } from "./styles";

const Buttons: React.FC<any> = ({ columns, filters, setFilters }) => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { sceneryId } = useParams<any>();

  const onGoBack = useCallback(() => {
    history.push("/price-base");
  }, [history]);

  return (
    <Container>
      <SceneryFilters
        url={`/price/scenery/relative/table-item-filters?scenery_id=${sceneryId}`}
        columns={columns}
        currentFilters={filters}
        setCurrentFilters={setFilters}
      />
      <ButtonExportExcelViaUrl
        url={`/price/scenery/relative/export?scenery_id=${sceneryId}`}
      />
      {state?.sceneryViewOnly ? (
        <Button width="8rem" onClick={onGoBack}>
          Início
        </Button>
      ) : (
        <ButtonSendToApprove />
      )}
    </Container>
  );
};

export default memo(Buttons);
