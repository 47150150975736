import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FaTimesCircle as DeleteSvg } from "react-icons/fa";

import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import { Autocomplete } from "../../../../../../components/AutoComplete";
import { Container, Dates, Inputs } from "./styles";
import api from "../../../../../../services/api";
import LoadingModal from "../../../../../../components/LoadingModal";
import AlertModal from "../../../../../../components/AlertModal";
import { AsyncSelectPaginated } from "../../../../../../components/AsyncSelectPaginated";
import { Controller, useForm } from "react-hook-form";

const monthOpt = [
  { key: "01", label: "Janeiro", value: "01" },
  { key: "02", label: "Feveiro", value: "02" },
  { key: "03", label: "Março", value: "03" },
  { key: "04", label: "Abril", value: "04" },
  { key: "05", label: "Maio", value: "05" },
  { key: "06", label: "Junho", value: "06" },
  { key: "07", label: "Julho", value: "07" },
  { key: "08", label: "Agosto", value: "08" },
  { key: "09", label: "Setembro", value: "09" },
  { key: "10", label: "Outubro", value: "10" },
  { key: "11", label: "Novembro", value: "11" },
  { key: "12", label: "Dezembro", value: "12" },
];

const monthValues: any = {
  Janeiro: "01",
  Fevereiro: "02",
  Março: "03",
  Abril: "04",
  Maio: "05",
  Junho: "06",
  Julho: "07",
  Agosto: "08",
  Setembro: "09",
  Outubro: "10",
  Novembro: "11",
  Dezembro: "12",
};

interface OptionProps {
  label: string;
  value: string | number;
}

export const yearsOpt = (() => {
  const max = new Date().getFullYear();
  const min = 2022;
  const size = max - min + 1;
  const years: number[] = [];

  _.times(size).map((value) => years.push(max - value));

  return years.map((year) => ({
    key: year,
    label: year,
    value: year,
  }));
})();

const ButtonNewScenery: React.FC = () => {
  const history = useHistory();
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [dates, setDates] = useState<string[]>([]);
  const [weekNumber, setWeekNumber] = useState("13");
  const [nielsenYear, setNielsenYear] = useState("");
  const [nielsenWeek, setNielsenWeek] = useState("");
  const [nielsenMonth, setNielsenMonth] = useState("");
  const [sceneryAlias, setSceneryAlias] = useState("");
  const [nielsenWeekOpt, setNielsenWeekOpt] = useState([]);

  const [isOpenSelectNivel02, setIsOpenSelectNivel02] = useState(false);
  const [isOpenSelectNivel03, setIsOpenSelectNivel03] = useState(false);
  const [isOpenSelectNivel04, setIsOpenSelectNivel04] = useState(false);
  const [isOpen, toggleOpen] = useToggle();

  const errorRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);

  const { control, watch, reset, resetField } = useForm();

  const [watchNivel02, watchNivel03, watchNivel04, watchSku] = watch([
    "nivel02",
    "nivel03",
    "nivel04",
    "sku",
  ]);

  const handleButtonClick = (setState: any) => {
    setState(false);

    setTimeout(() => {
      setState(true);
    }, 1000);
  };

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel02);

    resetField("nivel03");
  }, [watchNivel02, resetField]);

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel03);

    resetField("nivel04");
  }, [watchNivel03, resetField]);

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel04);

    resetField("sku");
  }, [watchNivel04, resetField]);

  // TODO: All Inputs Selected From Send in POST for Filters
  const allFiltersSelected = useMemo(() => {
    return {
      nivel01: ["PROCESSADOS", "PROCESSADOS TOTAL", "SPREADS"],
      nivel02: watchNivel02
        ? watchNivel02.map((item: OptionProps) => item.value)
        : null,
      nivel03: watchNivel03
        ? watchNivel03.map((item: OptionProps) => item.value)
        : null,
      nivel04: watchNivel04
        ? watchNivel04.map((item: OptionProps) => item.value)
        : null,
      nivel05: null,
      sku: watchSku ? watchSku.map((item: OptionProps) => item.value) : null,
    };
  }, [watchNivel02, watchNivel03, watchNivel04, watchSku]);

  const openError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setYear("");
      setMonth("");
      setNielsenYear("");
      setNielsenMonth("");
      setNielsenWeek("");
      setWeekNumber("13");
      setNielsenWeekOpt([]);
      setDates([]);
      setSceneryAlias("");
    }
  }, [isOpen]);

  useEffect(() => {
    setNielsenWeek("");
  }, [nielsenYear, nielsenMonth]);

  const updateDates = useCallback(() => {
    if (month && year) {
      setDates((current) => {
        const newDate = `${year}/${month}`;
        setYear("");
        setMonth("");
        if (current.includes(newDate)) return current;
        if (current.length === 3) current.shift();
        return [...current, newDate];
      });
    }
  }, [month, year]);
  useEffect(updateDates, [updateDates]);

  const getNielsenWeeks = useCallback(() => {
    if (!nielsenMonth && !nielsenYear) return;

    const url = `/param/info/nielsen-dates?year=${nielsenYear}&month=${nielsenMonth}`;

    api.get(url).then((res) => {
      setNielsenWeekOpt(
        res?.data?.dates?.map((item: string) => ({
          key: item,
          label: item.split("-").reverse().join("/"),
          value: item,
        })) ?? []
      );
    });
  }, [nielsenMonth, nielsenYear]);
  useEffect(getNielsenWeeks, [getNielsenWeeks]);

  const onDelete = useCallback((date: string) => {
    setDates((current) => current.filter((d) => d !== date));
  }, []);

  const goToNewScenery = useCallback(() => {
    const monthYearArr = dates.map((date) => {
      return date.split("/").join("");
    });

    const url = "param/generate/nielsen-macro";
    const req = {
      alias: sceneryAlias,
      month: monthYearArr,
      week_type: weekNumber,
      nielsen_week: nielsenWeek.split("/").reverse().join("-"),
    };

    openLoading(
      "Criando Cenário...",
      api
        .post(url, { ...req, filters: { ...allFiltersSelected } })
        .then((res) => {
          history.push({
            pathname: `/processed/scenery/${res.data.scenery_id}`,
            state: { sceneryMonths: monthYearArr },
          });
        })
        .catch(() => {
          openError();
        })
    );
  }, [
    dates,
    sceneryAlias,
    weekNumber,
    nielsenWeek,
    openLoading,
    allFiltersSelected,
    history,
    openError,
  ]);

  function toggleModal() {
    reset();
    toggleOpen();
  }

  return (
    <>
      <Button onClick={toggleModal}>Criar Cenário</Button>
      <Modal
        open={isOpen}
        setOpen={toggleModal}
        containerStyle={{ width: "700px" }}
        children={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              overflow: "hidden",
            }}
          >
            <span
              style={{
                fontSize: "2.5rem",
                fontWeight: "bolder",
                color: "#003b74",
              }}
            >
              Criar Cenário
            </span>
            <LoadingModal ref={loadingRef} />
            <AlertModal
              ref={errorRef}
              type="error"
              message="Erro ao criar, tente novamente."
            />
            <div
              style={{
                overflow: "auto",
                paddingRight: "2rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Input
                name="input"
                label="Nome"
                value={sceneryAlias}
                onChange={(e) => setSceneryAlias(e.target.value)}
              />
              <Inputs>
                <span
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "bolder",
                    color: "#003b74",
                  }}
                >
                  Selecione o período dos dados internos
                </span>
                <div className="container">
                  <Autocomplete
                    style={{ width: "100%" }}
                    label="Mês"
                    value={month}
                    options={monthOpt}
                    setValue={setMonth}
                  />
                  <Autocomplete
                    style={{ width: "100%" }}
                    label="Ano"
                    value={year}
                    options={yearsOpt}
                    setValue={setYear}
                  />
                </div>
              </Inputs>
              <Dates>
                {dates.map((date, i) => (
                  <div
                    key={date + i}
                    style={{
                      marginTop: "2rem",
                      width: "auto",
                      padding: "0.5rem 1rem",
                      border: "1px solid gray",
                      borderRadius: "12px",
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {date}
                    <DeleteSvg
                      style={{
                        color: "red",
                        fontSize: "1.5rem",
                        marginLeft: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => onDelete(date)}
                    />
                  </div>
                ))}
              </Dates>
              <Inputs>
                <span
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "bolder",
                    color: "#003b74",
                  }}
                >
                  Semana Nielsen
                </span>

                <div className="container">
                  <Autocomplete
                    label="Ano"
                    value={nielsenYear}
                    options={yearsOpt}
                    setValue={setNielsenYear}
                  />
                  <Autocomplete
                    label="Mês"
                    value={nielsenMonth}
                    options={monthOpt}
                    setValue={setNielsenMonth}
                  />

                  <Autocomplete
                    label="Semana"
                    value={nielsenWeek}
                    options={nielsenWeekOpt}
                    setValue={setNielsenWeek}
                  />
                </div>
              </Inputs>
              <Inputs>
                <div className="container">
                  <label className="radio__box">
                    <input
                      type="radio"
                      id="13"
                      checked={weekNumber === "13"}
                      name="13"
                      value="13"
                      onChange={({ target }) => setWeekNumber(target.value)}
                    />
                    <span>13 semanas</span>
                  </label>
                  <label className="radio__box">
                    <input
                      type="radio"
                      id="3"
                      checked={weekNumber === "3"}
                      name="3"
                      value="3"
                      onChange={({ target }) => setWeekNumber(target.value)}
                    />
                    <span>3 semanas</span>
                  </label>
                </div>
              </Inputs>
              <p
                style={{
                  marginTop: "1.5rem",
                  color: "#003b74",
                  fontSize: "1.3rem",
                  fontWeight: "bolder",
                }}
              >
                Níveis
              </p>
              <Container style={{ marginBottom: "1rem" }}>
                <Controller
                  name="nivel02"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <AsyncSelectPaginated
                      name={name}
                      isRequired
                      label="Nivel02"
                      serviceProps={{
                        method: "POST",
                        url: "/material/list/all/filters",
                        data: {
                          field: "nivel02",
                          type: "processados",
                        },
                      }}
                      mapOptions={(item) => ({
                        label: item.nivel02,
                        value: item.nivel02,
                      })}
                      isMulti
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {isOpenSelectNivel02 ? (
                  <Controller
                    name="nivel03"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <AsyncSelectPaginated
                        name={name}
                        label="Nivel03"
                        serviceProps={{
                          method: "POST",
                          url: "/material/list/all/filters",
                          filters:
                            watchNivel02 &&
                            watchNivel02.length > 0 &&
                            watchNivel02.map((item: any) => item.value),
                          data: {
                            field: "nivel03",
                            type: "processados",
                          },
                        }}
                        mapOptions={(item) => ({
                          label: item.nivel03,
                          value: item.nivel03,
                        })}
                        isMulti
                        onChange={onChange}
                        value={value}
                        isReadOnly={
                          !isOpenSelectNivel02 ||
                          !watchNivel02 ||
                          watchNivel02?.length === 0
                        }
                      />
                    )}
                  />
                ) : (
                  <AsyncSelectPaginated
                    label="Nivel03"
                    name="default"
                    isReadOnly
                  />
                )}
              </Container>
              <Container style={{ marginBottom: "1rem" }}>
                {isOpenSelectNivel03 ? (
                  <Controller
                    name="nivel04"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <AsyncSelectPaginated
                        name={name}
                        label="Nivel04"
                        serviceProps={{
                          method: "POST",
                          url: "/material/list/all/filters",
                          filters:
                            watchNivel03 &&
                            watchNivel03.length > 0 &&
                            watchNivel03.map((item: any) => item.value),
                          data: {
                            field: "nivel04",
                            type: "processados",
                          },
                        }}
                        mapOptions={(item) => ({
                          label: item.nivel04,
                          value: item.nivel04,
                        })}
                        isMulti
                        onChange={onChange}
                        value={value}
                        isReadOnly={
                          !isOpenSelectNivel03 ||
                          !watchNivel03 ||
                          watchNivel03?.length === 0
                        }
                      />
                    )}
                  />
                ) : (
                  <AsyncSelectPaginated
                    label="Nivel04"
                    name="default"
                    isReadOnly
                  />
                )}
                {isOpenSelectNivel04 ? (
                  <Controller
                    name="sku"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <AsyncSelectPaginated
                        name={name}
                        label="SKU"
                        serviceProps={{
                          method: "POST",
                          url: "/material/list/all/filters",
                          filters:
                            watchNivel04 &&
                            watchNivel04.length > 0 &&
                            watchNivel04.map((item: any) => item.value),
                          data: {
                            field: "sku",
                            type: "processados",
                          },
                        }}
                        mapOptions={(item) => ({
                          label: +item.sku,
                          value: item.sku,
                        })}
                        isMulti
                        onChange={onChange}
                        value={value}
                        isReadOnly={
                          !isOpenSelectNivel04 ||
                          !watchNivel04 ||
                          watchNivel04?.length === 0
                        }
                      />
                    )}
                  />
                ) : (
                  <AsyncSelectPaginated label="SKU" name="default" isReadOnly />
                )}
              </Container>
            </div>
            <Button
              disable={
                !sceneryAlias ||
                dates.length === 0 ||
                !nielsenYear ||
                !nielsenMonth ||
                !nielsenWeek
              }
              onClick={goToNewScenery}
              containerStyle={{ marginTop: "2rem" }}
            >
              Criar
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ButtonNewScenery;
