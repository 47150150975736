import { Checkbox, Input, HeaderCheckbox } from "./components";

export const limit = 30;

export const columnsDefaultStart = [
  {
    Header: ({ filters, getRows, allChecked, setAllChecked }: any) => (
      <HeaderCheckbox
        filters={filters}
        getRows={getRows}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
    ),
    accessor: ({ row, getRows }: any) => (
      <Checkbox row={row} getRows={getRows} />
    ),
  },
];

export const columnsToReplace: { [key: string]: any } = {
  ppc_min: {
    Header: "PPC Mín (Kg)",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="ppc_min"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
  aumento_reducao: {
    Header: "Aumento/Redução",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="aumento_reducao"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
  desc_acao: {
    Header: "Desc Ação",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="desc_acao"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
};
