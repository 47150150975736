import { useCallback, useMemo } from "react";
//
import IColumnToDisplay from "../../types/IColumnToDisplay";
import { Container } from "./styles";

interface IProps {
  col: IColumnToDisplay;
  setColsToDisplay: React.Dispatch<React.SetStateAction<IColumnToDisplay[]>>;
}

const ColCheckbox: React.FC<IProps> = ({ col, setColsToDisplay }) => {
  const isChecked = useMemo(() => {
    return col.display;
  }, [col]);

  const onChange = useCallback(() => {
    setColsToDisplay((current) => {
      const clone = [...current];

      const i = clone.findIndex((c) => c.Header === col.Header);

      clone[i].display = !col.display;

      return clone;
    });
  }, [col, setColsToDisplay]);

  if (!col.Header || !col.accessor) return null;
  return (
    <Container>
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span>{col.Header}</span>
    </Container>
  );
};

export default ColCheckbox;
