import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import GlobalFilter from "./GlobalFilter";

import { Toolbar } from "../styles";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "0 0.5rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function TableToolbar({
  showExportOptions,
  exportOptions,
  preGlobalFilteredRows,
  setGlobalFilter,
  globalFilter,
  buttons,
}) {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      {showExportOptions && exportOptions}
      <div />
      {buttons}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Toolbar>
  );
}
