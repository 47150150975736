import { memo } from "react";
import { useParams } from "react-router-dom";

import SceneryFilters from "../../../../../components/SceneryFilters";
import ButtonExportExcelViaUrl from "../../../../../components/ButtonExportExcelViaUrl";

import ButtonResume from "./ButtonResume";
import ButtonResult from "./ButtonResult";
import { Container } from "./styles";

const Buttons: React.FC<any> = ({ columns, filters, setFilters }) => {
  const { sceneryId } = useParams<any>();

  return (
    <Container>
      <SceneryFilters
        url={`/price/service-orders/scenery/table-item-filters?scenery_id=${sceneryId}`}
        columns={columns}
        currentFilters={filters}
        setCurrentFilters={setFilters}
      />
      <ButtonExportExcelViaUrl
        url={`/price/service-orders/scenery/export?scenery_id=${sceneryId}`}
      />
      <ButtonResume />
      <ButtonResult />
    </Container>
  );
};

export default memo(Buttons);
