import Status from "../../../components/Status";
import { Delete, Edit } from "../components";

export const getColumns = () => {
  return [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Solicitante",
      accessor: "requester",
    },
    {
      Header: "Data de solicitação",
      accessor: "date_request",
    },
    {
      Header: "Nome do Cenário",
      accessor: "scenery_name",
    },
    {
      Header: "Status",
      accessor: ({ row }: any) => <Status status={row.status} />,
    },
    {
      Header: "",
      accessor: ({ row }: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Edit row={row} />
          {row.status === "Editando" && <Delete row={row} />}
        </div>
      ),
    },
  ];
};
