import { useEffect, useState, useCallback } from "react";
import Paper from "../Paper";
import PropTypes from "prop-types";

import EnhancedTableNew from "./components/EnhancedTableNew";
import TablePaginationActions from "./components/TablePaginationActions";

import { Container, TablePagination } from "./styles";

const App = ({
  pagination = true,
  showAllRows,
  tableData = [],
  columns = [],
  borderBottom = false,
  showToolbar = true,
  toolBarButtons = <div></div>,
  toolBarLeftComponents,
  containerStyle = {},
  showExportOptions = true,
  getPaginationProps = (data) => {},
  showSelectOptions = true,
  statusChangeHandler = (data) => {},
  filters = [{ search: true, selectFilter: false }],
  openModal = (event) => {},
}) => {
  const [newPage, setNewPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [dataCount, setDataCount] = useState(tableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = useCallback((_, newPageIndex) => {
    setNewPage(newPageIndex);
  }, []);

  useEffect(() => {
    getPaginationProps({
      dataCount,
      pageIndex,
      rowsPerPage,
      handleChangePage,
    });
  }, [dataCount, rowsPerPage, pageIndex, handleChangePage, getPaginationProps]);

  useEffect(() => {
    if (showAllRows) return setRowsPerPage(tableData.length);
  }, [showAllRows, tableData.length]);

  return (
    <Container borderBottom={borderBottom}>
      <Paper containerStyle={containerStyle}>
        <EnhancedTableNew
          data={tableData}
          columns={columns}
          openModal={openModal}
          showToolbar={showToolbar}
          toolBarButtons={toolBarButtons}
          showExportOptions={showExportOptions}
          showSelectOptions={showSelectOptions}
          toolBarLeftComponents={toolBarLeftComponents}
        />
      </Paper>
      {pagination && (
        <TablePagination
          page={pageIndex}
          count={dataCount}
          colSpan={3}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[rowsPerPage]}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            `Visualizando ${from} - ${to} de ${count}`
          }
        />
      )}
    </Container>
  );
};

App.propTypes = {
  pagination: PropTypes.bool,
  showAllRows: PropTypes.bool,
  tableData: PropTypes.array,
  columns: PropTypes.array,
  borderBottom: PropTypes.bool,
  showToolbar: PropTypes.bool,
  toolBarButtons: PropTypes.node,
  CustomPagination: PropTypes.node,
  toolBarLeftComponents: PropTypes.node,
  showSelectOptions: PropTypes.bool,
  containerStyle: PropTypes.object,
  statusChangeHandler: PropTypes.func,
  getPaginationProps: PropTypes.func,
  showExportOptions: PropTypes.bool,
};

export default App;
