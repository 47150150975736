import { useMemo, useCallback, useState, useEffect, Fragment } from "react";
import { ListItemText } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

import { FaUsers } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { IoPricetagsSharp } from "react-icons/io5";
import { FaHome } from "react-icons/fa";

import api from "../../../services/api";
import { useAuth } from "../../../contexts/AuthContext";

import { Container, TopCurve, BottomCurve, Item, ListItemIcon } from "./styles";

interface IListItemsProps {
  active: boolean;
  showItem: boolean;
  title: string;
  icon: React.ReactNode;
  navigatePath: string;
}

const ListItems: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const showPage = useCallback(
    (permission: string) => {
      if (!user) return false;
      if (Object.keys(user).length === 0) return false;
      if (/master/i.test(user.profile_type)) return true;
      return user.permissions.includes(permission);
    },
    [user]
  );

  const itemsList = useMemo(
    () => [
      {
        active: true,
        showItem: true,
        title: "Início",
        icon: <FaHome />,
        navigatePath: "/dashboard",
      },
      {
        active: false,
        title: "Controle de acesso",
        icon: <FaUsers />,
        showItem: showPage("view_access_control"),
        navigatePath: "/access/list",
      },
      {
        active: false,
        title: "Gestão de preços",
        icon: <IoPricetagsSharp />,
        showItem: showPage("view_price_management"),
        navigatePath: "/price-management",
      },
      {
        active: false,
        title: "Base de Preços (In Natura)",
        icon: <IoPricetagsSharp />,
        showItem:
          showPage("view_in_natura") ||
          user?.region
            .map((r: any) => r.nameRegion)
            .includes("BRF HQ - In Natura"),
        navigatePath: "/price-base",
      },
      {
        active: false,
        title: "Base de Preços (Processados)",
        icon: <IoPricetagsSharp />,
        showItem:
          showPage("view_processed") ||
          user?.region
            .map((r: any) => r.nameRegion)
            .includes("BRF HQ - Processados"),
        navigatePath: "/processed",
      },
      {
        active: false,
        title: "Aprovação",
        icon: <FaCheckDouble />,
        showItem: showPage("view_approval"),
        navigatePath: "/approval",
      },
      {
        active: false,
        title: "Relatórios",
        icon: <IoDocumentText />,
        showItem: showPage("view_reports"),
        navigatePath: "/reports",
      },
    ],
    [showPage, user?.region]
  );

  const [listState, setListState] = useState<IListItemsProps[]>(itemsList);

  useEffect(() => {
    const [, routeName] = pathname.split("/");

    setListState((prevState) => {
      return prevState.map((navItem) => ({
        ...navItem,
        active: routeName
          ? navItem.navigatePath.includes(routeName)
            ? false
            : false
          : navItem.navigatePath === "/dashboard"
          ? false
          : false,
      }));
    });
  }, [pathname]);

  return (
    <Container>
      {listState.map((item) => {
        if (!item.showItem) return <></>;

        if (item.title === "Relatórios") {
          return (
            <Item
              key={item.title}
              button
              active={String(item.active)}
              onClick={async () => {
                let user;
                const userStr = localStorage.getItem("@PrixsiaBRF:user");
                if (userStr) user = JSON.parse(userStr);

                return api
                  .post("/users/qlik-url", { email: user.email })
                  .then((res) => {
                    window.open(res.data.url, "_blank")!.focus();
                  });
              }}
            >
              <TopCurve className="curve" active={String(item.active)} />
              <BottomCurve className="curve" active={String(item.active)} />
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </Item>
          );
        }

        return (
          <Fragment key={item.title}>
            {item.showItem && (
              <Link to={item.navigatePath} style={{ color: "rgb(65, 70, 75)" }}>
                <Item key={item.title} button active={String(item.active)}>
                  <TopCurve className="curve" active={String(item.active)} />
                  <BottomCurve className="curve" active={String(item.active)} />
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </Item>
              </Link>
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default ListItems;
