import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

import api from "../../services/api";
import Modal from "../Modal";
import Button from "../Button";
import IColumn from "../TableBackendV2/types/IColumn";
import useToggle from "../../hooks/useToggle";
import LoadingModal from "../LoadingModal";
import { useToast } from "../../contexts/ToastContext";

import * as S from "./styles";
import Select from "../Select";

interface IProps {
  columns: IColumn[];
  stickyPos: number;
  columnsOrder: number[];
  tableName:
    | "natura_scenery"
    | "natura_result"
    | "natura_relativity"
    | "processed_scenery"
    | "processed_details";
}

const ButtonEditTableLayout: React.FC<IProps> = ({
  columns,
  stickyPos,
  columnsOrder,
  tableName,
}) => {
  const { addToast } = useToast();

  const [isOpen, toggleOpen] = useToggle();
  const [newStickyPos, setNewStickyPos] = useState(stickyPos);
  const [editableColumns, setEditableColumns] = useState<any[]>([]);

  const loadingRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const onOpen = useCallback(() => {
    setNewStickyPos(stickyPos);
    setEditableColumns(
      columns
        .filter((col) => col.Header && typeof col.Header === "string")
        .map((col, i) => {
          if (typeof col.accessor === "string") {
            return { ...col, order: columnsOrder[i] };
          }

          return {
            Header: col.Header,
            accessor: col.accessor({}).props.param,
            order: columnsOrder[i],
          };
        })
    );
  }, [columns, columnsOrder, stickyPos]);

  useEffect(() => {
    if (isOpen) onOpen();
  }, [isOpen, onOpen]);

  const moveColumn = useCallback((currentPos: number, newPos: number) => {
    setEditableColumns((current) => {
      const clone = [...current];
      const itemToMove = clone[currentPos];
      clone[currentPos] = clone[newPos];
      clone[newPos] = itemToMove;
      return clone;
    });
  }, []);

  const onSave = useCallback(() => {
    const url = "/price/scenery-layout";
    const req = {
      order: editableColumns.map((c) => c.order),
      sticky_pos: newStickyPos,
      table_name: tableName,
    };

    console.log("req", req);

    openLoading(
      "Salvando informações...",
      api
        .post(url, req)
        .then(() => {
          addToast({
            type: "success",
            title: "Sucesso",
            description: "Informações salvas com sucesso.",
          });
          setTimeout(() => window.location.reload(), 1000);
        })
        .catch(() => {
          addToast({
            type: "error",
            title: "Erro",
            description: "Erro ao salvar, tente novamente.",
          });
        })
    );
  }, [addToast, editableColumns, newStickyPos, openLoading, tableName]);

  if (columnsOrder?.length === 0 || columns?.length === 0 || !stickyPos) {
    return null;
  }
  return (
    <>
      <Button
        onClick={toggleOpen}
        containerStyle={{ width: "12rem", height: "3rem" }}
      >
        Editar Layout
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "90vw" }}
        children={
          <S.Container>
            <S.SectionText>Prender colunas na tela até a posição</S.SectionText>
            <Select
              width="26rem"
              name="sticky_pos"
              value={newStickyPos + ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setNewStickyPos(+e.target.value)
              }
              options={_.times(10, (n) => ({
                key: n + 1,
                value: n + 1,
                label: n + 1 + "",
              }))}
            ></Select>
            <S.SectionText>Selecione as posições das colunas</S.SectionText>
            <S.Cols>
              {editableColumns.map((c, i) => (
                <S.Col
                  key={c.order}
                  borderColor={i < newStickyPos ? "#003b74" : "lightgray"}
                >
                  {i > 0 && (
                    <FaChevronCircleLeft onClick={() => moveColumn(i, i - 1)} />
                  )}
                  <span>{c.Header}</span>
                  {i < editableColumns.length - 1 && (
                    <FaChevronCircleRight
                      onClick={() => moveColumn(i, i + 1)}
                    />
                  )}
                </S.Col>
              ))}
            </S.Cols>
            <S.Footer>
              <Button width="10rem" onClick={toggleOpen}>
                Cancelar
              </Button>
              <Button width="10rem" onClick={onSave}>
                Salvar
              </Button>
            </S.Footer>
          </S.Container>
        }
      />
      <LoadingModal ref={loadingRef} />
    </>
  );
};

export default ButtonEditTableLayout;
