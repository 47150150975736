import { useMemo, useState } from "react";

import Cols from "./Cols";
import TBody from "./TBody";
import THead from "./THead";
import IProps from "./types/IProps";
import Pagination from "./Pagination";
import IColumnToDisplay from "./types/IColumnToDisplay";
import { TableStyled } from "./styles";

const TableBackend: React.FC<IProps> = ({
  rows,
  cols,
  page,
  limit,
  total,
  setPage,
  totalPages,
  cellProps = {},
  stickyPos = 0,
  enableHideCols = false,
  subRowComponent,
}) => {
  const [colsToDisplay, setColsToDisplay] = useState<IColumnToDisplay[]>(
    cols.map((col) => ({ ...col, display: true }))
  );

  const columns = useMemo(() => {
    return colsToDisplay.filter((col) => col.display);
  }, [colsToDisplay]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
        }}
      >
        {enableHideCols && (
          <Cols
            colsToDisplay={colsToDisplay}
            setColsToDisplay={setColsToDisplay}
          />
        )}
        <TableStyled>
          <THead cols={columns} stickyPos={stickyPos} cellProps={cellProps} />
          <TBody
            cols={columns}
            rows={rows}
            cellProps={cellProps}
            stickyPos={stickyPos}
            subRowComponent={subRowComponent}
          />
        </TableStyled>
      </div>
      <Pagination
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default TableBackend;
