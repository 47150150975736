import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 1rem;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StepLabel = styled.span`
  color: #003b74;
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 0.1rem;
`;

export const ExpandButtonContainer = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-left: auto;

  span {
    color: #003b74;
    font-size: 1.2rem;
    font-weight: bold;
  }

  svg {
    font-size: 16px;
    color: #003b74;
  }

  :hover {
    filter: brightness(0.8);
    text-decoration: underline;
  }
`;

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin-left: 3rem;
  gap: 1rem;
`;

interface ResultLabelProps {
  color: string;
}
export const ResultLabel = styled.span<ResultLabelProps>`
  color: ${(props) => props.color};
  font-weight: bold;
  white-space: nowrap;
`;

export const ResultMessage = styled.span`
  color: rgb(65, 70, 75);
`;
