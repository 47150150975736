import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../../services/api";
import { Multiselect } from "../../../../../../../components/AutoComplete";

import { Container } from "./styles";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsInvestimento: React.FC<IProps> = ({ setInputs }) => {
  const [uf, setUf] = useState([]);
  const [sku, setSku] = useState([]);
  const [channel, setChannel] = useState([]);

  const [ufOpt, setUfOpt] = useState([]);
  const [skuOpt, setSkuOpt] = useState([]);
  const [channelOpt, setChannelOpt] = useState([]);

  const getUf = useCallback(() => {
    api.get("/price/list/regions").then((response) => {
      setUfOpt(
        response.data.regions.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.id },
        }))
      );
    });
  }, []);
  useEffect(getUf, [getUf]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      uf: uf.map((item: any) => item.value),
    }));
  }, [setInputs, uf]);

  const getChannel = useCallback(() => {
    api.get("/price/list/channels").then((response) => {
      setChannelOpt(
        response.data.channels.map((item: any, index: number) => ({
          key: index,
          label: { label: item.channel, value: item.channel },
        }))
      );
    });
  }, []);
  useEffect(getChannel, [getChannel]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      channel: channel.map((item: any) => item.value),
    }));
  }, [setInputs, channel]);

  useEffect(() => {
    setInputs((current) => ({ ...current, sku }));
  }, [setInputs, sku]);

  const getSku = useCallback(() => {
    api.get("/material/list/processados").then((response) => {
      setSkuOpt(
        response.data.material.map(
          (item: any, index: number) =>
            +item.sku && {
              key: index,
              label: { label: +item.sku, value: +item.sku },
            }
        )
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      sku: sku.map((item: any) => item.value),
    }));
  }, [setInputs, sku]);

  return (
    <>
      <Container>
        <Multiselect label="UF" value={uf} options={ufOpt} setValue={setUf} />
        <Multiselect
          label="Canal"
          value={channel}
          options={channelOpt}
          setValue={setChannel}
        />
      </Container>
      <Container>
        <Multiselect
          label="Material"
          value={sku}
          options={skuOpt}
          setValue={setSku}
        />
      </Container>
    </>
  );
};

export default InputsInvestimento;
