import moment from "moment";

import { getDateLimits } from "../getDateLimits";

export const validateRow = (row: any, tabRequest: any) => {
  const { new_price, new_data_ini, new_data_fim } = row;

  const formattedNewDataIni = new_data_ini
    ? moment.utc(new_data_ini).format("DD/MM/YYYY")
    : null;
  const formattedNewDataFim = new_data_fim
    ? moment.utc(new_data_fim).format("DD/MM/YYYY")
    : null;

  const [, endDateLimit] = getDateLimits(tabRequest, formattedNewDataIni); // End date limit based on data_ini
  const [defaultStartDateLimit] = getDateLimits(tabRequest);

  const minStartDate = moment(defaultStartDateLimit, "DD/MM/YYYY");

  const maxEndDate = moment(endDateLimit, "DD/MM/YYYY");

  if (formattedNewDataIni) {
    const startDate = moment(formattedNewDataIni, "DD/MM/YYYY");

    if (startDate.isBefore(minStartDate)) {
      return "invalid";
    }
  }

  if (formattedNewDataFim) {
    const endDate = moment(formattedNewDataFim, "DD/MM/YYYY");

    if (maxEndDate.isBefore(endDate)) {
      return "invalid";
    }
  }

  if (formattedNewDataIni && formattedNewDataFim) {
    const startDate = moment(formattedNewDataIni, "DD/MM/YYYY");
    const endDate = moment(formattedNewDataFim, "DD/MM/YYYY");

    if (endDate.isBefore(startDate)) {
      return "invalid";
    }
  }

  if (!isNaN(+new_price)) {
    return "valid";
  }

  return "missing_inputs";
};
