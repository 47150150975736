import { useCallback, memo, useRef } from "react";
import XLSX from "xlsx";

import * as S from "./styles";
import api from "../../../services/api";
import Button from "../../Button";
import AlertModal from "../../AlertModal";
import LoadingModal from "../../LoadingModal";
import { useToast } from "../../../contexts/ToastContext";
import { IUploadOption } from "../types";

interface IProps {
  filters: { [key: string]: any[] };
  selected: IUploadOption;
}

const DownloadExampleButton: React.FC<IProps> = ({ filters, selected }) => {
  const { addToast } = useToast();

  const linkRef = useRef<any>(null);
  const errorRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);
  const successRef = useRef<any>(null);

  const openError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const openSuccess = useCallback(() => {
    successRef.current.toggle();
  }, []);

  const downloadExcel = useCallback(() => {
    if (selected.exportEndpoint) {
      if (selected.filters) {
        const areAllArraysEmpty = Object.values(filters).every(
          (array) => array.length === 0
        );

        if (areAllArraysEmpty) {
          return addToast({
            type: "error",
            title: "Erro",
            description: "Selecione pelo menos um filtro.",
          });
        }
      }

      openLoading(
        "Exportando base de dados...",
        api
          .post(selected.exportEndpoint, { filters, role: selected.roleType })
          .then((res) => {
            let url = res.data.url;

            if (!url.match(/^https?:\/\//i)) {
              url = "https://" + url;
            }

            linkRef!.current!.href = url;
            linkRef!.current!.click();

            openSuccess();
          })
          .catch(() => {
            openError();
          })
      );
    }

    if (selected.exampleData) {
      const column = Object.keys(selected.exampleData[0]);
      const row = selected.exampleData;

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(row, { header: column });

      XLSX.utils.book_append_sheet(wb, ws1);
      XLSX.writeFile(wb, `Modelo.xlsx`);
    }
  }, [
    addToast,
    filters,
    openError,
    openLoading,
    openSuccess,
    selected.exampleData,
    selected.exportEndpoint,
    selected.filters,
    selected.roleType,
  ]);

  return (
    <>
      <S.SvgContainer>
        <Button
          onClick={downloadExcel}
          containerStyle={{
            width: 240,
            height: 40,
          }}
        >
          {selected.buttons.downloadSvg()}
          {selected.buttons.downloadText}
        </Button>
      </S.SvgContainer>
      <a ref={linkRef} style={{ display: "none" }} download />
      <LoadingModal ref={loadingRef} />
      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro ao exportar, tente novamente."
      />
      <AlertModal ref={successRef} message="Exportado com sucesso!" />
    </>
  );
};

export default memo(DownloadExampleButton);
