import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import Input from "../../../../../../../../components/Input";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputDeflator: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [range, setRange] = useState(row.range);

  const onChange = useCallback((e) => {
    setRange(e.target.value);
  }, []);

  const onBlur = useCallback(
    (e) => {
      let newVal = e.target.value || "0";
      setRange(newVal);

      const url = "/param/update/range";
      const req = {
        atividade: row.atividade,
        range: newVal,
      };

      api.post(url, req).catch(() => {
        setRange(row.deflator);
      });
    },
    [row]
  );

  if (isUserSupport) return range;
  return (
    <Input
      name="range"
      value={range}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default InputDeflator;
