import { useCallback } from "react";

import { extractPrice } from "../../../../../../utils/regex";

const CellVariation: React.FC<any> = ({ row }) => {
  const getPercentageString = useCallback(
    (currentPrice: number, newPrice: number) => {
      if (!currentPrice || !newPrice) return "";

      const decreaseValue = newPrice - currentPrice;
      const result = (decreaseValue / currentPrice) * 100;

      if (result === 0) return "0%";
      return result.toFixed(2) + "%";
    },
    []
  );

  const getTextColor = useCallback((resultNum: number) => {
    if (resultNum < 0) return "red";
    if (resultNum > 0) return "green";
    if (resultNum === 0) return "tan";
    return "transparent";
  }, []);

  const calculateVariaton = useCallback(() => {
    const newPrice = +row.original.newPrice;
    const currentPrice = +row.original.currentPrice
      ?.match(extractPrice)?.[0]
      ?.replace(",", ".");

    const resultStr = getPercentageString(currentPrice, newPrice);
    const resultNum = +resultStr.substring(0, resultStr.length - 1);

    return <span style={{ color: getTextColor(resultNum) }}>{resultStr}</span>;
  }, [row, getPercentageString, getTextColor]);

  return calculateVariaton();
};

export default CellVariation;
