import { shade } from "polished";
import styled from "styled-components";
import { ReactComponent as GoBackIconSVG } from "../../assets/icons/go-back.svg";
import Button from "../../components/Button";

import { IoArrowBack as IoArrowBackSVG } from "react-icons/io5";

import { Checkbox as MuiCheckbox } from "@material-ui/core";

export const GobackDiv = styled.div`
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: #003b74;
      stroke-width: 70px;
    }
  }
`;

export const GoBackIcon = styled(IoArrowBackSVG)`
  background: #fff;
  border-radius: 50%;
  width: 2.8rem;
  height: 2.8rem;
  padding: 0.6rem;

  transition: background 0.3s;

  cursor: pointer;

  margin-right: 0.8rem;

  &:hover {
    background: ${shade(0.1, "#fff")};
  }
`;

export const PaperTitle = styled.h2`
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  width: 100%;
  color: #001eba;
`;

export const PaperHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ddd7e6;
  margin-bottom: 2rem;
`;

export const SaveBtn = styled(Button)``;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// 1px solid #ddd7e5
export const Checkbox = styled(MuiCheckbox)`
  &.Mui-disabled {
    color: #c8c9e0 !important;
  }
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #001eba;
  }
  &.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const CheckboxLabel = styled.p`
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 0.2rem;
`;

export const ChannelsTitle = styled.h2`
  font-weight: 600;
  font-size: 1.6rem;
  width: 100%;
  color: #001eba;

  margin: 3rem 0 2rem;
`;
