import Input from "./Input";

import shouldRenderSection from "../../../../../utils/shouldRenderSection";

import { IProps } from "./types";

const InputCnpjRoot: React.FC<IProps> = ({
  value,
  region,
  setValue,
  proposal,
}) => {
  if (!shouldRenderSection(proposal, "grupo_de_preco")) return null;
  return <Input value={value} region={region} setValue={setValue} />;
};

export default InputCnpjRoot;
