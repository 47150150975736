import React from "react";
import _ from "lodash";

export const number = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  e.currentTarget.value = value;
  return e.currentTarget.value;
};

export const cpf = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  e.currentTarget.value = value;
  return e.currentTarget.value;
};

export const cnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, "$1.$2.$3/$4-$5");
  e.currentTarget.value = value;
  return e.currentTarget.value;
};

export const date = (value: string) => {
  let v = value.replace(/\D/g, "").slice(0, 8);
  if (v.length >= 5) return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
  if (v.length >= 3) return `${v.slice(0, 2)}/${v.slice(2)}`;
  return v;
};

export const money = (value: string) => {
  let v = value.replace(/\D/g, "");
  if (v.length > 0 && v.length <= 2) return "." + v;
  if (v.length > 2) {
    return (+`${v.slice(0, v.length - 2)}.${v.slice(v.length - 2)}`).toFixed(2);
  }
  return v;
};

export const value = (value: string) => {
  value += "";
  let x = value.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2");
  }
  return x1 + x2;
};

export const moneyV2 = (value: string, allowNegative = false) => {
  const isNegative = value.charAt(0) === "-";

  let v = value.replace(/\D/g, "");

  // 3 becomes 003 so the comma can be added latter,
  // so when the users types 3 it formats to 0,03 on the screen for example
  if (v.length < 3) {
    for (let i = 0; i < 4 - v.length; i += 1) {
      v = v.replace(/^/, "0");
    }
  }

  let finalValue = v;

  // Next 2 ifs format values by adding ","
  if (v.length > 0 && v.length <= 2) {
    finalValue = "," + v;
  }

  if (v.length > 2) {
    const beforeComma = v.slice(0, v.length - 2);
    const afterComma = v.slice(v.length - 2);
    const formattedStr = `${beforeComma}.${afterComma}`;
    finalValue = (+formattedStr).toFixed(2).replace(".", ",");
  }

  return (allowNegative && isNegative ? "-" : "") + finalValue;
};
