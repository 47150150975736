import { Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../services/api";
import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import { moneyV2 } from "../../../../../../utils/masks";

const EditAllButton: React.FC<any> = ({
  setLoading,
  checkedRows,
  setCheckedRows,
  setAllRowsChecked,
  toggleBigDataReset,
}) => {
  const { state } = useLocation<any>();
  const { sceneryId } = useParams<any>();

  const [meta, setMeta] = useState("");
  const [desafio, setDesafio] = useState("");
  const [precoMeta, setPrecoMeta] = useState("");
  const [precoDesafio, setPrecoDesafio] = useState("");
  const [isOpen, toggleOpen] = useToggle();

  useEffect(() => {
    if (!isOpen) {
      setMeta("");
      setDesafio("");
      setPrecoMeta("");
      setPrecoDesafio("");
    }
  }, [isOpen]);

  const onEdit = useCallback(() => {
    const req: any = {
      ids: checkedRows,
      scenery_id: sceneryId,
    };

    if (/[1-9]/.test(meta)) req.additional = meta.replace(",", ".");
    if (/[1-9]/.test(desafio)) req.challenge = desafio.replace(",", ".");
    if (/[1-9]/.test(precoMeta)) req.price_goal = precoMeta.replace(",", ".");
    if (/[1-9]/.test(precoDesafio))
      req.price_challenge = precoDesafio.replace(",", ".");

    api.put("/price/service-orders/scenery/all", req).then(() => {
      setCheckedRows([]);
      setAllRowsChecked(false);
      setLoading(true);
      toggleBigDataReset();
      toggleOpen();
    });
  }, [
    meta,
    desafio,
    precoMeta,
    precoDesafio,
    checkedRows,
    sceneryId,
    setCheckedRows,
    setAllRowsChecked,
    setLoading,
    toggleBigDataReset,
    toggleOpen,
  ]);

  if (state?.sceneryViewOnly) return null;
  return (
    <>
      <Button
        disable={checkedRows.length === 0}
        containerStyle={{
          width: "14rem",
          height: "3rem",
        }}
        onClick={toggleOpen}
      >
        Alterar Selecionados
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "420px" }}
        children={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <Input
              name="input"
              label="%Meta"
              value={meta}
              onChange={(e) => setMeta(moneyV2(e.target.value, true))}
            />
            <Input
              name="input"
              label="Preço Meta"
              value={precoMeta}
              onChange={(e) => setPrecoMeta(moneyV2(e.target.value))}
              containerStyle={{ marginTop: "4rem" }}
            />
            <Input
              name="input"
              label="%Desafio"
              value={desafio}
              onChange={(e) => setDesafio(moneyV2(e.target.value, true))}
              containerStyle={{ marginTop: "4rem" }}
            />
            <Input
              name="input"
              label="Preço Desafio"
              value={precoDesafio}
              onChange={(e) => setPrecoDesafio(moneyV2(e.target.value))}
              containerStyle={{ marginTop: "4rem" }}
            />
            <Button
              disable={!meta && !desafio && !precoMeta && !precoDesafio}
              onClick={onEdit}
              containerStyle={{ marginTop: "2rem" }}
            >
              Alterar Selecionados
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default EditAllButton;
