import TableSmallViewOnly from "../../../../components/TableSmallViewOnly";

interface IProps {
  columns: any;
  tableData: any;
  setTableData: any;
}

const Table: React.FC<IProps> = ({ columns, tableData, setTableData }) => {
  if (tableData.length === 0) return <></>;
  return (
    <TableSmallViewOnly
      data={tableData}
      columns={columns}
      setData={setTableData}
    />
  );
};

export default Table;
