import { useMemo, useCallback, memo } from "react";

import { usePriceManagement } from "../../../hooks";
import { LevelsRow, LevelButton } from "../../styles";

import { IProps, TNiveis, TShouldRender, THandleRender } from "./types";

const Niveis: React.FC<IProps> = ({ nivel, condition, setNivel }) => {
  const { isUserSDP, isUserHQ } = usePriceManagement();

  const niveis: TNiveis = useMemo(() => {
    return (
      {
        Y500: ["N1", "N2", "N5", "N6", "NP"],
        Y501: ["N1", "N2", "N4", "N5", "N6", "N7", "N8", "NP"],
        Y540: ["N1", "N2", "N3", "N4", "N5"],
        Y546: ["N1", "N2", "N3", "N4", "N5"],
        YVPR: ["N1", "N2", "N3", "N4", "N5", "N6", "N7"],
      }[condition] || []
    );
  }, [condition]);

  const shouldRender: TShouldRender = useCallback(
    (currentNivel: string) => {
      if (condition === "Y500") {
        if (isUserSDP && !["N6", "NP"].includes(currentNivel)) return false;
        if (currentNivel === "N1" && !isUserHQ) return false;
      }

      if (condition === "Y501") {
        if (isUserSDP && !["N6", "NP"].includes(currentNivel)) return false;
        if (["N1", "N8"].includes(currentNivel) && !isUserHQ) return false;
      }

      return true;
    },
    [condition, isUserSDP, isUserHQ]
  );

  const handleRender: THandleRender = useCallback(
    (currentNivel: string) => {
      if (!shouldRender(currentNivel)) return null;
      return (
        <LevelButton
          key={currentNivel}
          active={currentNivel === nivel}
          onClick={() => setNivel(currentNivel)}
        >
          {currentNivel}
        </LevelButton>
      );
    },
    [shouldRender, nivel, setNivel]
  );

  return <LevelsRow>{niveis.map(handleRender)}</LevelsRow>;
};

export default memo(Niveis);
