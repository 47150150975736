import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  Drawer,
  AppBar as MUIAppBar,
  List,
  ListItemText,
} from "@material-ui/core";

import { HiOutlineLogout } from "react-icons/hi";

import ListItems from "./ListItems";
import AppBarDropdown from "./AppBarDropdown";

import logoPricepoint from "../../assets/logo-white.png";
import logoBRF from "../../assets/brf-logo-white.png";

import {
  LogoImg,
  DropdownItem,
  useStyles,
  Toolbar,
  ToolbarWrapper,
  BorderDiv,
} from "./styles";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const AppBar: React.FC = () => {
  const classes = useStyles();
  const { user, signOut } = useAuth();

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MUIAppBar className={clsx(classes.appBar)}>
        <Toolbar>
          <Link to="/" style={{ maxHeight: "3.6rem" }}>
            <LogoImg src={logoPricepoint} />
          </Link>

          <LogoImg src={logoBRF} alt="BRF" />

          <ToolbarWrapper>
            {/* <BellNotifyIcon /> */}
            <AppBarDropdown
              width="22rem"
              dropdownText={
                user
                  ? `Olá ${user.firstName} ${user.lastName}`
                  : "Olá {{userName}}"
              }
            >
              <DropdownItem
                button
                onClick={() => {
                  signOut();
                }}
              >
                <HiOutlineLogout />
                <ListItemText primary="Sair" style={{ flex: "none" }} />
              </DropdownItem>
            </AppBarDropdown>
          </ToolbarWrapper>
        </Toolbar>
      </MUIAppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.appBarSpacer} />
        <BorderDiv open={open} />
        <List onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
          <ListItems />
        </List>
      </Drawer>
    </>
  );
};

export default AppBar;
