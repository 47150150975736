import { useState, useCallback, memo } from "react";
import { Box } from "@material-ui/core";

import Modal from "../../../../../../components/Modal";
import Input from "../../../../../../components/Input";
import Button from "../../../../../../components/Button";
import { date, money } from "../../../../../../utils/masks";
import { usePriceManagement } from "../../../../hooks";

import Radio from "./Radio";
import EditionsTable from "./EditionsTable";
import { validateDates } from "./actions";
import { Inputs, DateInputs, RadioContainer } from "./styles";

const EditionsModalDiscount: React.FC<any> = ({
  open,
  setOpen,
  tableData,
  setTableData,
  selectedRowsPk,
}) => {
  const { tabRequest } = usePriceManagement();

  const [endDate, setEndDate] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [inputType, setInputType] = useState<string>("Desconto (%)");
  const [inputValue, setInputValue] = useState<string>("");

  const handleRadio = useCallback((e) => {
    setInputType(e.target.name);
    setInputValue("");
  }, []);

  const validate = useCallback(() => {
    return validateDates(startDate, endDate, tabRequest);
  }, [startDate, endDate, tabRequest]);

  const alterData = useCallback(() => {
    const isValid = validate();

    if (!isValid.valid) {
      return alert(isValid.error);
    }

    const newPrice = inputValue.replace(",", ".");

    setTableData((current: any) => {
      return current.map((row: any) => {
        const isChecked = selectedRowsPk.find((checked: any) => {
          return row.id === checked.id;
        });

        if (isChecked) {
          return {
            ...row,
            dt_fim: endDate || row.dt_fim,
            dt_inicio: startDate || row.dt_inicio,
            newPrice: newPrice
              ? inputType === "Desconto (%)"
                ? newPrice
                : -newPrice
              : row.newPrice,
          };
        }

        return row;
      });
    });

    setOpen();
  }, [
    setTableData,
    selectedRowsPk,
    startDate,
    validate,
    endDate,
    setOpen,
    inputType,
    inputValue,
  ]);

  if (selectedRowsPk.length === 0) return <div style={{ height: 25 }} />;
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      children={
        <Box>
          <Inputs>
            <Box>
              <RadioContainer>
                <Radio
                  name="Desconto (%)"
                  inputType={inputType}
                  handleRadio={handleRadio}
                />
                {/* <Radio
                  name="Desconto (-%)"
                  inputType={inputType}
                  handleRadio={handleRadio}
                /> */}
              </RadioContainer>
              <Input
                required
                name="inputValue"
                value={inputValue}
                placeholder={inputType}
                maxLength={5}
                onChange={(e) => setInputValue(money(e.target.value))}
              />
            </Box>
            <DateInputs>
              <Input
                label="Data Início"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(date(e.target.value))}
                containerStyle={{ marginRight: "2rem" }}
              />
              <Input
                label="Data Fim"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(date(e.target.value))}
              />
              <Button onClick={alterData}>Aplicar alterações</Button>
            </DateInputs>
          </Inputs>
          <EditionsTable checkedOnlyRows={selectedRowsPk} />
        </Box>
      }
    />
  );
};

export default memo(EditionsModalDiscount);
