import React, { useEffect, useState } from "react";

import { IoDocumentText } from "react-icons/io5";
import { Redirect, useHistory } from "react-router";

import LoadingScreen from "../../components/LoadingScreen";

import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";
import { useAuth } from "../../contexts/AuthContext";
import { usePermission } from "../../contexts/PermissionContext";
// import { useToast } from "../../contexts/ToastContext";

import { Container } from "./styles";

const Reports: React.FC = () => {
  // const [loading, setLoading] = useState<boolean>(false);
  // const [isAllowed, setIsAllowed] = useState<boolean>(false);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  const { isAllowedTo } = usePermission();
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    const userIsAllowed = isAllowedTo(user.profile_type ?? "");

    if (!userIsAllowed) {
      history.replace("/");
    }
  }, [history, user.profile_type, isAllowedTo]);

  return (
    <Container>
      <PageTitle icon={<IoDocumentText />} title="Relatórios" />

      <Paper containerStyle={{ height: "100%", minHeight: "70vh" }}></Paper>
    </Container>
  );
};

export default Reports;
