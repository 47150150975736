export const columns = [
  {
    Header: "Categoria",
    accessor: "",
  },
  {
    Header: "SKU Capitão",
    accessor: "",
  },
  {
    Header: "Descrição",
    accessor: "",
  },
  {
    Header: "Código SKU",
    accessor: "",
  },
  {
    Header: "Descrição",
    accessor: "",
  },
  {
    Header: "Relatividade",
    accessor: "",
  },
];
