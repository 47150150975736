import React, { useEffect } from "react";

import BackgroundImg from "../../assets/BRF_background.png";
import ProfitLogoImg from "../../assets/profit-logo.png";
import BRFLogoImg from "../../assets/brf-logo.png";

import {
  Container,
  Section,
  Logos,
  LogoImg,
  Title,
  BackgroundBRF,
  Text,
} from "./styles";
import { useAuth } from "../../contexts/AuthContext";

const UnauthorizedUser: React.FC = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <BackgroundBRF src={BackgroundImg} alt="BRF-Background" />
      <Section>
        <Logos>
          <LogoImg src={BRFLogoImg} alt="BRF" />
          <LogoImg src={ProfitLogoImg} alt="Profit" />
        </Logos>

        <Title>Acesso Negado</Title>

        <Text>Solicite sua permissão de acesso ao Controle de Acesso</Text>
      </Section>
    </Container>
  );
};

export default UnauthorizedUser;
