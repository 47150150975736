import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleLine } from "react-icons/ri";

export const Container = styled(Modal)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(73, 129, 194, 0.36);
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 95vw;
  max-height: 85vh;
  overflow: hidden !important;
`;

export const CloseIcon = styled(RiCloseCircleLine)`
  width: 3.5rem;
  height: 3.5rem;
  color: #003b74;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  align-self: flex-end;
  z-index: 30;
`;
