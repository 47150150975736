import styled, { css } from "styled-components";

interface SceneryProps {
  selected: boolean;
  disabled: boolean;
}

export const Scenery = styled.div<SceneryProps>`
  cursor: pointer;

  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
  border-radius: 4px;

  color: ${({ selected }) => (selected ? "#05137C" : "#808080")};
  border: ${({ selected }) => `1px solid ${selected ? "#05137C" : "#808080"}`};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
