import { useCallback, useRef } from "react";

import api from "../../services/api";
import Button from "../Button";
import AlertModal from "../AlertModal";
import LoadingModal from "../LoadingModal";

interface IProps {
  url: string;
}

const ButtonExportExcelViaUrl: React.FC<IProps> = ({ url }) => {
  const linkRef = useRef<any>(null);
  const errorRef = useRef<any>(null);
  const successRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);

  const openError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openSuccess = useCallback(() => {
    successRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const onClick = useCallback(() => {
    openLoading(
      "Gerando Excel...",
      api
        .get(url)
        .then((res) => {
          let url = res.data.url;

          if (!url.match(/^https?:\/\//i)) {
            url = "https://" + url;
          }

          linkRef!.current!.href = url;
          linkRef!.current!.click();

          openSuccess();
        })
        .catch(() => {
          openError();
        })
    );
  }, [openError, openLoading, openSuccess, url]);

  return (
    <>
      <Button width="12rem" onClick={onClick}>
        Exportar Excel
      </Button>
      <a ref={linkRef} style={{ display: "none" }} download />
      <LoadingModal ref={loadingRef} />
      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro ao exportar, tente novamente."
      />
      <AlertModal ref={successRef} message="Exportado com sucesso!" />
    </>
  );
};

export default ButtonExportExcelViaUrl;
