import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { Box, CircularProgress, Grid } from "@material-ui/core";

import api from "../../services/api";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import useToggle from "../../hooks/useToggle";

import Input from "./Input";

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  url: string;
  columns: { Header: any; accessor: any }[];
  currentFilters: any;
  setCurrentFilters: any;
}

const Filters: React.FC<IProps> = ({
  url,
  columns,
  currentFilters,
  setCurrentFilters,
}) => {
  const [isOpen, toggleOpen] = useToggle();
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<{ [key: string]: IOption[] }>({});
  const [options, setOptions] = useState<{ [key: string]: IOption[] }>({});

  const inputs = useMemo(() => {
    return columns
      .filter((col) => col.Header && typeof col.Header === "string")
      .map((col) => {
        if (typeof col.accessor === "string") return col;
        return {
          Header: col.Header,
          accessor: col.accessor({}).props.param,
        };
      });
  }, [columns]);

  useEffect(() => {
    if (!isOpen) {
      setLoading(true);
      setFilters({});
      setOptions({});
    }
  }, [isOpen]);

  const getFilters = useCallback(() => {
    if (!isOpen) return;

    api
      .post(url)
      .then((res) => {
        const newOptions: { [key: string]: IOption[] } = {};

        Object.keys(res.data.filters).forEach((key) => {
          newOptions[key] = res.data.filters[key]
            .filter((item: string) => item)
            .map((item: string | number) => {
              return { label: (item + "").trim(), value: (item + "").trim() };
            });
        });

        const newFilters: { [key: string]: IOption[] } = {};

        Object.keys(currentFilters).forEach((key) => {
          if (currentFilters[key]?.length > 0) {
            newFilters[key] = currentFilters[key].map((item: string) => ({
              label: item,
              value: item,
            }));
          }
        });

        setFilters(newFilters);
        setOptions(newOptions);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [currentFilters, isOpen, url]);
  useEffect(getFilters, [getFilters]);

  const onFilter = useCallback(() => {
    const newFilters: { [key: string]: string[] } = {};

    Object.keys(filters).forEach((key) => {
      if (filters[key]?.length > 0) {
        newFilters[key] = filters[key].map((item) => item.value);
      }
    });

    setCurrentFilters(newFilters);
    toggleOpen();
  }, [filters, setCurrentFilters, toggleOpen]);

  const onClear = useCallback(() => {
    setCurrentFilters({});
    toggleOpen();
  }, [setCurrentFilters, toggleOpen]);

  return (
    <>
      <Button width="7rem" onClick={toggleOpen}>
        Filtros
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "85vw", maxWidth: "100rem" }}
        children={
          <Box textAlign="center" pt={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container spacing={2}>
                  {inputs.map((input) => (
                    <Input
                      key={uuid()}
                      input={input}
                      filters={filters}
                      options={options}
                      setFilters={setFilters}
                    />
                  ))}
                </Grid>
                <Box
                  mt={3}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={onClear}
                    containerStyle={{ marginRight: "1.5rem" }}
                  >
                    Limpar Filtros
                  </Button>
                  <Button onClick={onFilter}>Filtrar</Button>
                </Box>
              </>
            )}
          </Box>
        }
      />
    </>
  );
};

export default Filters;
