import { memo } from "react";

import HeaderContainer from "../../../../components/HeaderContainer";

import Buttons from "./Buttons";
import PageTitle from "./PageTitle";

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <PageTitle />
      <Buttons />
    </HeaderContainer>
  );
};

export default memo(Header);
