import moment from "moment";

import api from "../../../services/api";

const requester = (user: any) => {
  return `${user.firstName} ${user.lastName}`;
};

const date_request = (date: Date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

export const getTableData = (callback: (args1: any) => void, options: any) => {
  const { page, limit } = options;

  api
    .get(`/price/list/service-orders/nielsen?page=${page}&limit=${limit}`)
    .then((response: any) => {
      const formattedData = response.data.data.service_orders.map((so: any) => {
        return {
          ...so,
          requester: requester(so.users.creation.user),
          date_request: date_request(so.users.creation.date),
        };
      });

      callback({
        error: false,
        total: response.data.data.total,
        totalPages: response.data.data.totalPages,
        tableData: formattedData,
      });
    })
    .catch((error) => {
      console.log("error", error);
      return {
        error: true,
        message: error,
      };
    });
};
