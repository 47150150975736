import Input from "./Input";

import { IProps } from "./types";

const InputSaleDocumentType: React.FC<IProps> = ({
  value,
  setValue,
  shouldRender,
}) => {
  if (!shouldRender("documento_de_venda")) return null;
  return <Input value={value} setValue={setValue} />;
};

export default InputSaleDocumentType;
