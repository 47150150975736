import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  gap: 2rem;
  margin-bottom: 2rem;

  display: flex;
  justify-content: flex-end;

  button {
    max-width: 12rem;
  }
`;
