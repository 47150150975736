import { useMemo } from "react";

import { Container } from "./styles";
import {
  InputsIpTarget,
  InputsMateriais,
  InputsSkuXCapitao,
  InputsInvestimento,
  InputsMargemMinMax,
  InputsRelatividadeDeCanais,
} from "./components";

interface IProps {
  table: string;
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const Header: React.FC<IProps> = ({ table, setInputs }) => {
  const component = useMemo(() => {
    return {
      "Relatividade de Produtos": null,
      "Relatividade de Canais": (
        <InputsRelatividadeDeCanais setInputs={setInputs} />
      ),
      "SKU X Capitão": <InputsSkuXCapitao setInputs={setInputs} />,
      Investimento: <InputsInvestimento setInputs={setInputs} />,
      "IP Target": <InputsIpTarget setInputs={setInputs} />,
      "Margem Min & Max": <InputsMargemMinMax setInputs={setInputs} />,
      "Range de Preço": null,
      Materiais: <InputsMateriais setInputs={setInputs} />,
      "Relação Nielsen": null,
    }[table];
  }, [table, setInputs]);

  return <Container>{component}</Container>;
};

export default Header;
