import { useEffect, useState, useCallback, memo } from "react";
import moment from "moment";

import Input from "../../../../../components/Input";
import { date } from "../../../../../utils/masks";
import { getDateLimits } from "../../actions";
import { usePriceManagement } from "../../../hooks";

const CellInputDateFim: React.FC<any> = ({ row, column, updateTableData }) => {
  const { tabRequest } = usePriceManagement();

  const [value, setValue] = useState<any>("");

  useEffect(() => {
    if (row.original.dt_fim) {
      return setValue(row.original.dt_fim);
    }
    return setValue("");
  }, [row.original.dt_fim]);

  const placeholder = useCallback(() => {
    const [, endDateLimit] = getDateLimits(tabRequest, row.original.dt_inicio);
    return endDateLimit;
  }, [tabRequest, row.original.dt_inicio]);

  const onChange = useCallback((e) => {
    setValue(date(e.target.value));
  }, []);

  const onBlur = useCallback(() => {
    if (value === row.original.dt_fim) return;

    if (!moment(value, "DD/MM/YYYY", true).isValid()) {
      updateTableData(row.index, column.id, "");
      return setValue("");
    }

    updateTableData(row.index, column.id, value);
  }, [updateTableData, row, column.id, value]);

  if (tabRequest.requestId) {
    return <span>{row.original.dt_fim || "-"}</span>;
  }

  if (row.original.blockEditing) {
    return <span>-</span>;
  }

  return (
    <Input
      name="endDate"
      placeholder={placeholder()}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      containerStyle={{
        width: "76px",
        height: "20px",
      }}
      inputStyle={{
        width: "100%",
        color: "#003b74",
        fontSize: "1rem !important",
        padding: 8,
      }}
    />
  );
};

export default memo(CellInputDateFim);
