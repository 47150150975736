import styled from "styled-components";

export const Upload = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 0 2rem;

  color: white;
  font-size: 1.3rem;
  background: #003b74;
  border-radius: 8px;

  transition: background 0.3s color 0.3s;

  &:hover {
    filter: brightness(110%);
  }

  svg {
    font-size: 24px;
    margin-right: 1rem;
  }
`;
