import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import Input from "../../../../../../../../components/Input";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputMin: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [min, setMin] = useState(row.min_margin);

  const onChange = useCallback((e) => {
    setMin(e.target.value);
  }, []);

  const onBlur = useCallback(
    (e) => {
      let newVal = e.target.value || "0";
      setMin(newVal);

      const url = "/param/update/margin";
      const req = {
        region: row.region,
        channel: row.channel,
        category: row.category,
        activity: row.activity,
        min_margin: newVal,
      };

      api.post(url, req).catch(() => {
        setMin(row.deflator);
      });
    },
    [row]
  );

  if (isUserSupport) return min;
  return (
    <Input
      name="min_margin"
      value={min}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default InputMin;
