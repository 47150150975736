import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 2rem;
  margin-bottom: 2rem;

  p {
    margin-right: auto;
    color: #003b74;
    font-size: 18px;
    font-weight: bold;
  }
`;
