import { memo } from "react";

import Paper from "../../../../../../components/Paper";
import { scenValue } from "../../../../../../utils/scenValue";

import { Title, Value, Info } from "./styles";

const CardTechnic: React.FC<any> = ({ data }) => {
  return (
    <Paper containerStyle={{ color: "#003b74" }}>
      <Title>Preço médio técnico</Title>
      {data && (
        <>
          <Value>{scenValue(data.preco_tecnico)}</Value>
          <Info>
            <div>
              <span>Volume Técnico</span>
              <span>{scenValue(data.volume_m1)}</span>
            </div>
            <div>
              <span>ROB Técnico</span>
              <span>{scenValue(data.rob_m1)}</span>
            </div>
            <div>
              <span>ROB PxP (Técnico) %</span>
              <span>{scenValue(data.pxp_tecnico)}%</span>
            </div>
          </Info>
        </>
      )}
    </Paper>
  );
};

export default memo(CardTechnic);
