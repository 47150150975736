import { SelectFilter } from "../../components";
import shouldRenderSection, {
  truthTable,
  truthTableYTags,
} from "../../../../../utils/shouldRenderSection";

type TInfoColumn = {
  truthTableX: string;
  Header: string;
  accessor: string;
  Filter: ({ column }: any) => JSX.Element;
  filter: string;
};

type TInfoColumns = TInfoColumn[];

type TGetIInfoColumns = (proposal: string) => any[];

// COLUNAS OPCIONAIS
// FALTA DADOS canal_de_distribuicao: 7,
const infoColumns: TInfoColumns = [
  {
    truthTableX: "material",
    Header: "Cód SKU",
    accessor: "pk",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "hierarquia",
    Header: "Hierarquia",
    accessor: "pk",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "regiao",
    Header: "Região",
    accessor: "branch",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "filial",
    Header: "Filial",
    accessor: "branch",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "canal_pricing",
    Header: "Canal Pricing",
    accessor: "canal_pricing",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "canal_de_distribuicao",
    Header: "Canal De Distribuição",
    accessor: "canal_dist",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "material",
    Header: "Descrição Produto",
    accessor: "description",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "categoria",
    Header: "Categoria",
    accessor: "categoria",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "categoria_de_items",
    Header: "Categoria de Itens",
    accessor: "categoryItems",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "empresa",
    Header: "Empresa",
    accessor: "empresa",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "raiz_cnpj",
    Header: "Raíz CNPJ",
    accessor: "raiz_cnpj",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "grupo_de_preco",
    Header: "Grupo de Preço",
    accessor: "grupo_preco",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "condicao_de_expedicao",
    Header: "Condição de Expedição",
    accessor: "condicao_de_expedicao",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "grupo_de_cliente",
    Header: "Grupo de Cliente",
    accessor: "grupo_de_cliente",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "cliente",
    Header: "Cliente",
    accessor: "cliente",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "lista_de_precos",
    Header: "Lista de Preços",
    accessor: "lista_de_precos",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    truthTableX: "documento_de_venda",
    Header: "Tipo de Documento de Venda",
    accessor: "documento_de_venda",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
];

const globalColumns = [
  {
    Header: "Data Início",
    accessor: "data_ini",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    Header: "Data Início Next",
    accessor: "next_data_ini",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    Header: "Data Fim",
    accessor: "data_fim",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
  {
    Header: "Data Fim Next",
    accessor: "next_data_fim",
    Filter: ({ column }: any) => <SelectFilter column={column} />,
    filter: "exactText",
  },
];

export const getInfoColumns: TGetIInfoColumns = (proposal) => {
  const filteredOptionalColumns = infoColumns.filter((col: TInfoColumn) => {
    return shouldRenderSection(proposal, col.truthTableX);
  });

  if (/N8/i.test(proposal)) {
    const documentsArray = [
      filteredOptionalColumns[0],
      filteredOptionalColumns[filteredOptionalColumns.length - 1],
    ];

    return [...documentsArray, ...globalColumns];
  }

  return [...filteredOptionalColumns, ...globalColumns];
};
