import { useCallback, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";

import api from "../../../../../../services/api";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";

const ButtonResult: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { sceneryId, type } = useParams<any>();

  const [isOpen, toggleOpen] = useToggle();

  const typeValue = useMemo(() => {
    const values: any = { A: 1, B: 2, C: 3 };
    return values[type];
  }, [type]);

  const onSend = useCallback(() => {
    const request = {
      scenery_id: +sceneryId,
      scenery_name: typeValue,
    };

    api.post("/price/scenery/clear", request).then(() => {
      history.push({
        pathname: `/price-base/result/${sceneryId}`,
        state,
      });
    });
  }, [sceneryId, typeValue, history, state]);

  return (
    <>
      <Button
        width="14rem"
        onClick={() => {
          if (state?.sceneryViewOnly) {
            onSend();
          } else {
            toggleOpen();
          }
        }}
      >
        Visualizar Resultado
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "420px" }}
        children={
          <Box textAlign="center" pt={2}>
            <h1>
              Voce escolheu o cenário{" "}
              <strong style={{ color: "#003b74" }}>"{type}"</strong>. Ao
              selecionar este cenário os demais serão deletados. Deseja
              prosseguir?
            </h1>
            <Box
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={toggleOpen}
                containerStyle={{ marginRight: "4rem" }}
              >
                Não
              </Button>
              <Button onClick={onSend}>Sim</Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default ButtonResult;
