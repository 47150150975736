import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../../services/api";
import { Multiselect } from "../../../../../../../components/AutoComplete";

import { Container } from "./styles";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsRelatividadeCanal: React.FC<IProps> = ({ setInputs }) => {
  const [sku, setSku] = useState([]);
  const [region, setRegion] = useState([]);
  const [channel, setChannel] = useState([]);
  const [category, setCategory] = useState([]);

  const [skuOpt, setSkuOpt] = useState([]);
  const [regionOpt, setRegionOpt] = useState([]);
  const [channelOpt, setChannelOpt] = useState([]);
  const [categoryOpt, setCategoryOpt] = useState([]);

  useEffect(() => {
    setInputs((current) => ({ ...current, sku }));
  }, [setInputs, sku]);

  const getSku = useCallback(() => {
    const request = { category: category.map((item: any) => item.value) };
    api.post("/material/list/natura", request).then((response) => {
      setSkuOpt(
        response.data.material.map(
          (item: any, index: number) =>
            +item.sku && {
              key: index,
              label: { label: +item.sku, value: +item.sku },
            }
        )
      );
    });
  }, [category]);
  useEffect(getSku, [getSku]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      sku: sku.map((item: any) => item.value),
    }));
  }, [setInputs, sku]);

  const getRegion = useCallback(() => {
    api.get("/price/list/regions").then((response) => {
      setRegionOpt(
        response.data.regions.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.id },
        }))
      );
    });
  }, []);
  useEffect(getRegion, [getRegion]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      region: region.map((item: any) => item.value),
    }));
  }, [setInputs, region]);

  const getChannel = useCallback(() => {
    api.get("/price/list/channels").then((response) => {
      setChannelOpt(
        response.data.channels.map((item: any, index: number) => ({
          key: index,
          label: { label: item.channel, value: item.channel },
        }))
      );
    });
  }, []);
  useEffect(getChannel, [getChannel]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      channel: channel.map((item: any) => item.value),
    }));
  }, [setInputs, channel]);

  const getCategory = useCallback(() => {
    api.get("/material/natura/category").then((response) => {
      setCategoryOpt(
        response.data.material.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.name },
        }))
      );
    });
  }, []);
  useEffect(getCategory, [getCategory]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      sku: [],
      category: category.map((item: any) => item.value),
    }));

    setSku([]);
  }, [setInputs, category]);

  return (
    <>
      <Container>
        <Multiselect
          label="Região"
          value={region}
          options={regionOpt}
          setValue={setRegion}
        />
        <Multiselect
          label="Canal"
          value={channel}
          options={channelOpt}
          setValue={setChannel}
        />
      </Container>
      <Container>
        <Multiselect
          label="Sku"
          value={sku}
          options={skuOpt}
          setValue={setSku}
        />
        <Multiselect
          label="Categoria"
          value={category}
          options={categoryOpt}
          setValue={setCategory}
        />
      </Container>
    </>
  );
};

export default InputsRelatividadeCanal;
