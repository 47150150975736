import moment from "moment";

import api from "../../../services/api";

const requester = (user: any) => {
  return `${user.firstName} ${user.lastName}`;
};

const base_origin = (so: any) => {
  return so.condition && so.level
    ? `${so.condition}-${so.level.slice(so.level.length - 2, so.level.length)}`
    : "";
};

const filial = (name: string) => {
  return name?.replace(/,/g, "; ");
};

const date_request = (date: Date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

// const materials = (materials: any) => {
//   if (materials[0].hierarquia) return "-";
//   return materials.map((material: any) => material.material).join("; ");
// };

// const hierarquia = (materials: any) => {
//   if (materials[0].material) return "-";
//   return materials.map((material: any) => material.hierarquia).join("; ");
// };

export const getTableData = (callback: (args1: any) => void) => {
  api
    .get("price/list/service-orders/approval")
    .then((response: any) => {
      const formattedData = response.data.serviceOrders.map((so: any) => {
        return {
          id: so.id,
          requester: requester(so.users.creation.user),
          base_origin: base_origin(so),
          filial: filial(so.filial.name),
          date_request: date_request(so.users.creation.date),
          adicionais: so.adicionais,
          status: so.order_status,
          edit: true,
          // materials: materials(so.materials),
          // hierarquia: hierarquia(so.materials),
          observation: so.reason || "-",
          base: so,
        };
      });

      callback({ error: false, tableData: formattedData });
    })
    .catch((error) => {
      return {
        error: true,
        message: error,
      };
    });
};
