import styled from "styled-components";

const Line = styled.div`
  width: 100%;

  margin: 1rem 0;

  border-bottom: 1px solid #ddd7e6;
`;

const Divider: React.FC = () => {
  return <Line />;
};

export default Divider;
