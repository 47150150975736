import { v4 as uuid } from "uuid";
import { TableBody } from "@material-ui/core";

import Row from "./Row";
import IColumn from "../types/IColumn";

interface ITBodyProps {
  rows: { [key: string]: any }[];
  cols: IColumn[];
  cellProps: { [key: string]: any };
  stickyPos: number;
  subRowComponent?: React.FC<any>;
}

const Table: React.FC<ITBodyProps> = ({
  rows,
  cols,
  cellProps,
  stickyPos,
  subRowComponent,
}) => {
  return (
    <TableBody>
      {rows.map((row: any, rowIndex) => (
        <Row
          key={uuid()}
          row={row}
          cols={cols}
          rowIndex={rowIndex}
          cellProps={cellProps}
          stickyPos={stickyPos}
          subRowComponent={subRowComponent}
        />
      ))}
    </TableBody>
  );
};

export default Table;
