import { memo } from "react";

import Paper from "../../../../../../components/Paper";
import { scenValue } from "../../../../../../utils/scenValue";

import { Title, Value, Info } from "./styles";

const CardBase: React.FC<any> = ({ data }) => {
  return (
    <Paper containerStyle={{ color: "#003b74" }}>
      <Title>Preço médio base</Title>
      {data && (
        <>
          <Value>{scenValue(data.preco_base)}</Value>
          <Info>
            <div>
              <span>Volume período atual</span>
              <span>{scenValue(data.volume_medio)}</span>
            </div>
            <div>
              <span>ROB atual</span>
              <span>{scenValue(data.rob_medio)}</span>
            </div>
          </Info>
        </>
      )}
    </Paper>
  );
};

export default memo(CardBase);
