import { useContext } from "react";

import { PriceManagementContext } from "./PriceManagementContext";

export const usePriceManagement = () => {
  const context = useContext(PriceManagementContext);

  if (!context) {
    throw new Error(
      "usePriceManagement must be used within a PriceManagementContext"
    );
  }

  return context;
};
