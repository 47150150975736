import { createPortal } from "react-dom";
import {
  useMemo,
  forwardRef,
  useCallback,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";

import Button from "../Button";
import useToggle from "../../hooks/useToggle";

import { svgs, portal } from "./constants";
import { Props, ImperativeHandle } from "./interfaces";
import { BG, Container, SvgContainer, Message } from "./styles";

const AlertModal: ForwardRefRenderFunction<ImperativeHandle, Props> = (
  {
    type = "success",
    message = "Ação realizada com sucesso!",
    onContinue = null,
  },
  ref
) => {
  const [open, toggle] = useToggle();
  useImperativeHandle(ref, () => ({ toggle }));

  const Svg = useMemo(() => svgs[type], [type]);

  const onClick = useCallback(() => {
    return onContinue?.() || toggle();
  }, [onContinue, toggle]);

  const buttonStyle = useMemo(() => {
    const color = {
      success: "rgba(76, 175, 80, 1)",
      error: "rgba(244,67,54,1)",
    }[type];

    return {
      backgroundColor: color,
      border: `1px solid ${color}`,
    };
  }, [type]);

  if (!open) return null;
  return createPortal(
    <BG>
      <Container>
        <SvgContainer type={type}>
          <Svg />
        </SvgContainer>
        <Message>{message}</Message>
        <Button onClick={onClick} containerStyle={buttonStyle}>
          Continuar
        </Button>
      </Container>
    </BG>,
    portal
  );
};

export default forwardRef(AlertModal);
