export const columns = [
  {
    Header: "Capitão",
    accessor: "",
  },
  {
    Header: "Descrição",
    accessor: "",
  },

  {
    Header: " ",
    accessor: "",
  },
];
