import React, { useCallback, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

import {
  Container,
  DropdownBtn,
  DropdownContent,
  IconContainer,
} from "./styles";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

interface AppBarDropdownProps {
  width?: string;
  dropdownText: string;
}

const AppBarDropdown: React.FC<AppBarDropdownProps> = ({
  children,
  width,
  dropdownText,
}) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { signOut } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
    history.push("/login");
  }, [history, signOut]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container onClick={() => setOpen(!open)}>
        <DropdownBtn width={width} open={open}>
          {dropdownText}
          <IconContainer>
            {open ? (
              <RiArrowUpSFill size={18} />
            ) : (
              <RiArrowDownSFill size={18} />
            )}
          </IconContainer>
        </DropdownBtn>
        <DropdownContent open={open} onClick={handleSignOut}>
          {children}
        </DropdownContent>
      </Container>
    </ClickAwayListener>
  );
};

export default AppBarDropdown;
