import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  left: 0;

  width: 100%;
  margin-top: 1rem;

  display: flex;
  align-items: center;
`;

export const Total = styled.span`
  margin-right: auto;
  font-size: 1.4rem;
  color: black;
`;

export const NeighborPage = styled.span`
  cursor: pointer;

  min-width: 2.7rem;
  height: 2.7rem;
  margin: 0 0.25rem;
  padding: 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: black;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 8px;

  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: #f2f3f5;
  }
`;

export const CurrentPage = styled.span`
  min-width: 2.7rem;
  height: 2.7rem;
  margin: 0 0.25rem;
  padding: 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 1.4rem;
  text-align: center;
  background-color: #003b74;
  border-radius: 8px;
`;

export const Ellipsis = styled.span`
  width: 2.7rem;
  height: 2.7rem;
  margin: 0 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: black;
  font-size: 1.4rem;
  text-align: center;
`;
