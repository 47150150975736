import { useEffect, useState } from "react";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import api from "../../../../../../services/api";
import Paper from "../../../../../../components/Paper";
import LoadingScreen from "../../../../../../components/LoadingScreen";

import { Table } from "./styles";
import { columns } from "./constants";
import { Nav, Pagination, InputDeflator } from "./components";

const limit = 10;

const TableMateriais: React.FC<any> = ({ inputs }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [prevPage, setPrevPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);

  useEffect(() => {
    api
      .post("/material/list/all", { ...inputs, limit, page, type: "natura" })
      .then((res) => {
        setRows(res.data.dados.table);
        setTotal(res.data.dados.total);
        setPrevPage(res.data.dados.previousPage);
        setNextPage(res.data.dados.nextPage);
        setTotalPage(res.data.dados.totalPages);
        setLoading(false);
      });
  }, [inputs, page]);

  if (loading) return <LoadingScreen />;
  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <Nav filters={{ ...inputs, type: "natura" }} />
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow key={`${row.sku}-${row.nivel01}-${row.nivel02}`}>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.sigla}</TableCell>
              <TableCell>{row.descricao}</TableCell>
              <TableCell>{row.nivel01}</TableCell>
              <TableCell>{row.nivel02}</TableCell>
              <TableCell>{row.nivel03}</TableCell>
              <TableCell>{row.nivel04}</TableCell>
              <TableCell>{row.nivel05}</TableCell>
              <TableCell>
                <InputDeflator row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          paginationOptions={{
            total,
            nextPage,
            prevPage,
            totalPages,
            setPage,
          }}
        />
      )}
    </Paper>
  );
};

export default TableMateriais;

