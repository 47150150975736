import {
  ActionMeta,
  GroupBase,
  MultiValue,
  NoticeProps,
  components as customComponents,
} from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import api from "../../services/api";

export type Options = {
  label: string;
  value: string;
};

interface ServiceProps {
  url: string;
  method: 'POST' | 'GET';
  data: any;
  filters?: string[]
}

interface AsyncSelect {
  revalidate?: string;
  cacheUniqs?: readonly any[];
  label?: string;
  helperText?: string;
  name: string;
  isRequired?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  error?: string;
  placeholder?: string;
  isMulti?: true;
  onChange?: (
    newValue: MultiValue<Options>,
    actionMeta: ActionMeta<Options>
  ) => void | undefined;
  value?: Options | Options[];
  debounceTimeout?: number | undefined;
  mapOptions?: (item: any) => {
    label: string | number;
    value: string;
  };
  serviceProps?: ServiceProps;
}

export function AsyncSelectPaginated({
  revalidate = "",
  isLoading,
  onChange,
  cacheUniqs,
  error,
  isRequired,
  name,
  mapOptions,
  helperText,
  isReadOnly,
  label,
  placeholder = "Selecione a opção",
  isMulti,
  debounceTimeout = 500,
  value,
  serviceProps,
}: AsyncSelect) {
  function handleOnChange(
    newValue: MultiValue<Options>,
    actionValue: ActionMeta<Options>
  ) {
    if (typeof onChange === "function") {
      onChange(newValue, actionValue);
    }
  }
  const limit = 10


  const loadOptions: LoadOptions<
    Options,
    GroupBase<Options>,
    { page: number }
  > = async (search, _loadedOptions, additional) => {
    if (serviceProps) {
      const { method, url, data, filters } = serviceProps;

      if (data) {
        const response = await api({
          url, method, data: {
            ...data,
            search,
            filters,
            limit,
            offset: (additional && additional?.page * 10) || 0
          }
        })

        const mappedOptions = response.data.material.map(mapOptions);

        return {
          options: mappedOptions,
          hasMore: response.data.material.length > 0,
          additional: {
            page: (additional && additional?.page + 1) || 1,
          },
        };
      }
    }

    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0,
      },
    };
  };

  const NoOptionsMessage = (
    props: NoticeProps<
      {
        label: string;
        value: string;
      },
      true,
      GroupBase<{
        label: string;
        value: string;
      }>
    >
  ) => (
    <customComponents.NoOptionsMessage {...props}>
      Sem opções
    </customComponents.NoOptionsMessage>
  );

  return (
    <div style={{ display: "flex", gap: '0.5rem', flexDirection: 'column' }}>
      {label && <label htmlFor={name} style={{ fontSize: '1.25rem', color: "#003b74" }}>{label}</label>}
      <AsyncPaginate
        id={name}
        key={revalidate}
        isLoading={isLoading}
        cacheUniqs={cacheUniqs}
        value={value}
        name={name}
        backspaceRemovesValue={false}
        controlShouldRenderValue
        loadOptions={loadOptions}
        onChange={handleOnChange}
        loadOptionsOnMenuOpen
        components={{ NoOptionsMessage }}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        isMulti={isMulti}
        isSearchable
        required={isRequired}
        isDisabled={isReadOnly}
        isClearable={!isReadOnly}
        placeholder={placeholder}
        maxMenuHeight={200}
        menuShouldScrollIntoView
        additional={{
          page: 0,
        }}
        menuIsOpen={isReadOnly ? false : undefined}
        debounceTimeout={debounceTimeout}
        loadingMessage={() => <span style={{ fontSize: '1.125rem' }}>Carregando...</span>}
        styles={{
          control: (base) => ({ ...base, fontSize: '1.3rem' }),
          option: (base, props) => ({
            ...base,
            fontSize: '1.12rem',
            color: "#003b74"
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: "#003b74",
          }),
          noOptionsMessage: (base, props) => ({
            ...base,
            fontSize: '1.25rem'
          }),
        }}
      />
      {helperText && <div>{helperText}</div>}
    </div>
  );
}
