import { createPortal } from "react-dom";
import {
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { portal } from "./constants";
import { BG, Container, Message } from "./styles";
import { Props, ImperativeHandle } from "./interfaces";

const LoadingModal: ForwardRefRenderFunction<ImperativeHandle, Props> = (
  _,
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const close = useCallback(() => {
    setLoading(false);
  }, []);

  const open = useCallback(
    (message = "Carregando...", promise: Promise<void>) => {
      setLoading(true);
      setMessage(message);

      Promise.resolve(promise).finally(close);
    },
    [close]
  );

  useImperativeHandle(ref, () => ({ open }));

  if (!loading) return null;
  return createPortal(
    <BG>
      <Container>
        <CircularProgress />
        <Message>{message}</Message>
      </Container>
    </BG>,
    portal
  );
};

export default forwardRef(LoadingModal);
