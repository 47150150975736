import { useState } from "react";
import { Body, Header } from "./components";

const TableCodigoCapitao: React.FC<any> = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [captain, setCaptain] = useState("");

  return (
    <>
      <Header setRows={setRows} setCaptain={setCaptain} />
      <Body rows={rows} setRows={setRows} captain={captain} />
    </>
  );
};

export default TableCodigoCapitao;
