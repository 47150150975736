import styled from "styled-components";
import { Table as MUITable } from "@material-ui/core";

export const Table = styled(MUITable)`
  thead tr {
    background-color: #f2f3f5;
  }

  tbody {
    tr,
    td {
      transition: background-color 0.3s;
    }
  }

  th,
  td {
    width: auto;
    max-width: 140px;
    margin: 0;
    padding: 0.5rem 1rem;

    color: #003b74;
    font-size: 1rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :first-child {
      padding-left: 0.75rem;
    }

    :last-child {
      max-width: 200px;
    }
  }

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    font-size: 1rem;
  }
`;
