import { memo, useCallback } from "react";
import { FaArrowLeft as GoBackSvg } from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router-dom";

import PageTitleComponent from "../../../../../components/PageTitle";

const PageTitle: React.FC<any> = () => {
  const history = useHistory();
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const goBack = useCallback(() => {
    history.push({
      pathname: `/processed/scenery/${id}`,
      state,
    });
  }, [history, id, state]);

  return (
    <PageTitleComponent
      icon={<GoBackSvg />}
      title="Base de Preços"
      linkLabel="Visualizar Detalhado"
      onClick={goBack}
      containerStyle={{ cursor: "pointer" }}
    />
  );
};

export default memo(PageTitle);
