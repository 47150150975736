import { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import api from "../../../../../../services/api";

interface IProps {
  row: any;
  getRows: () => void;
}

const Checkbox: React.FC<IProps> = ({ row, getRows }) => {
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const checked = useMemo(() => {
    return row.enabled;
  }, [row.enabled]);

  const onChange = useCallback(() => {
    const url = `/param/check/nielsen-ppv/by-id?id=${row.id}&scenery_id=${id}`;

    api.post(url).then(() => {
      getRows();
    });
  }, [getRows, id, row.id]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={state?.sceneryViewOnly}
      />
    </div>
  );
};

export default Checkbox;
