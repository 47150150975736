import { useCallback, memo, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../services/api";
import Paper from "../../../../components/Paper";
import TableBackend from "../../../../components/TableBackendV2";
import LoadingScreen from "../../../../components/LoadingScreen";
import ButtonEditTableLayout from "../../../../components/ButtonEditTableLayout";
import { useToast } from "../../../../contexts/ToastContext";

import { limit } from "./constants";
import { formatData } from "./actions";
import { EditAllButton } from "./components";

const Scenery: React.FC<any> = ({
  filters,
  columns,
  stickyPos,
  columnsOrder,
  setReady,
  toggleBigDataReset,
}) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { sceneryId, type } = useParams<any>();

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([]);
  const [allRowsChecked, setAllRowsChecked] = useState(false);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  const typeValue = useMemo(() => {
    const values: any = { A: 1, B: 2, C: 3 };
    return values[type];
  }, [type]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
  }, [filters, typeValue]);

  const getRows = useCallback(() => {
    const url = `/price/service-orders/scenery/list-items?page=${page}&limit=${limit}&scenery_id=${sceneryId}&type=${typeValue}`;

    api
      .post(url, filters)
      .then((res) => {
        setRows(formatData(res.data.scenery_items));
        setTotal(res.data.total);
        setTotalPage(res.data.totalPages);
        setReady(true);
        setLoading(false);
      })
      .catch((e) => {
        history.push("/price-base");
        console.log(e);
        addToast({
          type: "error",
          title: "Erro",
          description: e.response?.data?.message || "Erro, tente novamente.",
        });
      });
  }, [page, sceneryId, typeValue, filters, setReady, history, addToast]);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [getRows, loading]);

  if (loading) return <LoadingScreen />;
  return (
    <>
      <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem" }}>
        <EditAllButton
          setLoading={setLoading}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          setAllRowsChecked={setAllRowsChecked}
          toggleBigDataReset={toggleBigDataReset}
        />
        <ButtonEditTableLayout
          columns={columns}
          stickyPos={stickyPos}
          columnsOrder={columnsOrder}
          tableName="natura_scenery"
        />
      </div>
      <Paper
        containerStyle={{
          height: "100%",
          maxHeight: "54vh",
          position: "relative",
        }}
      >
        <TableBackend
          rows={rows}
          cols={columns}
          stickyPos={stickyPos + 1}
          page={page}
          limit={limit}
          total={total}
          totalPages={totalPages}
          enableHideCols
          setPage={(newPage) => {
            setLoading(true);
            setPage(newPage);
          }}
          cellProps={{
            getRows,
            checkedRows,
            setCheckedRows,
            allRowsChecked,
            setAllRowsChecked,
            toggleBigDataReset,
          }}
        />
      </Paper>
    </>
  );
};

export default memo(Scenery);
