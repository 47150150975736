import styled from "styled-components";

const getTextColor = (validationStr: string) => {
  if (validationStr === "valid") return "green";
  if (validationStr === "invalid") return "red";
  if (validationStr === "editing") return "#003b74";
  return "transparent";
};

interface ContainerProps {
  validationStr: string;
}
export const Container = styled.div<ContainerProps>`
  min-width: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 16px;
    margin-right: 1rem;
  }

  color: ${({ validationStr }) => getTextColor(validationStr)};
`;
