import { useState, useMemo, useCallback, useEffect, memo } from "react";

import Paper from "../../../components/Paper";
import Table from "../../../components/TableSmall";
import LoadingScreen from "../../../components/LoadingScreen";
import { usePriceManagement } from "../hooks/usePriceManagement";

import { Options } from "./components";
import {
  getColumns,
  handleSetTableData,
  renderRowSubComponent,
} from "./actions";
import {
  TColumns,
  TTableData,
  TSelectedRows,
  TCheckboxOnChange,
  TGetInitialTableData,
} from "./types";

const TableProposalVisualize: React.FC = () => {
  const { tabRequest, tabList } = usePriceManagement();

  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<TTableData>([]);
  const [selectedRows, setSelectedRows] = useState<TSelectedRows>([]);

  // #1 Generate react-table columns based on condition & level
  // Ex: `Y500-N1`
  const columns: TColumns = useMemo(() => getColumns(tabRequest), [tabRequest]);

  // #2 Formats previous page API response to fit react-table row format
  const getInitialTableData: TGetInitialTableData = useCallback(() => {
    handleSetTableData(tabList, tabRequest, (data) => {
      setTableData(data.tableData);
      setLoading(false);
    });
  }, [tabList, tabRequest]);

  useEffect(getInitialTableData, [getInitialTableData]);

  // #3 Handles checkbox selection
  const checkboxOnChange: TCheckboxOnChange = useCallback(
    (selectedFlatRows) => setSelectedRows(selectedFlatRows),
    []
  );

  if (loading) return <LoadingScreen />;
  return (
    <Paper>
      {/* <Options
        tableData={tableData}
        setTableData={setTableData}
        selectedRows={selectedRows}
      /> */}
      <Table
        data={tableData}
        columns={columns}
        setData={setTableData}
        checkbox={false}
        checkboxOnChange={checkboxOnChange}
        renderRowSubComponent={renderRowSubComponent}
      />
    </Paper>
  );
};

export default memo(TableProposalVisualize);
