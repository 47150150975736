import Status from "../../../components/Status";

export const getColumns = () => {
  return [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Solicitante",
      accessor: "requester",
    },
    {
      Header: "Origem Base",
      accessor: "base_origin",
    },
    {
      Header: "Filial",
      accessor: "filial",
    },
    {
      Header: "Adicionais",
      accessor: "adicionais",
    },
    // {
    //   Header: "Materiais",
    //   accessor: "materials",
    //   Cell: ({ row }: any) => <TruncatedText text={row.original.materials} />,
    // },
    // {
    //   Header: "Hierarquia",
    //   accessor: "hierarquia",
    //   Cell: ({ row }: any) => <TruncatedText text={row.original.hierarquia} />,
    // },
    {
      Header: "Observação",
      accessor: "observation",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: any) => {
        return <Status status={row.original.status} />},
    },
    {
      Header: "Data de solicitação",
      accessor: "date_request",
    },
    {
      Header: "Aprovar",
      accessor: "edit",
    },
  ];
};
