import _ from "lodash";

export const monthOpt = [
  { key: "01", label: "Janeiro", value: "01" },
  { key: "02", label: "Feveiro", value: "02" },
  { key: "03", label: "Março", value: "03" },
  { key: "04", label: "Abril", value: "04" },
  { key: "05", label: "Maio", value: "05" },
  { key: "06", label: "Junho", value: "06" },
  { key: "07", label: "Julho", value: "07" },
  { key: "08", label: "Agosto", value: "08" },
  { key: "09", label: "Setembro", value: "09" },
  { key: "10", label: "Outubro", value: "10" },
  { key: "11", label: "Novembro", value: "11" },
  { key: "12", label: "Dezembro", value: "12" },
];

export const yearsOpt = (() => {
  const max = new Date().getFullYear();
  const min = 2022;
  const size = max - min + 1;
  const years: number[] = [];

  _.times(size).map((value) => years.push(max - value));

  return years.map((year) => ({
    key: year,
    label: year,
    value: year,
  }));
})();
