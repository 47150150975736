import { memo } from "react";

import Paper from "../../../../../../components/Paper";
import { scenValue } from "../../../../../../utils/scenValue";

import { Title, Value, Info } from "./styles";

const CardChallenge: React.FC<any> = ({ data }) => {
  return (
    <Paper containerStyle={{ color: "#003b74" }}>
      <Title>Preço médio desafio</Title>
      {data && (
        <>
          <Value>{scenValue(data.preco_desafio)}</Value>
          <Info>
            <div>
              <span>ROB Desafio</span>
              <span>{scenValue(data.rob_desafio)}</span>
            </div>
            <div>
              <span>ROB PxP (Desafio)</span>
              <span>{scenValue(data.pxp_desafio)}%</span>
            </div>
          </Info>
        </>
      )}
    </Paper>
  );
};

export default memo(CardChallenge);
