import { useMemo, memo, useCallback } from "react";

import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import isDmxOrigin from "../../../../../../utils/isDmxOrigin";
import isDiscountOrigin from "../../../../../../utils/isDiscountOrigin";
import { usePriceManagement } from "../../../../hooks";

import EditionsModalDmx from "./EditionsModalDmx";
import EditionsModalCash from "./EditionsModalCash";
import EditionsModalDiscount from "./EditionsModalDiscount";

const AlterPricesButton: React.FC<any> = ({
  tableData,
  setTableData,
  selectedRows,
}) => {
  const { tabRequest } = usePriceManagement();

  const [isOpen, toggleOpen] = useToggle();

  const selectedRowsPk = useMemo(() => {
    const checkedSkus = selectedRows
      .filter((row: any) => !row.original.blockEditing)
      .map((row: any) => row.original.pk);
    return tableData.filter((row: any) => checkedSkus.includes(row.pk));
  }, [tableData, selectedRows]);

  const EditionsModal = useCallback(
    (props: any) => {
      if (isDiscountOrigin(tabRequest.proposal)) {
        return <EditionsModalDiscount {...props} />;
      }

      if (isDmxOrigin(tabRequest.proposal)) {
        return <EditionsModalDmx {...props} />;
      }

      return <EditionsModalCash {...props} />;
    },
    [tabRequest.proposal]
  );

  if (selectedRows.length === 0) return <div style={{ height: 25 }} />;
  return (
    <>
      <Button onClick={toggleOpen}>Alterar Selecionados</Button>
      <EditionsModal
        open={isOpen}
        setOpen={toggleOpen}
        tableData={tableData}
        setTableData={setTableData}
        selectedRowsPk={selectedRowsPk}
      />
    </>
  );
};

export default memo(AlterPricesButton);
