import { useState, useEffect, memo, useCallback } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";

import api from "../../../../../services/api";
import { usePriceManagement } from "../../../hooks";

import { Container, Header, Row } from "./styles";

const SubRowComponent: React.FC<any> = memo(({ row }) => {
  const { tabRequest } = usePriceManagement();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    const { condition, nivel } = tabRequest;

    const request = {
      id: row.original.id,
      nivel,
      condition,
    };

    api.post("price/list/history", request).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }, [tabRequest, row.original.id]);

  useEffect(() => {
    loading && fetchData();
  }, [loading, fetchData]);

  const formatDate = useCallback((date: string | null) => {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY HH:mm");
  }, []);

  const isRowAwaitingApproval = useCallback((row) => {
    return !row.n2_date || !row.n1_date;
  }, []);

  return (
    <Container>
      {!row?.isExpanded || loading ? (
        <b>Carregando...</b>
      ) : data.length > 0 ? (
        <>
          <b>{row?.original?.pk} - Histórico de preços</b>
          <Header>
            <span>Criador</span>
            <span>Valor</span>
            <span>Aprovação N1</span>
            <span>Aprovação N2</span>
            {/* <span>Aprovação N3</span> */}
          </Header>
          {data.map((row: any) => (
            <Row key={uuid()} isAwaitingApproval={isRowAwaitingApproval(row)}>
              <span>{row.creator_name}</span>
              <span>{row.new_value}</span>
              <span>
                {row.n1_name && `${row.n1_name} - `}
                {formatDate(row.n1_date)}
              </span>
              <span>
                {row.n2_name && `${row.n2_name} - `}
                {formatDate(row.n2_date)}
              </span>
              {/* <span>{approvalSection(row.users.n3_approval)}</span> */}
            </Row>
          ))}
        </>
      ) : (
        <b>Não há resultados para exibir.</b>
      )}
    </Container>
  );
});

export default SubRowComponent;
