import { HTMLAttributes, useCallback } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { AutocompleteStyled } from "./styles";

interface OptionProps {
  key: any;
  value: any;
  label: any;
}

export interface SelectProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: any;
  options: any[];
  setValue: any;
  filter?: boolean;
  errorMessage?: string | number;
  noOptionsText?: string;
}

export const Multiselect = ({
  label,
  value,
  options,
  setValue,
  filter = false,
  errorMessage = "",
  noOptionsText = "Sem opções disponíveis",
  ...rest
}: SelectProps) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  const onChange = useCallback(
    (_e, next) => {
      setValue(next);
    },
    [setValue]
  );

  const renderTags = useCallback((values: unknown[], getTagProps: any) => {
    return values.map((option: any, index: number) => (
      <Chip
        variant="outlined"
        label={option?.label}
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const detectPaste = useCallback(
    (value) => {
      const split = value.split(/;| /);

      if (split.length > 1) {
        const pastedInputValues: any = [];

        split.forEach((typedValue: any) => {
          const selected = options.find(
            (option) =>
              option?.label?.label === typedValue ||
              option?.label?.value === typedValue
          );

          if (selected) {
            pastedInputValues.push(selected.label);
          }
        });

        setValue(pastedInputValues);
      }
    },
    [options, setValue]
  );

  const renderInput = useCallback(
    (params: any) => {
      return (
        <>
          {label && (
            <label className="label-input">
              {label}
              {errorMessage && ` (${errorMessage})`}
            </label>
          )}
          {detectPaste(params.inputProps.value)}
          <TextField {...params} variant="outlined" />
        </>
      );
    },
    [label, errorMessage, detectPaste]
  );

  return (
    <AutocompleteStyled
      multiple
      value={value}
      filterOptions={filterOptions}
      onChange={onChange}
      renderTags={renderTags}
      renderInput={renderInput}
      options={options.map((option) => option.label || "")}
      style={{
        ...rest.style,
        marginTop: label ? 32 : 0,
        color: errorMessage ? "red" : "#003b74",
        transition: "all 200ms",
      }}
      noOptionsText={noOptionsText}
    />
  );
};
