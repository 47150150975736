export const columns = [
  {
    Header: "Atividade",
    accessor: "",
  },
  {
    Header: "Range",
    accessor: "",
  },
];
