import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { createPortal } from "react-dom";

import api from "../../../../services/api";
import Paper from "../../../../components/Paper";
import Button from "../../../../components/Button";
import PageTitle from "../../../../components/PageTitle";
import PageContainer from "../../../../components/PageContainer";
import HeaderContainer from "../../../../components/HeaderContainer";

import * as S from "./styles";
import ValidationStep from "./ValidationStep";

const portal = document.getElementById("portal") as HTMLElement;

const Validate: React.FC = () => {
  const [step, setStep] = useState(0);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationsResults, setValidationsResults] = useState<
    { label: string; success: boolean; message: string }[][]
  >([]);

  const [passedSteps, totalSteps] = useMemo(() => {
    const passed = validationsResults?.filter((step) =>
      step?.find((validation) => validation.success)
    ).length;
    const total = validationsResults?.length;
    return [passed, total];
  }, [validationsResults]);

  const [passedTests, totalTests] = useMemo(() => {
    const passed = validationsResults?.reduce(
      (acc, curr) =>
        acc + curr?.filter((validation) => validation.success).length,
      0
    );
    const total = validationsResults?.reduce(
      (acc, curr) => acc + curr?.length,
      0
    );
    return [passed, total];
  }, [validationsResults]);

  const validations = useMemo(() => {
    return [
      { label: "Validar materiais", param: "materiais" },
      {
        label: "Validar relatividade de produtos",
        param: "relatividade_de_produtos",
      },
      {
        label: "Validar relatividade de canais",
        param: "relatividade_de_canais",
      },
      {
        label: "Validar IP target",
        param: "ip_target",
      },
      {
        label: "Validar margem mín & máx",
        param: "margem_min_e_max",
      },
      {
        label: "Validar range",
        param: "range",
      },
      {
        label: "Validar volume",
        param: "volume",
      },
    ];
  }, []);

  const stopValidation = useMemo(() => {
    return step >= validations.length;
  }, [step, validations.length]);

  const resetData = useCallback(() => {
    setStep(0);
    setValidationsResults([]);
  }, []);

  useEffect(() => {
    if (!openValidation || stopValidation) return;

    const param = validations[step]?.param || "";

    if (!param) return;

    api
      .get(`/param/validate?param=${param}`)
      .then((res) => {
        setValidationsResults((current) => [...current, res.data.validations]);
        setStep((current) => current + 1);
      })
      .catch(() => {
        setValidationsResults((current) => [
          ...current,
          [
            {
              label: "Erro ao validar",
              success: false,
              message:
                "Erro interno no servidor, por favor tente novamente mais tarde.",
            },
          ],
        ]);
        setStep((current) => current + 1);
      });
  }, [openValidation, stopValidation, step, validations]);

  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle icon={<FaCheck />} title="Validar parâmetros" />
      </HeaderContainer>
      <Paper
        containerStyle={{
          padding: "2rem",
          gap: "1rem",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ color: "#003b74", fontWeight: "normal" }}>
          Validar parâmetros? Esse processo pode demorar alguns minutos.
        </h1>
        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
          <Link to="/processed/parameters">
            <Button outline width="12rem">
              Cancelar
            </Button>
          </Link>
          <Button
            width="12rem"
            onClick={() => {
              resetData();
              setOpenValidation(true);
            }}
          >
            Iniciar Validação
          </Button>
        </div>
      </Paper>
      {openValidation &&
        createPortal(
          <S.BG>
            <S.Container>
              <S.Validations>
                {validations.map(
                  (validation, i) =>
                    i <= step && (
                      <ValidationStep
                        key={validation.param}
                        i={i}
                        validation={validation}
                        validationsResults={validationsResults}
                      />
                    )
                )}
              </S.Validations>
              <S.Footer>
                {stopValidation && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "1.2rem",
                      color: "rgb(65, 70, 75)",
                    }}
                  >
                    <span>
                      Passos:{" "}
                      <span
                        style={{
                          color: passedSteps === totalSteps ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {passedSteps === totalSteps
                          ? passedSteps
                          : totalSteps - passedSteps}{" "}
                        {passedSteps === totalSteps ? "passados" : "falhos"}
                      </span>
                      , {totalSteps} total
                    </span>
                    <span>
                      Testes:{" "}
                      <span
                        style={{
                          color: passedTests === totalTests ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {passedTests === totalTests
                          ? passedTests
                          : totalTests - passedTests}{" "}
                        {passedTests === totalTests ? "passados" : "falhos"}
                      </span>
                      , {totalTests} total
                    </span>
                  </div>
                )}
                <Button
                  width="12rem"
                  containerStyle={{ marginLeft: "auto" }}
                  disable={!stopValidation}
                  onClick={() => {
                    setOpenValidation(false);
                    resetData();
                  }}
                >
                  Finalizar
                </Button>
              </S.Footer>
            </S.Container>
          </S.BG>,
          portal
        )}
    </PageContainer>
  );
};

export default Validate;
