import { useRef, useCallback, memo } from "react";

import api from "../../../../services/api";
import Button from "../../../../components/Button";
import AlertModal from "../../../../components/AlertModal";
import LoadingModal from "../../../../components/LoadingModal";
import StatusUpload from "../../../../components/StatusUpload";
import { usePriceManagement } from "../../hooks";

import { HeaderModal } from "./styles";

const ContainerModal: React.FC<any> = ({ row }) => {
  const { setTabList, setTabRequest, navigate } = usePriceManagement();

  const errorRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);

  const toggleError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const onView = useCallback(() => {
    const [condition, nivel] = row.original.base_origin.split("-");

    const request = { request_id: row.original.id, condition, nivel };

    openLoading(
      "Carregando tabela...",
      api
        .post("/price/upload/table", request)
        .then((response) => {
          const newTabRequest = {
            ...request,
            proposal: `${request.condition}-${request.nivel}`,
            serviceOrder: response.data.serviceOrder,
            url: "/price/upload/table",
            disableInputs: false,
            requestId: row.original.id,
          };

          setTabList(response.data.table);
          setTabRequest(newTabRequest);

          navigate.tableProposal();
        })
        .catch(() => toggleError())
    );
  }, [row, toggleError, openLoading, navigate, setTabList, setTabRequest]);

  return (
    <>
      <HeaderModal>
        <h1>
          <b>ID: {row.original.id}</b>
          <StatusUpload status={row.original.uploadStatus} />
        </h1>
        <p>
          <b>Solicitante:</b>
          {row.original.requester}
        </p>
        <p>
          <b>Origem base:</b>
          {row.original.base_origin}
        </p>
        <p>
          <b>Observação:</b>
          {row.original.observation}
        </p>
      </HeaderModal>
      {!/reprovado/i.test(row.original.uploadStatus) && (
        <Button width="100%" onClick={onView}>
          Visualizar
        </Button>
      )}
      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro, tente novamente."
      />
      <LoadingModal ref={loadingRef} />
    </>
  );
};

export default memo(ContainerModal);
