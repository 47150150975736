import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Status from "../../../components/Status";
import IColumn from "../../../components/TableBackendV2/types/IColumn";
import LoadingScreen from "../../../components/LoadingScreen";
import PageContainer from "../../../components/PageContainer";
import SceneryMonths from "../../../components/SceneryMonths";
import getTableLayout from "../../../utils/getTableLayout";
import { useToast } from "../../../contexts/ToastContext";

import Cards from "./Cards";
import Table from "./Table";
import Header from "./Header";
import { columnsDefaultStart, columnsToReplace } from "./Table/constants";

const NewScenery: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const { addToast } = useToast();

  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [bigDataReset, setBigDataReset] = useState(false);

  const [columns, setColumns] = useState<IColumn[]>([]);
  const [stickyPos, setStickyPos] = useState(5);
  const [columnsOrder, setColumnsOrder] = useState<number[]>([]);
  const [layoutReady, setLayoutReady] = useState(false);

  const toggleBigDataReset = useCallback(() => {
    setBigDataReset((current) => !current);
  }, []);

  useEffect(() => {
    getTableLayout("processed_scenery", (data) => {
      if (data.error) {
        history.push("/processed");
        addToast({
          type: "error",
          title: "Erro",
          description: "Erro ao carregar layout, tente novamente.",
        });
      }
      setColumns([
        ...columnsDefaultStart,
        ...data.layout.map((col) => {
          const colToReplace = columnsToReplace[col.accessor];
          if (colToReplace) return colToReplace;
          return col;
        }),
      ]);
      setStickyPos(data.sticky_pos);
      setColumnsOrder(data.order);
      setLayoutReady(true);
    });
  }, [addToast, history]);

  if (!layoutReady) return <LoadingScreen />;
  return (
    <PageContainer>
      <Header columns={columns} filters={filters} setFilters={setFilters} />
      <div style={{ gap: "1rem", display: "flex", alignItems: "center" }}>
        <SceneryMonths />
        <Status status={state?.sceneryStatus || "Editando"} />
      </div>
      <Cards bigDataReset={bigDataReset} />
      <Table
        filters={filters}
        columns={columns}
        stickyPos={stickyPos}
        columnsOrder={columnsOrder}
        toggleBigDataReset={toggleBigDataReset}
      />
    </PageContainer>
  );
};

export default NewScenery;
