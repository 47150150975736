import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding-right: 1rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 16rem;
  grid-gap: 2rem;
`;
