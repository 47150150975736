import { v4 as uuid } from "uuid";

import ColCheckbox from "./ColCheckbox";
import IColumnToDisplay from "../types/IColumnToDisplay";
import { Container } from "./styles";

interface IProps {
  colsToDisplay: IColumnToDisplay[];
  setColsToDisplay: React.Dispatch<React.SetStateAction<IColumnToDisplay[]>>;
}

const Cols: React.FC<IProps> = ({ colsToDisplay, setColsToDisplay }) => {
  return (
    <Container>
      {colsToDisplay
        .filter((col) => typeof col.Header === "string")
        .map((col) => (
          <ColCheckbox
            key={uuid()}
            col={col}
            setColsToDisplay={setColsToDisplay}
          />
        ))}
    </Container>
  );
};

export default Cols;
