import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import api from "../../../../../../services/api";
import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import Divider from "../../../../../../components/Divider";
import useToggle from "../../../../../../hooks/useToggle";
import LoadingModal from "../../../../../../components/LoadingModal";
import { money } from "../../../../../../utils/masks";

import Radios from "./Radios";

const EditAllButton: React.FC<any> = ({ setLoading, toggleBigDataReset }) => {
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const [isOpen, toggleOpen] = useToggle();

  const [ppc, setPpc] = useState("");
  const [ppcType, setPpcType] = useState("+Aumento R$");
  const [aumRed, setAumRed] = useState("");
  const [aumRedType, setAumRedType] = useState("+Aumento %");
  const [descAcao, setDescAcao] = useState("");
  const [descAcaoType, setDescAcaoType] = useState("-Redução %");

  const loadingRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setPpc("");
      setPpcType("+Aumento R$");
      setAumRed("");
      setAumRedType("+Aumento %");
      setDescAcao("");
      setDescAcaoType("+Aumento %");
    }
  }, [isOpen]);

  const formatValue = useCallback((value: string, type: string) => {
    return {
      "+Aumento R$": `${+value}`,
      "-Redução R$": `-${+value}`,
      "+Aumento %": `${+value}%`,
      "-Redução %": `-${+value}%`,
    }[type];
  }, []);

  const onEdit = useCallback(() => {
    const request: any = {
      scenery_id: id,
      ppc_min: formatValue(ppc, ppcType),
      desc_acao: formatValue(descAcao, descAcaoType),
      aumento_reducao: formatValue(aumRed, aumRedType),
    };

    openLoading(
      "Alterando...",
      api.put("/param/update/nielsen-ppv/all", request).then(() => {
        setLoading(true);
        toggleBigDataReset();
        toggleOpen();
      })
    );
  }, [
    id,
    formatValue,
    ppc,
    ppcType,
    descAcao,
    descAcaoType,
    aumRed,
    aumRedType,
    openLoading,
    setLoading,
    toggleBigDataReset,
    toggleOpen,
  ]);

  if (state?.sceneryViewOnly) return null;
  return (
    <>
      <Button
        onClick={toggleOpen}
        containerStyle={{ width: "14rem", height: "3rem" }}
      >
        Alterar Selecionados
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "500px" }}
        children={
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}
          >
            <LoadingModal ref={loadingRef} />
            <Radios
              types={["+Aumento %", "-Redução %"]}
              type={aumRedType}
              setType={setAumRedType}
            />
            <Input
              label="Alterar Aumento/Redução"
              name="aumRed"
              value={aumRed}
              onChange={(e) => setAumRed(money(e.target.value))}
            />
            <Divider />
            <Radios
              types={["+Aumento R$", "-Redução R$", "+Aumento %", "-Redução %"]}
              type={ppcType}
              setType={setPpcType}
            />
            <Input
              label="Alterar PPC Mín + Relatividade Canal"
              name="ppc_final"
              value={ppc}
              onChange={(e) => setPpc(money(e.target.value))}
            />
            <Divider />
            <Radios
              types={["-Redução %"]}
              type={descAcaoType}
              setType={setDescAcaoType}
            />
            <Input
              label="Alterar Desc Ação"
              name="desc_acao"
              value={descAcao}
              onChange={(e) => setDescAcao(money(e.target.value))}
            />
            <Button onClick={onEdit} containerStyle={{ marginTop: "2rem" }}>
              Alterar Selecionados
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default EditAllButton;
