import { Input } from "./components";

export const columnsToReplace: { [key: string]: any } = {
  price_challenge: {
    Header: "Preço Desafio",
    accessor: ({ row, getRows, updateRow, toggleBigDataReset }: any) => (
      <Input
        row={row}
        param="price_challenge"
        getRows={getRows}
        updateRow={updateRow}
        toggleBigDataReset={toggleBigDataReset}
      />
    ),
  },
};
