import { InputDeflator } from "./components";

export const columns = [
  {
    Header: "UF",
    accessor: "region",
  },
  {
    Header: "Canal",
    accessor: "channel",
  },
  // {
  //   Header: "Categoria",
  //   accessor: "category",
  // },
  {
    Header: "Código SKU",
    accessor: "sku_material",
  },
  {
    Header: "Relatividade Canal",
    accessor: ({ row }: any) => <InputDeflator row={row} />,
  },
];
