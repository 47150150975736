import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  overflow: auto;
  position: relative;
  background: #fff;
  flex-direction: column;
`;
