import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IProps {
  children: React.ReactNode;
}

const HeaderContainer: React.FC<IProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default HeaderContainer;
