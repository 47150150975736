import { scenValue } from "../../../../utils/scenValue";

export const formatData = (data: any[]) => {
  return data.map((row: any) => ({
    ...row,
    ppc_min: scenValue(row.ppc_min),
    ppc_max: scenValue(row.ppc_max),
    ppc_medio_sugestao: scenValue(row.ppc_medio_sugestao),
    share_volume_13_semanas: scenValue(row.share_volume_13_semanas),
    share_volume_3_semanas: scenValue(row.share_volume_3_semanas),
    share_volume_13_semanas_concorrente_principal: scenValue(
      row.share_volume_13_semanas_concorrente_principal
    ),
    share_volume_3_semanas_concorrente_principal: scenValue(
      row.share_volume_3_semanas_concorrente_principal
    ),
    ppc_13_semanas: scenValue(row.ppc_13_semanas),
    ppc_3_semanas: scenValue(row.ppc_3_semanas),
    ppc_13_semanas_concorrente_principal: scenValue(
      row.ppc_13_semanas_concorrente_principal
    ),
    ppc_3_semanas_concorrente_principal: scenValue(
      row.ppc_3_semanas_concorrente_principal
    ),
    indice_preco_13_semanas_total_mercado: scenValue(
      row.indice_preco_13_semanas_total_mercado
    ),
    indice_preco_3_semanas_total_mercado: scenValue(
      row.indice_preco_3_semanas_total_mercado
    ),
    indice_preco_13_semanas_concorrencia: scenValue(
      row.indice_preco_13_semanas_concorrencia
    ),
    indice_preco_3_semanas_concorrencia: scenValue(
      row.indice_preco_3_semanas_concorrencia
    ),
    ppc_medio_atual: scenValue(row.ppc_medio_atual),
    real_arvore: scenValue(row.real_arvore),
    relat_categoria_nielsen: scenValue(row.relat_categoria_nielsen),
    arvore_pesquisa: scenValue(row.arvore_pesquisa),
    ip_target_otimizador: scenValue(row.ip_target_otimizador),
    variacao_ppc: scenValue(row.variacao_ppc),
    ppv_medio_ponderado: scenValue(row.ppv_medio_ponderado),
    // Inputs
    ip_final: scenValue(row.ip_final, true),
    ppc_final: scenValue(row.ppc_final, true),
  }));
};
