export const columns = [
  {
    Header: "CÓD Capitão Nielsen",
    accessor: "",
  },
  {
    Header: "Categoria Nielsen",
    accessor: "",
  },
  {
    Header: " ",
    accessor: "",
  },
  {
    Header: " ",
    accessor: "",
  },
];
