import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  display: flex;
  justify-content: center;

  gap: 2rem;

  div {
    width: 100%;
    max-width: 20rem;
  }
`;
