import { useState, useCallback, useEffect, useMemo, memo } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IoPricetagsSharp } from "react-icons/io5";

import PageTitleComponent from "../../../../../components/PageTitle";

import { usePriceManagement } from "../../../hooks";

const PageTitle: React.FC<any> = () => {
  const { showComponent, tabRequest, navigate } = usePriceManagement();

  const [linkLabel, setLinkLabel] = useState<string>("");

  const handleSetLinkLabel = useCallback(() => {
    const labelDictionary: any = {
      main: "",
      upload: "Upload",
      visualize: "Consultar",
      inputOptions: "Nova Proposta",
      tableProposal: `Nova proposta: ${tabRequest.proposal}`,
      tableProposalVisualize: `Consultar: ${tabRequest.proposal}`,
    };

    return setLinkLabel(labelDictionary[showComponent]);
  }, [showComponent, tabRequest]);

  useEffect(() => {
    handleSetLinkLabel();
  }, [handleSetLinkLabel]);

  const isSubPage = useMemo(() => {
    return showComponent !== "main";
  }, [showComponent]);

  const pageIcon = useMemo(() => {
    return isSubPage ? <FaArrowLeft /> : <IoPricetagsSharp />;
  }, [isSubPage]);

  const onClick = useCallback(() => {
    const navigateDictionary: any = {
      main: () => {},
      upload: navigate.main,
      visualize: navigate.main,
      inputOptions: navigate.main,
      tableProposal: navigate.main,
      tableProposalVisualize: navigate.main,
    };

    return navigateDictionary[showComponent]();
  }, [showComponent, navigate]);

  return (
    <PageTitleComponent
      title="Gestão de preço"
      icon={pageIcon}
      onClick={onClick}
      linkLabel={linkLabel}
      containerStyle={{ cursor: isSubPage && "pointer" }}
    />
  );
};

export default memo(PageTitle);
