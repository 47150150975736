import { useCallback, useEffect, useState } from "react";
import { FaInfoCircle as ErrorSvg } from "react-icons/fa";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import api from "../../../../../../../../services/api";
import Paper from "../../../../../../../../components/Paper";
import { Autocomplete } from "../../../../../../../../components/AutoComplete";

import { columns } from "./constants";
import { Table, Error } from "./styles";

interface IProps {
  setUf: React.Dispatch<React.SetStateAction<string>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setCaptain: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<IProps> = ({ setUf, setRows, setCaptain }) => {
  const [sku, setSku] = useState("");
  const [skuOpt, setSkuOpt] = useState<any>([]);
  const [region, setRegion] = useState("");
  const [regionOpt, setRegionOpt] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [categoryOpt, setCategoryOpt] = useState<any>([]);
  const [brands, setBrands] = useState("");
  const [brandsOpt, setBrandsOpt] = useState<any>([]);

  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setBrands("");
    setCategory("");
  }, [sku, region]);

  useEffect(() => {
    setUf(region);
  }, [region, setUf]);

  useEffect(() => {
    setCaptain(sku);
  }, [sku, setCaptain]);

  const getRegion = useCallback(() => {
    api.get("/price/list/regions").then((response) => {
      setRegionOpt(
        response.data.regions
          .filter((item: any) => item.name && item.id)
          .map((item: any, index: number) => ({
            key: index + 1,
            label: item.name,
            value: item.id,
          }))
      );
    });
  }, []);
  useEffect(getRegion, [getRegion]);

  const getSku = useCallback(() => {
    api.get("/material/list").then((res) => {
      setSkuOpt(
        res.data.material
          .filter((item: any) => +item.sku)
          .map((item: any, index: number) => ({
            key: index + 1,
            label: +item.sku + "",
            value: +item.sku + "",
          }))
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  const getCategory = useCallback(() => {
    api.get("/param/list/nielsen-categories").then((res) => {
      setCategoryOpt(
        res.data.data
          .filter((item: any) => item)
          .map((item: any, index: number) => ({
            key: index + 1,
            label: item,
            value: item,
          }))
      );
    });
  }, []);
  useEffect(getCategory, [getCategory]);

  const getBrands = useCallback(() => {
    api.get("/param/list/nielsen-brands").then((res) => {
      setBrandsOpt(
        res.data.data
          .filter((item: any) => item)
          .map((item: any, index: number) => ({
            key: index + 1,
            label: item,
            value: item,
          }))
      );
    });
  }, []);
  useEffect(getBrands, [getBrands]);

  const getInfo = useCallback(() => {
    setRows([]);
    setDesc("");
    setError("");

    if (!region || !sku || !category) return;

    api
      .post("/param/info/sku-capitan-processados", {
        uf: region,
        sku,
        brand: brands,
        nielsen: category,
      })
      .then((res) => {
        if (res.data.message) {
          setError(res.data.message);
        } else {
          setDesc(res.data.response.WhoIsCapitanDescription);
          setRows(res.data.response.data);
        }
      });
  }, [setRows, region, sku, category, brands]);
  useEffect(getInfo, [getInfo]);

  const updateNielsen = useCallback(() => {
    if (!sku || !region) return;
    const url = "/param/info/sku-capitan-processados/nielsen-columns";
    const request = { sku, uf: region };

    api.post(url, request).then((res) => {
      setBrands(res.data.brand);
      setCategory(res.data.nielsen);
    });
  }, [region, sku]);
  useEffect(updateNielsen, [updateNielsen]);

  return (
    <Paper containerStyle={{ padding: "2rem", marginBottom: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={region}
                options={regionOpt}
                setValue={(newval: any) => {
                  console.log(newval);
                  setRegion(newval);
                }}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={category}
                options={categoryOpt}
                setValue={setCategory}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={brands}
                options={brandsOpt}
                setValue={setBrands}
              />
            </TableCell>
            <TableCell>{desc}</TableCell>
            <TableCell>
              {error && (
                <Error>
                  <ErrorSvg /> {error}
                </Error>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Header;
