import isDmxOrigin from "../../../utils/isDmxOrigin";
import isDiscountOrigin from "../../../utils/isDiscountOrigin";
import {
  truthTable,
  truthTableYTags,
} from "../../../utils/shouldRenderSection";

import CellVariation from "./CellVariation";

const generateColumns = (clickedRow: any, tableData: any) => {
  let finalColumns = [];

  const truthTableX = truthTable[clickedRow.original.base_origin];
  const hasMaterial = truthTableX[truthTableYTags["material"]];
  const hasHierarchy = truthTableX[truthTableYTags["hierarquia"]];

  if (hasMaterial) {
    finalColumns.push({
      Header: "Material",
      accessor: "material",
    });
  }

  if (hasHierarchy) {
    finalColumns.push({
      Header: "Hierarquia",
      accessor: "hierarquia",
    });
  }

  const optinalColumns = [
    {
      Header: "Filial",
      accessor: "regiao_vendas",
    },
    {
      Header: "Região",
      accessor: "regiao_uf",
    },
    {
      Header: "Grupo de Preço",
      accessor: "grupo_preco",
    },
    {
      Header: "Grupo de Cliente",
      accessor: "grupo_cliente",
    },
    {
      Header: "Canal Pricing",
      accessor: "canal_pricing",
    },
    {
      Header: "Raíz CNPJ",
      accessor: "raiz_cnpj",
    },
    {
      Header: "Cliente",
      accessor: "codigo_cliente",
    },
    {
      Header: "Cliente",
      accessor: "cliente",
    },
    {
      Header: "Lista de Preços",
      accessor: "lista_preco",
    },
    {
      Header: "Condição de Expedição",
      accessor: "cond_expedicao",
    },
    {
      Header: "Categoria de Itens",
      accessor: "categoria_item",
    },
    {
      Header: "Tipo de Documento de Vendas",
      accessor: "tipo_doc_vendas",
    },
  ];

  optinalColumns.forEach((col) => {
    if (
      tableData.find(
        (item: any) => item[col.accessor] && item[col.accessor] !== "-"
      )
    ) {
      finalColumns.push(col);
    }
  });

  if (/Y501|Y500(?!-N1\b)/i.test(clickedRow.original.base_origin)) {
    console.log(clickedRow.original.base_origin);
    finalColumns = [
      ...finalColumns,
      {
        Header: "Preço Y500-N1 R$",
        accessor: "y500n1_value",
      },
    ];
  }

  if (isDiscountOrigin(clickedRow.original.base_origin)) {
    finalColumns = [
      ...finalColumns,
      {
        Header: "Desconto Atual %",
        accessor: "currentPrice",
      },
      {
        Header: "Novo Desconto %",
        accessor: "newPrice",
      },
      {
        Header: "Data Início",
        accessor: "startDate",
      },
      {
        Header: "Data Fim",
        accessor: "endDate",
      },
    ];
  } else if (isDmxOrigin(clickedRow.original.base_origin)) {
    finalColumns = [
      ...finalColumns,
      {
        Header: "Dmx Atual %",
        accessor: "currentPrice",
      },
      {
        Header: "Novo Dmx %",
        accessor: "newPrice",
      },
      {
        Header: "Data Início",
        accessor: "startDate",
      },
      {
        Header: "Data Fim",
        accessor: "endDate",
      },
    ];
  } else {
    finalColumns = [
      ...finalColumns,
      {
        Header: "Preço Atual R$",
        accessor: "currentPrice",
      },
      {
        Header: "Novo preço R$",
        accessor: "newPrice",
      },
      {
        Header: "Variação %",
        accessor: "variation",
        Cell: ({ row }: any) => <CellVariation row={row} />,
      },
      {
        Header: "Data Início",
        accessor: "startDate",
      },
      {
        Header: "Data Fim",
        accessor: "endDate",
      },
    ];
  }

  return finalColumns;
};

export default generateColumns;
