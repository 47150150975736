import styled from "styled-components";
import {
  withStyles,
  TablePagination as MUITablePagination,
  TableCell,
} from "@material-ui/core";
import { Switch as MUISwitch, Toolbar as MUIToolbar } from "@material-ui/core";

import { Pagination as MUIPagination } from "@material-ui/lab";
import { shade } from "polished";

export const Container = styled.div`
  table {
    thead {
      position: sticky;
      top: -1rem;
      z-index: 2;
    }

    thead tr {
      background-color: #f2f3f5;
    }

    tr {
      :nth-child(even) {
        background-color: #f9f9f9;

        td {
          :first-child {
            background-color: #f9f9f9;
          }

          :nth-child(2) {
            background-color: #f9f9f9;
          }
        }
      }
    }

    tbody {
      tr,
      td {
        transition: background-color 0.3s;
      }
    }

    th {
      background-color: #f2f3f5;
    }

    td {
      border-bottom: none;

      :first-child {
        background-color: #fff;
      }

      :nth-child(2) {
        background-color: #fff;

        position: relative;

        :after {
          content: "";

          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          // border-right: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }

    th,
    td {
      width: auto;
      max-width: 250px;
      margin: 0;
      padding: 0.5rem 1rem;

      color: #003b74;
      font-size: 1rem;

      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      // :first-child {
      //   padding-left: 0.75rem;
      //   position: sticky;
      //   left: -1rem;
      //   z-index: 1;
      // }

      // :nth-child(2) {
      //   padding-left: 0.75rem;
      //   position: sticky;
      //   left: 4.2rem;
      //   z-index: 1;
      // }

      :last-child {
        max-width: 200px;
      }
    }

    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    input {
      font-size: 1rem;
    }
  }
`;

export const TablePagination = styled(MUITablePagination)`
  width: 100%;
  display: flex;
  border: none;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.6rem;

    > p {
      font-size: 1.4rem;
    }

    .MuiTablePagination-spacer {
      display: none;
    }
  }
`;

export const Switch = styled(MUISwitch)`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #003b74;
  }
  span.MuiSwitch-track {
    background-color: #003b74 !important;
  }
`;

export const Pagination = styled(MUIPagination)`
  div {
    font-size: 1.8rem;
    padding: 0 4px;

    width: 2.6rem;
    min-width: unset;
  }

  button {
    font-size: 1.5rem;
    height: 3rem;
    min-width: 3rem;
  }

  button.Mui-selected {
    background-color: #003b74;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${shade(0.3, "#003b74")};
    }
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Toolbar = styled(MUIToolbar)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div > p {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    margin-left: 1rem;
  }
`;

export const DropdownItem = styled.button`
  font-size: 1.3rem;
  padding: 1.6rem 0;
  width: 100%;
  background: none;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;

  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const ExportText = styled.h2`
  font-size: 1.4rem;
  color: #003b74;
  font-weight: 900;
`;

export const ExportButton = styled.button`
  font-size: 1.3rem;
  background: #f2f3f5;
  border: none;
  transition: background 0.3s;
  border-radius: 5px;
  min-width: 4rem;
  color: #003b74;
  padding: 0.6rem;
  margin-left: 1.2rem;
  font-weight: 500;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: ${shade(0.1, "#f2f3f5")};
  }
`;
interface StatusLabelProps {
  status: string;
}

export const StatusLabel = styled.span<StatusLabelProps>`
  color: ${({ status }) =>
    status === "aprovado"
      ? "#0f5132"
      : status === "aguardando"
      ? "#664d03"
      : "#842029"};
  background: ${({ status }) =>
    status === "aprovado"
      ? "#d1e7dd"
      : status === "aguardando"
      ? "#fff3cd"
      : "#f8d7da"};
  padding: 5px 15px;
  border-radius: 50px;
`;

export const TableCellStyled = withStyles({
  root: {
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg": {
        cursor: "pointer",
      },
    },
  },
})(TableCell);
