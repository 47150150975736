import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  outline?: boolean;
  containerStyle?: object;
  type?: string;
  width?: string;
  disable?: boolean;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  outline,
  type = "button",
  width,
  containerStyle = {},
  disable = false,
  onClick,
  ...rest
}) => (
  <Container
    outline={outline}
    width={width}
    style={containerStyle}
    type={type}
    disable={disable}
    onClick={() => {
      if (disable) return;
      onClick?.();
    }}
    {...rest}
  >
    {children}
  </Container>
);

export default Button;
