import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import Input from "../../../../../../../../components/Input";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputDeflator: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [deflator, setDeflator] = useState(row.deflator);

  const onChange = useCallback((e) => {
    setDeflator(e.target.value);
  }, []);

  const onBlur = useCallback(
    (e) => {
      let newVal = e.target.value || "0";
      setDeflator(newVal);

      const url = "/param/update/deflator";
      const req = {
        activity: row.activity,
        category: row.category,
        deflator: newVal,
      };

      api.post(url, req).catch(() => {
        setDeflator(row.deflator);
      });
    },
    [row]
  );

  if (isUserSupport) return deflator;
  return (
    <Input
      name="deflator"
      value={deflator}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={6}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default InputDeflator;
