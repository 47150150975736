export const columns = [
  {
    Header: "Atividade",
    accessor: "",
  },
  {
    Header: "Categoria",
    accessor: "",
  },

  {
    Header: "Deflator Mix",
    accessor: "",
  },
];
