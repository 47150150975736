import { useMemo, useCallback, useEffect } from "react";
import XLSX from "xlsx";
import { matchSorter } from "match-sorter";
import {
  useTable,
  useSortBy,
  useFilters,
  useRowState,
  useRowSelect,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Table as MUITable,
} from "@material-ui/core";
import {
  MdArrowDropUp as UpSvg,
  MdArrowDropDown as DownSvg,
} from "react-icons/md";

import Row from "./Row";
import Button from "../../Button";
import GlobalFilter from "./GlobalFilter";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function EnhancedTable({
  data,
  columns,
  buttons,
  setCount,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  updateTableData,
}) {
  // Filter setup for react-table
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  // react-table setup
  const {
    page,
    rows,
    gotoPage,
    prepareRow,
    setPageSize,
    globalFilter,
    headerGroups,
    getTableProps,
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
      defaultColumn,
      autoResetPage: false,
      autoResetFilters: false,
      updateTableData,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState
  );

  // #1 Connects <Pagination /> to react-table
  const connectPaginationToTable = useCallback(() => {
    setPageSize(rowsPerPage);
  }, [setPageSize, rowsPerPage]);
  useEffect(connectPaginationToTable, [connectPaginationToTable]);

  // #2 Updates react-table when changing pages via <Pagination />
  const handleChangePage = useCallback(() => {
    gotoPage(currentPage);
  }, [gotoPage, currentPage]);
  useEffect(handleChangePage, [handleChangePage]);

  const updatePagesCount = useCallback(() => {
    setCount(rows.length);
  }, [setCount, rows]);

  useEffect(updatePagesCount, [updatePagesCount]);

  // 3# Export table to excel
  const downloadExcel = useCallback(() => {
    const header = columns
      .map((col) => col.Header)
      .filter(
        (col) => !["Variação %", "Histórico", "Editar", "Aprovar"].includes(col)
      );

    const excelData = data.map((tableRow) => {
      const excelRow = {};

      columns.forEach((col) => {
        excelRow[col.Header] = tableRow[col.accessor];
      });

      return excelRow;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(excelData, { header });

    XLSX.utils.book_append_sheet(wb, ws1);
    XLSX.writeFile(wb, `export-table.xlsx`);
  }, [columns, data]);

  return (
    <TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Button width="12rem" onClick={downloadExcel}>
            Exportar Excel
          </Button>
          {buttons}
        </div>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setCurrentPage={setCurrentPage}
          gotoPage={gotoPage}
        />
      </div>
      <MUITable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell key={column.Header} width={column.width}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      userSelect: "none",
                      position: "relative",
                    }}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "1rem",
                        paddingTop: "2px",
                        cursor: "pointer",
                      }}
                    >
                      {column.render("Header")}
                      <span style={{ fontSize: 20, paddingTop: 3 }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DownSvg />
                          ) : (
                            <UpSvg />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => (
            <Row key={row.id} row={row} prepareRow={prepareRow} />
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}
