import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../../services/api";

import { Container } from "./styles";
import { CardBase, CardGoal, CardTechnic, CardChallenge } from "./components";

const Cards: React.FC<any> = ({ filters, bigDataReset }) => {
  const { sceneryId } = useParams<any>();

  const [data, setData] = useState<any>();

  const getData = useCallback(() => {
    if (!sceneryId) return;

    const request = {
      scenery_id: +sceneryId,
      ...filters,
    };

    api
      .post("/price/scenery/big-number-in-natura", request)
      .then((response) => {
        setData(response.data.bignumbers[0]);
      });
  }, [filters, sceneryId]);
  useEffect(getData, [getData, bigDataReset]);

  return (
    <Container>
      <CardBase data={data} />
      <CardTechnic data={data} />
      <CardGoal data={data} />
      <CardChallenge data={data} />
    </Container>
  );
};

export default memo(Cards);
