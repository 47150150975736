export const columns = [
  {
    Header: "Atividade",
    accessor: "",
  },
  {
    Header: "Categoria",
    accessor: "",
  },
  {
    Header: "Subcategoria",
    accessor: "",
  },
  {
    Header: "Cluster",
    accessor: "",
  },
  {
    Header: "Elasticidade",
    accessor: "",
  },
];
