import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const Radios = styled.div`
  width: 100%;
  gap: 2rem;
  padding-top: 3rem;

  display: flex;
  align-items: center;
`;
