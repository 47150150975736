import { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowLeft as GoBackSvg } from "react-icons/fa";

import PageTitleComponent from "../../../../../components/PageTitle";

const PageTitle: React.FC<any> = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push("/processed");
  }, [history]);

  return (
    <PageTitleComponent
      icon={<GoBackSvg />}
      title="Base de Preços"
      linkLabel="Cenário"
      onClick={goBack}
      containerStyle={{ cursor: "pointer" }}
    />
  );
};

export default memo(PageTitle);
