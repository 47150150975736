import styled from "styled-components";

interface ButtonProps {
  outline?: boolean;
  width?: string;
  disable?: boolean;
}

export const Container = styled.button<ButtonProps>`
  cursor: ${({ disable }) => (disable ? "not-allowed" : "boolean")};

  position: relative;
  padding: 1rem 0;
  width: ${({ width }) => (!!width ? width : "100%")};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ outline }) => (outline ? "#003b74" : "#fff")};
  font-size: 1.3rem;

  background: ${({ outline }) => (outline ? "#fff" : "#003b74")};
  border-radius: 8px;
  border: 1px solid #003b74;

  transition: background-color 0.3s color 0.3s;

  filter: ${({ disable }) => disable && "opacity(50%)"};

  &:hover {
    filter: ${({ disable }) => !disable && "brightness(110%)"};
  }
`;
