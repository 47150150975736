import { memo } from "react";

import Radio from "../../../../../../../components/Radio";

import { Container } from "./styles";

interface IProps {
  types: string[];
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

const Radios: React.FC<IProps> = ({ types, type, setType }) => {
  return (
    <Container>
      {types.map((value) => (
        <Radio
          label={value}
          checked={value === type}
          onChange={() => setType(value)}
        />
      ))}
    </Container>
  );
};

export default memo(Radios);
