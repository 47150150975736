import { InputMax, InputMaxRule, InputMin, InputMinRule } from "./components";

export const columns = [
  {
    Header: "Filial",
    accessor: "region",
  },
  {
    Header: "Canal Pricing",
    accessor: "channel",
  },
  {
    Header: "Atividade",
    accessor: "activity",
  },
  {
    Header: "Categoria",
    accessor: "category",
  },
  {
    Header: "Mínimo",
    accessor: ({ row }: any) => <InputMin row={row} />,
  },
  {
    Header: "Máximo",
    accessor: ({ row }: any) => <InputMax row={row} />,
  },
  {
    Header: "Regra Mín",
    accessor: ({ row }: any) => <InputMinRule row={row} />,
  },
  {
    Header: "Regra Máx",
    accessor: ({ row }: any) => <InputMaxRule row={row} />,
  },
];
