import { useMemo, memo } from "react";

import { Props } from "./interfaces";
import { keyGen } from "./actions";
import { Container } from "./styles";
import { textColors, backgroundColors } from "./constants";

const Status: React.FC<Props> = ({ status }) => {
  const key = useMemo(() => keyGen(status), [status]);
  const textColor = useMemo(() => textColors[key], [key]);
  const backgroundColor = useMemo(() => backgroundColors[key], [key]);

  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      {status}
    </Container>
  );
};

export default memo(Status);
