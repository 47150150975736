import { getExpander } from "./getExpander";
import { getInfoColumns } from "./getInfoColumns";
import { getPriceColumns } from "./getPriceColumns";
// import { getInputColumns } from "./getInputColumns";

type TGetColumns = (tabRequest: any, disableInputs?: boolean) => object[];

export const getColumns: TGetColumns = (tabRequest, disableInputs = false) => {
  const { proposal } = tabRequest;

  let columns: object[] = [
    ...getExpander(disableInputs),
    ...getInfoColumns(proposal),
    ...getPriceColumns(proposal),
    // ...getInputColumns(proposal, disableInputs),
  ];

  return columns;
};
