import { useState, useEffect, useCallback } from "react";

import api from "../../../../../services/api";
import { Multiselect } from "../../../../../components/AutoComplete";
import { getInputValue } from "../../actions";

import { IInputProps } from "./types";

const Input: React.FC<IInputProps> = ({ value, region, setValue }) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchPriceGroup = useCallback(() => {
    setValue([]);

    api
      .post("/price/list/price-group", { region: getInputValue(region) })
      .then((response) => {
        const formattedOptions = response.data.map((item: any) => ({
          key: item.price_group,
          label: { label: item.price_group, value: item.price_group },
        }));

        setOptions(formattedOptions);
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, [region, setValue]);

  useEffect(fetchPriceGroup, [fetchPriceGroup]);

  return (
    <Multiselect
      label="Grupo de Preço"
      value={value}
      options={options}
      setValue={setValue}
      errorMessage={errorMessage}
    />
  );
};

export default Input;
