import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import Select from "../../../../../../../../components/Select";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputDeflator: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [deflator, setDeflator] = useState(row.base_type || "");

  const onChange = useCallback(
    (e) => {
      let newVal = e.target.value;

      if (!newVal) return;

      setDeflator(newVal);

      const url = "/param/update/base";
      const req = {
        sku: row.sku,
        region: row.region,
        channel: row.channel,
        base_type: newVal,
      };

      api.post(url, req).catch(() => {
        setDeflator(row.deflator);
      });
    },
    [row]
  );

  if (isUserSupport) return deflator;
  return (
    <Select
      name="deflator"
      value={deflator}
      onChange={onChange}
      options={[
        { key: "", label: "Selecione...", value: "" },
        { key: "Base SP", label: "Base SP", value: "Base SP" },
        {
          key: "Base Específica",
          label: "Base Específica",
          value: "Base Específica",
        },
      ]}
      style={{
        width: "105px",
        height: "22px",
        paddingBottom: "0.25rem",
        color: "#003b74",
        fontSize: "1rem",
      }}
    />
  );
};

export default InputDeflator;
