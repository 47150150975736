export const tables = [
  "Relatividade de Produtos",
  "Relatividade de Canais",
  "Investimento",
  "IP Target",
  "Margem Min & Max",
  "Range de Preço",
  "Materiais",
  // "Relação Nielsen",
];
