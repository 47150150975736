import { useMemo, useCallback, memo } from "react";

import { conditionLabels } from "../../actions";
import { usePriceManagement } from "../../../hooks";
import { LevelsRow, LevelButton } from "../../styles";

import { IProps, TConditions, TShouldRender, THandleRender } from "./types";

const Conditions: React.FC<IProps> = ({ condition, setCondition }) => {
  const { isUserHQ, isUserSDP } = usePriceManagement();

  const conditions: TConditions = useMemo(() => {
    return ["Y500", "Y501", "Y540", "Y546", "YVPR"];
  }, []);

  const shouldRender: TShouldRender = useCallback(
    (currentCondition: string) => {
      if (
        ["Y540", "Y546", "YVPR"].includes(currentCondition) &&
        (!isUserHQ || isUserSDP)
      ) {
        return false;
      }

      return true;
    },
    [isUserSDP, isUserHQ]
  );

  const handleRender: THandleRender = useCallback(
    (currentCondition: string) => {
      if (!shouldRender(currentCondition)) return null;
      return (
        <LevelButton
          key={currentCondition}
          active={currentCondition === condition}
          onClick={() => setCondition(currentCondition)}
        >
          {conditionLabels(currentCondition)}
        </LevelButton>
      );
    },
    [shouldRender, condition, setCondition]
  );

  return <LevelsRow>{conditions.map(handleRender)}</LevelsRow>;
};

export default memo(Conditions);
