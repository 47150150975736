import { useState, useCallback } from "react";

import Table from "./components/EnhancedTable";
import TablePaginationActions from "./components/TablePaginationActions";

import { Container, TablePagination } from "./styles";

const TableBig = ({
  data = [],
  columns = [],
  setData = (current) => {},
  rowsPerPage = 20,
  buttons = <></>,
}) => {
  const [count, setCount] = useState(data.length);
  const [currentPage, setCurrentPage] = useState(0);

  const updateTableData = useCallback(
    (rowIndex, columnId, value) => {
      setData((current) =>
        current.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row
        )
      );
    },
    [setData]
  );

  const paginationOnChange = useCallback((_, newPageIndex) => {
    setCurrentPage(newPageIndex);
  }, []);

  const paginationFooterLabel = useCallback(({ from, to, count }) => {
    return `Visualizando ${from} - ${to} de ${count}`;
  }, []);

  return (
    <Container>
      <Table
        data={data}
        columns={columns}
        buttons={buttons}
        setCount={setCount}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        updateTableData={updateTableData}
      />
      <TablePagination
        page={currentPage}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={paginationOnChange}
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[rowsPerPage]}
        labelDisplayedRows={paginationFooterLabel}
      />
    </Container>
  );
};

export default TableBig;
