import { memo, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";

import api from "../../../../../../services/api";

interface IProps {
  filters: { [key: string]: string[] };
  getRows: () => void;
  allChecked: boolean;
  setAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderCheckbox: React.FC<IProps> = ({
  filters,
  getRows,
  allChecked,
  setAllChecked,
}) => {
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const onChange = useCallback(() => {
    const url = allChecked
      ? `/param/check/nielsen-ppv/clear?scenery_id=${id}`
      : `/param/check/nielsen-ppv/filters?scenery_id=${id}`;

    api.post(url, filters).then(() => {
      getRows();
      setAllChecked(!allChecked);
    });
  }, [allChecked, filters, getRows, id, setAllChecked]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="checkbox"
        checked={allChecked}
        onChange={onChange}
        disabled={state?.sceneryViewOnly}
      />
    </div>
  );
};

export default memo(HeaderCheckbox);
