import { useState, useCallback } from "react";

import Table from "./components/EnhancedTable";
import TablePaginationActions from "./components/TablePaginationActions";

import { Container, TablePagination } from "./styles";

const TableSmallViewOnly = ({
  data = [],
  columns = [],
  setData = (current) => {},
  rowsPerPage = 10,
  checkboxOnChange = (rows) => {},
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const updateTableData = useCallback(
    (rowIndex, columnId, value) => {
      setData((current) =>
        current.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row
        )
      );
    },
    [setData]
  );

  const paginationOnChange = useCallback((_, newPageIndex) => {
    setCurrentPage(newPageIndex);
  }, []);

  const paginationFooterLabel = useCallback(({ from, to, count }) => {
    return `Visualizando ${from} - ${to} de ${count}`;
  }, []);

  return (
    <Container>
      <Table
        data={data}
        columns={columns}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        checkboxOnChange={checkboxOnChange}
        updateTableData={updateTableData}
      />
      <TablePagination
        page={currentPage}
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={paginationOnChange}
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[rowsPerPage]}
        labelDisplayedRows={paginationFooterLabel}
      />
    </Container>
  );
};

export default TableSmallViewOnly;
