import { useRef, useCallback, memo } from "react";

import api from "../../../../../../services/api";
import LoadingModal from "../../../../../../components/LoadingModal";
import { useUpload } from "../../../hooks";

import { Upload } from "./styles";
import { useToast } from "../../../../../../contexts/ToastContext";

const UploadButton: React.FC = () => {
  const { addToast } = useToast();
  const { nivel, condition, resetPageData } = useUpload();

  const loadingRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const resetFileBugfix = useCallback((event) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  }, []);

  const onUpload = useCallback(
    (e) => {
      const name = e.target?.files?.[0]?.name;

      if (!name || !/\.xlsx$/i.test(name)) {
        return alert(
          "Arquivo inválido. Por favor envie no formato excel (.xlsx)."
        );
      }

      const file = e.target.files[0];

      if (file.size >= 4e7) {
        return alert("Arquivo inválido. Limite 40mb.");
      }

      const formData = new FormData();

      formData.append("nivel", nivel);
      formData.append("condition", condition);
      formData.append("excel_import", file);

      console.log("up");

      openLoading(
        "Fazendo upload...",
        api
          .post("/price/upload", formData)
          .then(() => {
            addToast({
              type: "success",
              title: "Sucesso",
              description: "Upload realizado com sucesso.",
            });
            resetPageData();
          })
          .catch((error) => {
            addToast({
              type: "error",
              title: error.response.status + "",
              description:
                error.response?.data?.message ||
                "Erro ao fazer upload, tente novamente.",
            });
          })
      );
    },
    [nivel, condition, openLoading, resetPageData, addToast]
  );

  return (
    <label htmlFor="uploadExcel">
      <Upload>Upload de arquivo</Upload>
      <input
        type="file"
        id="uploadExcel"
        name="uploadExcel"
        onClick={resetFileBugfix}
        onChange={onUpload}
        style={{ display: "none" }}
      />
      <LoadingModal ref={loadingRef} />
    </label>
  );
};

export default memo(UploadButton);
