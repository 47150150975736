import React, { useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { Container } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

interface ParamProps {
  token: string;
}

const Authenticate: React.FC = () => {
  const { token } = useParams<ParamProps>();

  const { signIn } = useAuth();

  const history = useHistory();

  useEffect(() => {
    if (token) {
      signIn(token);
    } else {
      history.push("/login");
    }
  }, [signIn, token, history]);

  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default Authenticate;
