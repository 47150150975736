import styled, { keyframes } from "styled-components";

import { SvgContainerProps } from "./interfaces";

export const BG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  overflow: hidden;

  background: rgba(73, 129, 194, 0.36);
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  width: 100%;
  height: 100%;
  max-width: 40rem;
  max-height: 20rem;
  padding: 7rem 2rem 1rem;

  background: white;
  border-radius: 12px;
`;

const svgPopupAnimation = keyframes`
 0% { transform: scale(3); opacity: 0; }
 30% { transform: scale(1); opacity: 1; }
 50% { transform: scale(1.2); opacity: 1; }
 100% { transform: scale(1); opacity: 1; }
`;

const svgContainerBG = ({ type }: SvgContainerProps) => {
  return {
    success: "rgba(76, 175, 80, 1)",
    error: "rgba(244,67,54,1)",
  }[type];
};

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -4.5rem;
  width: 9rem;
  height: 9rem;

  border-radius: 50%;
  background: ${svgContainerBG};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  animation-name: ${svgPopupAnimation};
  animation-duration: 0.7s;

  svg {
    color: white;
    font-size: 5rem;
  }
`;

export const Message = styled.p`
  color: black;
  font-size: 1.4rem;
  text-align: center;
  overflow-y: auto;
`;
