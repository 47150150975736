import { memo, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Button from "../../../../../components/Button";
import SceneryFilters from "../../../../../components/SceneryFilters";
import ButtonExportExcelViaUrl from "../../../../../components/ButtonExportExcelViaUrl";

import { Container } from "./styles";

const Buttons: React.FC<any> = ({ columns, filters, setFilters }) => {
  const history = useHistory();
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const viewResult = useCallback(() => {
    history.push({
      pathname: `/processed/scenery/${id}/details`,
      state,
    });
  }, [history, id, state]);

  return (
    <Container>
      <SceneryFilters
        url={`/param/list/nielsen-macro/table-item-filters?scenery_id=${id}`}
        columns={columns}
        currentFilters={filters}
        setCurrentFilters={setFilters}
      />
      <ButtonExportExcelViaUrl
        url={`/param/export/nielsen-macro?scenery_id=${id}`}
      />
      <Button width="15rem" onClick={viewResult}>
        Visualizar Resultado
      </Button>
    </Container>
  );
};

export default memo(Buttons);
