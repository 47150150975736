import { memo } from "react";

import Paper from "../../../../../../components/Paper";
import { scenValue } from "../../../../../../utils/scenValue";

import { Title, Value, Info } from "./styles";

const CardTechnic: React.FC<any> = ({ data }) => {
  if (!data) return null;
  return (
    <Paper containerStyle={{ color: "#003b74" }}>
      <Title>PPV Novo</Title>
      <Value>{scenValue(data.ppv_novo)}</Value>
      <Info>
        <div>
          <span>Volume</span>
          <span>{scenValue(data.volume_real) || "--"}</span>
        </div>
        <div>
          <span>PPV Atual</span>
          <span>{scenValue(data.ppv_atual) || "--"}</span>
        </div>
        <div>
          <span>PPV Variação</span>
          <span>{scenValue(data.variacao_ppv) || "--"}%</span>
        </div>
      </Info>
    </Paper>
  );
};

export default memo(CardTechnic);
