import moment from "moment";

type TGetStartDateLimit = (tabRequest: any) => string;

const sameDay: string[] = ["N1"];

export const getStartDateLimit: TGetStartDateLimit = (tabRequest: any) => {
  const { nivel, isUserHQ } = tabRequest;

  let startDateLimt = moment(new Date());

  if (!isUserHQ && !sameDay.includes(nivel)) {
    startDateLimt = startDateLimt.add(1, "days");
  }

  return startDateLimt.format("DD/MM/YYYY");
};
