import { withStyles } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

export const AutocompleteStyled = withStyles({
  root: {
    minHeight: "50px",
    padding: "0",
    fontSize: "1.3rem",
    position: "relative",

    "& .label-input": {
      position: "absolute",
      top: "-25px",
      fontSize: "1.3rem",
    },

    "& .MuiFormControl-root": {
      height: "100%",
      "& .MuiInputLabel-root": {
        marginTop: "5px",
      },

      "& .MuiOutlinedInput-root": {
        width: "100%",
        height: "100%",
        paddingRight: "50px !important",
        borderRadius: "10px",
        display: "flex",
        alignContent: "flex-start",
        "& .MuiButtonBase-root": {
          height: "80%",
          maxHeight: "25px",
          fontSize: "1rem",
          color: "#003b74",
          "& .MuiChip-label": {
            padding: "5px 10px",
          },
          "& .MuiSvgIcon-root": {
            color: "#003b74",
          },
        },
        "& .MuiAutocomplete-endAdornment": {
          top: "0",
          bottom: "0",
          right: "10px",
          display: "flex",
          alignItems: "center",

          "& .MuiSvgIcon-root": {
            fontSize: "3rem",
          },
          "& .MuiAutocomplete-clearIndicator": {
            "& svg": {
              fontSize: "2rem",
            },
          },
        },
      },
    },
  },
})(Autocomplete);
