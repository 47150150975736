import { memo } from "react";

import Paper from "../../../../../../components/Paper";
import { scenValue } from "../../../../../../utils/scenValue";

import { Title, Value, Info } from "./styles";

const CardTechnic: React.FC<any> = ({ data }) => {
  if (!data) return null;
  return (
    <Paper containerStyle={{ color: "#003b74" }}>
      <Title>PPC Novo</Title>
      <Value>{scenValue(data.ppc_novo)}</Value>
      <Info>
        <div>
          <span>IP Novo</span>
          <span>{scenValue(data.ip_novo)}</span>
        </div>
      </Info>
    </Paper>
  );
};

export default memo(CardTechnic);
