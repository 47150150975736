import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  :first-child {
    margin-bottom: 1rem;
  }
`;
