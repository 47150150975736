type TPriceColumn = {
  Header: string;
  accessor: string;
};

type TGetPriceColumns = (proposal: string) => TPriceColumn[];

const priceColumns: TPriceColumn[] = [
  { Header: "Preço N1 R$", accessor: "n1_valor" },
  { Header: "Preço N1 Next R$", accessor: "n1_valor_next" },
  { Header: "Preço Atual R$", accessor: "currentPrice" },
  { Header: "Preço Next R$", accessor: "nextPrice" },
];

const discountColumns: TPriceColumn[] = [
  { Header: "Preço N1 R$", accessor: "n1_valor" },
  { Header: "Preço N1 Next R$", accessor: "n1_valor_next" },
  { Header: "Desconto Atual %", accessor: "currentDiscount" },
  { Header: "Desconto Next %", accessor: "nextDiscount" },
];

const dmxColumns: TPriceColumn[] = [
  { Header: "Preço N1 R$", accessor: "n1_valor" },
  { Header: "Preço N1 Next R$", accessor: "n1_valor_next" },
  // { Header: "Preço Atual Y500 R$", accessor: "currentPriceDmx" },
  // { Header: "Preço Next Y500 R$", accessor: "nextPriceDmx" },
  { Header: "Dmx Atual %", accessor: "currentDmx" },
  { Header: "Dmx Next %", accessor: "nextDmx" },
];

export const getPriceColumns: TGetPriceColumns = (proposal: string) => {
  if (/Y501|YVPR/i.test(proposal)) {
    return [...discountColumns];
  }

  if (/N8/i.test(proposal)) {
    return [...discountColumns].slice(1);
  }

  if (/Y540|Y546/i.test(proposal)) {
    return [...dmxColumns];
  }

  if (/Y500-N1/i.test(proposal)) {
    return [...priceColumns].slice(1);
  }

  return [...priceColumns];
};
