import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .MuiCircularProgress-colorPrimary {
    color: #003b74;
  }
`;
