import styled from "styled-components";

export const Inputs = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0 0 0.5rem !important;
  padding: 0 !important;
`;

interface IRadioLabelProps {
  selected: boolean;
}
export const RadioLabel = styled.span<IRadioLabelProps>`
  margin: 0 2rem 0 0.5rem;
  font-size: 1.2rem;

  color: ${({ selected }) => (selected ? "#003b74" : "rgb(142, 141, 141)")};
`;

export const DateInputs = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr) 200px;
  align-items: flex-end;

  margin: 1rem 0 3rem;

  button {
    height: 50px;
  }
`;
