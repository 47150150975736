import styled from "styled-components";
import { Table as MUITable } from "@material-ui/core";

export const TableStyled = styled(MUITable)`
  thead tr {
    background-color: #f2f3f5;
    position: sticky;
    z-index: 20;
    top: 0;
  }

  th,
  td {
    width: auto;
    max-width: 500px;
    padding: 0.5rem 0.75rem;

    color: #003b74;
    border-bottom: 0;

    white-space: nowrap;
  }

  th {
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
  }

  th,
  td,
  input,
  select {
    font-size: 1rem !important;
  }
`;
