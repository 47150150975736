import styled from "styled-components";

export const BG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  overflow: hidden;

  background: rgba(73, 129, 194, 0.36);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  max-width: 95vw;
  max-height: 95vh;

  background: white;
  border-radius: 8px;

  overflow: hidden;

  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
`;

export const Validations = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #ccc;
`;
