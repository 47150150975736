import { useCallback, useEffect, useState } from "react";

import { Multiselect } from "../../../../../../../components/AutoComplete";
import api from "../../../../../../../services/api";

import { Container } from "./styles";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsBaseSp: React.FC<IProps> = ({ setInputs }) => {
  const [sku, setSku] = useState([]);
  const [region, setRegion] = useState([]);

  const [skuOpt, setSkuOpt] = useState([]);
  const [regionOpt, setRegionOpt] = useState([]);

  const getSku = useCallback(() => {
    api.get("/material/list/natura").then((response) => {
      setSkuOpt(
        response.data.material.map((item: any, index: number) => ({
          key: index,
          label: { label: +item.sku, value: +item.sku },
        }))
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      sku: sku.map((item: any) => item.value),
    }));
  }, [setInputs, sku]);

  const getRegion = useCallback(() => {
    api.get("/price/list/regions").then((response) => {
      setRegionOpt(
        response.data.regions.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.id },
        }))
      );
    });
  }, []);
  useEffect(getRegion, [getRegion]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      region: region.map((item: any) => item.value),
    }));
  }, [setInputs, region]);

  return (
    <Container>
      <Multiselect label="Sku" value={sku} options={skuOpt} setValue={setSku} />
      <Multiselect
        label="Região"
        value={region}
        options={regionOpt}
        setValue={setRegion}
      />
    </Container>
  );
};

export default InputsBaseSp;
