import { memo, useCallback, useEffect, useState } from "react";

import api from "../../../../services/api";

import { Container } from "./styles";
import { CardBase, CardTechnic } from "./components";
import { useParams } from "react-router-dom";

const Cards: React.FC<any> = ({ bigDataReset }) => {
  const { id } = useParams<any>();

  const [data, setData] = useState<any>();

  const getData = useCallback(() => {
    const url = "/price/scenery/big-number-nielsen-ppv";
    const req = { scenery_id: id };

    api.post(url, req).then((response) => {
      setData(response.data.bigNumber);
    });
  }, [id]);
  useEffect(getData, [getData, bigDataReset]);

  return (
    <Container>
      <CardBase data={data} />
      <CardTechnic data={data} />
    </Container>
  );
};

export default memo(Cards);
