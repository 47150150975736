import React, { useContext } from "react";

export type Permission = "Usuario" | "Admin";

type PermissionContextType = {
  isAllowedTo: (permission: string) => boolean;
};

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
};

// Create the context
const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);

type PermissionProviderProps = {
  permissions: string[];
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<PermissionProviderProps> = ({
  permissions,
  children,
}) => {
  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: string) => permissions.includes(permission);

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = (): PermissionContextType => {
  const context = useContext(PermissionContext);

  return context;
};

export default PermissionProvider;
