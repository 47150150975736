import styled from "styled-components";
import { FaTimes as CloseSvgUnstyled } from "react-icons/fa";

export const Modal = styled.div`
  position: absolute;
  top: 2.55rem;
  right: 0;
  left: -0.2rem;
  z-index: 9999999 !important;

  padding: 1rem;
  min-width: 14rem;

  background: white;
  border: 1px solid #ddd7e6;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 5px;

  span {
    margin-left: 0.5rem;
  }
`;

export const CloseSvg = styled(CloseSvgUnstyled)`
  cursor: pointer;
  font-size: 16px;

  padding-bottom: 3px;

  &:hover {
    color: red;
  }
`;

export const StatusSvg = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;
