import { useState, useEffect } from "react";

import api from "../../../../../services/api";
import { Multiselect } from "../../../../../components/AutoComplete";

import { IInputProps } from "./types";

const Input: React.FC<IInputProps> = ({ value, setValue }) => {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    api
      .get("/price/list/document-type")
      .then((response) => {
        const formattedOptions = response.data.map((item: any) => ({
          key: item.id,
          label: {
            label: `${item.id} - ${item.description}`,
            value: item.id,
          },
        }));

        setOptions(formattedOptions);
      })
      .catch((error) => {
        setErrorMessage(`Erro ${error.response?.status || 501}`);
      });
  }, []);

  return (
    <Multiselect
      label="Tipo de Documento de Venda"
      value={value}
      options={options}
      setValue={setValue}
      errorMessage={errorMessage}
    />
  );
};

export default Input;
