import { useCallback, useRef } from "react";

import api from "../../../../../../../../services/api";
import Button from "../../../../../../../../components/Button";
import LoadingModal from "../../../../../../../../components/LoadingModal";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

import { Container } from "./styles";

const Nav: React.FC<any> = ({ filters }) => {
  const { isUserSupport } = useAuth();

  const loadingRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  const handleEnable = useCallback(
    (enable: boolean) => {
      const loadingMsg = `${
        enable ? "Ativando" : "Desativando"
      } materiais, por favor aguarde, essa ação pode levar alguns minutos...`;

      const url = "/material/enable/all";
      const req = { enable, filters };

      openLoading(
        loadingMsg,
        api.post(url, req).then(() => {
          window.location.reload();
        })
      );
    },
    [filters, openLoading]
  );

  if (isUserSupport) return null;
  return (
    <Container>
      <Button onClick={() => handleEnable(true)}>Ativar Todos</Button>
      <Button onClick={() => handleEnable(false)}>Desativar Todos</Button>
      <LoadingModal ref={loadingRef} />
    </Container>
  );
};

export default Nav;
