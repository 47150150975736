import { Container, Label } from "./styles";

interface IProps {
  label: string;
  checked: boolean;
  onChange: (e: any) => void;
}

const Radio: React.FC<IProps> = ({ label, checked, onChange }) => {
  return (
    <Container>
      <input type="radio" checked={checked} onChange={onChange} />
      <Label selected={checked}>{label}</Label>
    </Container>
  );
};

export default Radio;
