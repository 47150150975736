import { useCallback } from "react";
import moment from "moment";

import Status from "../../../../components/Status";

import * as S from "./styles";

interface IProps {
  clickedRow: {
    original: {
      id: string;
      status: string;
      requester: string;
      base_origin: string;
      observation: string;
      base: {
        users: {
          n1_approval: {
            date: string;
            user: {
              firstName: string;
              lastName: string;
            };
          };
          n2_approval: {
            date: string;
            user: {
              firstName: string;
              lastName: string;
            };
          };
          n3_approval: {
            date: string;
            user: {
              firstName: string;
              lastName: string;
            };
          };
        };
      };
    };
  };
}

const Info: React.FC<IProps> = ({ clickedRow }) => {
  const formatApprovalUser = useCallback((approval) => {
    const { date } = approval;
    const { firstName, lastName } = approval.user;

    const formattedDate = moment.utc(date).format("DD/MM/YYYY HH:mm");

    return `${formattedDate} ${firstName} ${lastName}`;
  }, []);

  return (
    <S.Container>
      <h1>
        <b>ID: {clickedRow.original.id}</b>
        <Status status={clickedRow.original.status} />
      </h1>
      <p>
        <b>Solicitante:</b>
        {clickedRow.original.requester}
      </p>
      <p>
        <b>Origem base:</b>
        {clickedRow.original.base_origin}
      </p>
      <p>
        <b>Observação:</b>
        {clickedRow.original.observation}
      </p>
      {clickedRow.original.base.users.n1_approval && (
        <p>
          <b>Aprovador N1:</b>
          {formatApprovalUser(clickedRow.original.base.users.n1_approval)}
        </p>
      )}
      {clickedRow.original.base.users.n2_approval && (
        <p>
          <b>Aprovador N2:</b>
          {formatApprovalUser(clickedRow.original.base.users.n2_approval)}
        </p>
      )}
      {clickedRow.original.base.users.n3_approval && (
        <p>
          <b>Aprovador N3:</b>
          {formatApprovalUser(clickedRow.original.base.users.n3_approval)}
        </p>
      )}
    </S.Container>
  );
};

export default Info;
