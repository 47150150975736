import moment from "moment";

import { getDateLimits } from "../../../../actions";

const isValid = (date: any) => {
  if (date) {
    return moment(date, "DD/MM/YYYY", true).isValid();
  }

  return true;
};

const mStart = (startDate: string) => {
  return moment(startDate, "DD/MM/YYYY");
};

const mEnd = (endDate: string) => {
  return moment(endDate, "DD/MM/YYYY");
};

export const validateDates = (
  startDate: any,
  endDate: any,
  tabRequest: any
) => {
  if (!isValid(startDate)) {
    return { valid: false, error: `Data início inválida` };
  }

  if (!isValid(endDate)) {
    return { valid: false, error: `Data fim inválida` };
  }

  const [min, max] = getDateLimits(tabRequest, startDate);

  if (startDate) {
    const minIso = moment(min, "DD/MM/YYYY");

    if (mStart(startDate).isBefore(minIso)) {
      return { valid: false, error: `Data início mínima é ${min}` };
    }
  }

  if (endDate) {
    const maxIso = moment(max, "DD/MM/YYYY");

    if (moment(maxIso).isBefore(mEnd(endDate))) {
      return { valid: false, error: `Data final máxima é ${max}` };
    }
  }

  if (startDate && endDate) {
    if (mEnd(endDate).isBefore(mStart(startDate))) {
      return { valid: false, error: `Data final menor que inicial` };
    }
  }

  return { valid: true };
};
