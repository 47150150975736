export const columns = [
  {
    Header: "Sku",
    accessor: "sku_material",
  },
  {
    Header: "Descrição",
    accessor: "description",
  },
  {
    Header: "Peso",
    accessor: "size_uni",
  },
  {
    Header: "UF",
    accessor: "uf",
  },
  {
    Header: "%Relatividade",
    accessor: "relatividade",
  },
];

export const addColumns = [
  {
    Header: "Novo Sku",
    accessor: "",
  },

  {
    Header: " ",
    accessor: "",
  },
  {
    Header: "  ",
    accessor: "",
  },
];
