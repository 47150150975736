import { useState, useMemo, useEffect, memo } from "react";
import { MdVisibility as EditSvg } from "react-icons/md";

import Modal from "../../../../components/Modal";
import Paper from "../../../../components/Paper";
import useToggle from "../../../../hooks/useToggle";
import TableBackend from "../../../../components/TableBackendV2";
import LoadingScreen from "../../../../components/LoadingScreen";

import SearchInput from "./SearchInput";
import HistoryModal from "./HistoryModal";
import { getColumns, getTableData } from "./actions";

const limit = 20;

const PriceManagementTable: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [clickedRow, setClickedRow] = useState<any>({});
  const [openHistoryModal, toggleOpenHistoryModal] = useToggle();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPage] = useState(1);

  const tableColumns = useMemo(() => {
    const baseCols = getColumns();

    baseCols[baseCols.length - 1].accessor = ({ row }: any) => (
      <div
        onClick={() => {
          setClickedRow(row);
          toggleOpenHistoryModal();
        }}
      >
        <EditSvg size={16} style={{ cursor: "pointer" }} />
      </div>
    );

    return baseCols;
  }, [toggleOpenHistoryModal]);

  useEffect(() => {
    getTableData(
      (data) => {
        if (data.error) return console.error(data.message);
        setTotal(data.total);
        setTotalPage(data.totalPages);
        setTableData(data.tableData);
        setLoading(false);
      },
      { page, limit }
    );
  }, [page]);

  if (loading) return <LoadingScreen />;
  return (
    <Paper>
      <div style={{ marginBottom: "1rem" }}>
        <SearchInput
          setTotal={setTotal}
          setLoading={setLoading}
          setTotalPage={setTotalPage}
          setTableData={setTableData}
          setPage={(newPage: any) => {
            setLoading(true);
            setPage(newPage);
          }}
        />
      </div>
      <TableBackend
        rows={tableData}
        cols={tableColumns}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true);
          setPage(newPage);
        }}
        totalPages={totalPages}
      />
      <Modal
        open={openHistoryModal}
        setOpen={toggleOpenHistoryModal}
        children={
          <HistoryModal
            clickedRow={clickedRow}
            closeModal={toggleOpenHistoryModal}
          />
        }
      />
    </Paper>
  );
};

export default memo(PriceManagementTable);
