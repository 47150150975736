import React, { useCallback } from "react";
import styled from "styled-components";
import { TableRow, TableCell } from "@material-ui/core";

const Td = styled(TableCell)`
  margin: 0;
  padding: 0 0.75rem;

  color: #003b74;
  font-size: 1rem;
  border-right: 0;
  border-bottom: 1px solid #ddd7e6;
  transition: background-color 300ms;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Row = ({ row, prepareRow, renderRowSubComponent, visibleColumns }) => {
  prepareRow(row);

  const backgroundColor = useCallback((row) => {
    const yellow = "#fffdf2";
    const blue = "#f2fbff";
    const green = "#f6fff2";
    const red = "#fff2f2";

    if (row.original.order_status?.match(/aguardando/gi)) {
      return yellow;
    }

    if (row.isSelected) {
      return blue;
    }

    if (row.original.validation === "valid") {
      return green;
    }

    if (row.original.validation === "invalid") {
      return red;
    }

    return "#fff";
  }, []);

  return (
    <React.Fragment>
      <TableRow {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Td {...cell.getCellProps()} backgroundColor={backgroundColor(row)}>
            {cell.render("Cell")}
          </Td>
        ))}
      </TableRow>
      {row.isExpanded ? (
        <TableRow>
          <Td colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</Td>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};

export default Row;
