import moment from "moment";

type TGetEndDateKey = (tabRequest: any) => "sameMonth" | "infinite";

type TGetEndDateLimit = (tabRequest: any, startDate: any) => string;

const sameMonth: string[] = [
  // Y500
  "Y500-N2",
  "Y500-N5",
  "Y500-N6",
  "Y500-NP",
  // Y501
  "Y501-N2",
  "Y501-N5",
  "Y501-N6",
  "Y501-N7",
  "Y501-NP",
];

const dependsOnUserType: string[] = ["Y501-N4"];

const getEndDateKey: TGetEndDateKey = (tabRequest: any) => {
  const { proposal, isUserHQ } = tabRequest;

  if (sameMonth.includes(proposal)) {
    return "sameMonth";
  }

  if (dependsOnUserType.includes(proposal) && !isUserHQ) {
    return "sameMonth";
  }

  return "infinite";
};

export const getEndDateLimit: TGetEndDateLimit = (
  tabRequest: any,
  startDate: any
) => {
  const endDateLimit = getEndDateKey(tabRequest);

  if (endDateLimit === "infinite") return "31/12/9999";

  if (startDate) {
    const dtInicio = new Date(
      startDate.split("/").reverse().join("-") + " 23:59:59"
    );

    return moment(dtInicio).endOf("month").format("DD/MM/YYYY");
  } else {
    return moment(new Date()).endOf("month").format("DD/MM/YYYY");
  }
};
