import { useCallback, useState } from "react";
import api from "../../../../../../../../services/api";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const InputDeflator: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [enabled, setEnabled] = useState(!!row.enabled);

  const onChange = useCallback(() => {
    api
      .post("/material/enable", { sku: row.sku })
      .then(() => {
        setEnabled((current) => !current);
      })
      .catch(() => {
        setEnabled(!!row.enabled);
      });
  }, [row]);

  return (
    <input
      type="checkbox"
      checked={enabled}
      onChange={onChange}
      disabled={isUserSupport}
    />
  );
};

export default InputDeflator;
