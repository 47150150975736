import { memo } from "react";

import HeaderContainer from "../../../../components/HeaderContainer";

import Buttons from "./Buttons";
import PageTitle from "./PageTitle";

const Header: React.FC<any> = ({ columns, filters, setFilters }) => {
  return (
    <HeaderContainer>
      <PageTitle />
      <Buttons columns={columns} filters={filters} setFilters={setFilters} />
    </HeaderContainer>
  );
};

export default memo(Header);
