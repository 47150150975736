import { useCallback, useEffect, useState } from "react";

import api from "../../../../../../../services/api";
import { Multiselect } from "../../../../../../../components/AutoComplete";

import { Container } from "./styles";
import Input from "../../../../../../../components/Input";

interface IProps {
  setInputs: React.Dispatch<React.SetStateAction<{}>>;
}

const InputsMargemMinMax: React.FC<IProps> = ({ setInputs }) => {
  const [uf, setUf] = useState([]);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [channel, setChannel] = useState([]);
  const [category, setCategory] = useState([]);
  const [activity, setActivity] = useState([]);

  const [ufOpt, setUfOpt] = useState([]);
  const [channelOpt, setChannelOpt] = useState([]);
  const [categoryOpt, setCategoryOpt] = useState([]);
  const [activityOpt, setActivityOpt] = useState([]);

  // UF stuff
  const getUf = useCallback(() => {
    api.get("/price/list/regions").then((response) => {
      setUfOpt(
        response.data.regions.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.id },
        }))
      );
    });
  }, []);
  useEffect(getUf, [getUf]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      uf: uf.map((item: any) => item.value),
    }));
  }, [setInputs, uf]);

  // Channel stuff
  const getChannel = useCallback(() => {
    api.get("/price/list/channels").then((response) => {
      setChannelOpt(
        response.data.channels.map((item: any, index: number) => ({
          key: index,
          label: { label: item.channel, value: item.channel },
        }))
      );
    });
  }, []);
  useEffect(getChannel, [getChannel]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      channel: channel.map((item: any) => item.value),
    }));
  }, [setInputs, channel]);

  // Category stuff
  const getCategory = useCallback(() => {
    api.get("/material/category/processados/").then((response) => {
      setCategoryOpt(
        response.data.material.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.name },
        }))
      );
    });
  }, []);
  useEffect(getCategory, [getCategory]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      category: category.map((item: any) => item.value),
    }));
  }, [setInputs, category]);

  // Activity stuff
  const getActivity = useCallback(() => {
    api.get("/material/list/activity/processados").then((response) => {
      setActivityOpt(
        response.data.material.map((item: any, index: number) => ({
          key: index,
          label: { label: item.name, value: item.name },
        }))
      );
    });
  }, []);
  useEffect(getActivity, [getActivity]);

  useEffect(() => {
    setInputs((current) => ({
      ...current,
      activity: activity.map((item: any) => item.value),
    }));
  }, [setInputs, activity]);

  // Min stuff
  useEffect(() => {
    setInputs((current) => ({ ...current, min_margin: min }));
  }, [setInputs, min]);

  // Max stuff
  useEffect(() => {
    setInputs((current) => ({ ...current, max_margin: max }));
  }, [setInputs, max]);

  return (
    <>
      <Container>
        <Multiselect label="UF" value={uf} options={ufOpt} setValue={setUf} />
        <Multiselect
          label="Atividade"
          value={activity}
          options={activityOpt}
          setValue={setActivity}
        />
        <Multiselect
          label="Categoria"
          value={category}
          options={categoryOpt}
          setValue={setCategory}
        />
      </Container>
      <Container>
        <Multiselect
          label="Canal"
          value={channel}
          options={channelOpt}
          setValue={setChannel}
        />
        <Input
          label="Mínimo"
          name="min"
          onBlur={(e) => setMin(e.target.value)}
        />
        <Input
          label="Máximo"
          name="max"
          onBlur={(e) => setMax(e.target.value)}
        />
      </Container>
    </>
  );
};

export default InputsMargemMinMax;

