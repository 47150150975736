import styled from "styled-components";

export const Upload = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 30px;

  color: white;
  font-size: 1.3rem;
  background: #dfac03;
  border-radius: 8px;

  transition: background 0.3s color 0.3s;

  &:hover {
    filter: brightness(110%);
  }
`;
