import { memo } from "react";
import { Link } from "react-router-dom";

import Button from "../../../../../components/Button";
import ButtonNewScenery from "./ButtonNewScenery";

import { Container, Divider } from "./styles";

const Buttons: React.FC = () => {
  return (
    <Container>
      <Link to="/processed/parameters">
        <Button
          containerStyle={{
            backgroundColor: "transparent",
            border: "1px solid #003b74",
            color: "#003b74",
          }}
        >
          Alterar Parâmetros
        </Button>
      </Link>
      <Divider />
      <ButtonNewScenery />
    </Container>
  );
};

export default memo(Buttons);
