import {
  FaCheckCircle as ValidSvg,
  FaTimesCircle as InvalidSvg,
  FaExclamationCircle as EditingSvg,
} from "react-icons/fa";

export const getSvg = (validationStr: string) =>
  ({
    valid: <ValidSvg />,
    invalid: <InvalidSvg />,
    editing: <EditingSvg />,
  }[validationStr] || "");
