export const textColors = {
  waiting: "#664d03",
  approved: "#0f5132",
  rejected: "#842029",
  editing: "#41464b",
  waiting_n1: '#2f3f5f'
};

export const backgroundColors = {
  waiting: "#fff3cd",
  approved: "#d1e7dd",
  rejected: "#f8d7da",
  editing: "#e2e3e5",
  waiting_n1: '#c2d6ee'
};
