import React, { useState, useCallback, useLayoutEffect, useMemo } from "react";

import Modal from "../../../components/Modal";
import Paper from "../../../components/Paper";
import Table from "../../../components/Table";
import useToggle from "../../../hooks/useToggle";
import LoadingScreen from "../../../components/LoadingScreen";

import ContainerModal from "./ContainerModal";
import { Header } from "./components";
import { Container } from "./styles";
import { UploadProvider } from "./hooks";
import { getColumns, getTableData } from "./actions";

const Upload: React.FC = () => {
  const [nivel, setNivel] = useState<string>("");
  const [loading, toggleLoading] = useToggle(true);
  const [tableData, setTableData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [condition, setCondition] = useState<string>("");
  const [clickedRow, setClickedRow] = useState<any>({});

  const columns = useMemo(() => getColumns(), []);

  const resetPageData = useCallback(toggleLoading, [toggleLoading]);

  const getInitialState = useCallback(() => {
    return getTableData((data) => {
      if (data.error) return console.error(data.message);
      setTableData(data.tableData);
      toggleLoading();
    });
  }, [toggleLoading]);

  useLayoutEffect(() => {
    loading && getInitialState();
  }, [loading, getInitialState]);

  if (loading) return <LoadingScreen />;
  return (
    <UploadProvider
      value={{
        nivel,
        setNivel,
        condition,
        setCondition,
        resetPageData,
      }}
    >
      <Container>
        <Header />
        <Paper>
          <Table
            tableData={tableData}
            columns={columns}
            pagination
            borderBottom={false}
            showSelectOptions={false}
            showExportOptions={false}
            filters={[{ search: false, selectFilter: true }]}
            openModal={(row) => {
              setClickedRow(row);
              setOpenModal(true);
            }}
          />
          <Modal
            containerStyle={{ width: "40rem" }}
            open={openModal}
            setOpen={() => setOpenModal(!openModal)}
            children={
              <ContainerModal
                row={clickedRow}
                updatePageState={() => {
                  toggleLoading();
                  setOpenModal(false);
                }}
              />
            }
          />
        </Paper>
      </Container>
    </UploadProvider>
  );
};

export default Upload;
