import {
  truthTable,
  truthTableYTags,
} from "../../../../../../../utils/shouldRenderSection";

type TInfoColumn = { name: string; key: string };
type TGetColumns = (condition: string, nivel: string) => string[];

const infoColumns: TInfoColumn[] = [
  { name: "Material", key: "material" },
  { name: "Hierarquia", key: "hierarquia" },
  { name: "Filial", key: "filial" },
  { name: "Canal Pricing", key: "canal_pricing" },
  { name: "Categoria", key: "categoria" },
  { name: "Grupo de Cliente", key: "grupo_de_cliente" },
  { name: "Raíz CNPJ", key: "raiz_cnpj" },
  { name: "Grupo de Preço", key: "grupo_preco" },
  { name: "Cliente", key: "cliente" },
  { name: "Canal de Distribuição", key: "canal_de_distribuicao" },
  { name: "Empresa", key: "empresa" },
  { name: "Região", key: "regiao" },
  { name: "Lista de Preços", key: "lista_de_precos" },
  { name: "Condição de Expedição", key: "condicao_de_expedicao" },
  { name: "Documento de Venda", key: "documento_de_venda" },
  { name: "Categoria de Itens", key: "categoria_de_items" },
  { name: "Grupo de Preço", key: "grupo_de_preco" },
];

const getInfoColumns = (condition: string, nivel: string) => {
  const truthTableX = truthTable[`${condition}-${nivel}`];

  return infoColumns
    .filter((col: TInfoColumn) => {
      const truthTableXY = truthTableX[truthTableYTags[col.key]];
      return truthTableXY;
    })
    .map((col: TInfoColumn) => {
      return col.name;
    });
};

export const getColumns: TGetColumns = (condition, nivel) => {
  return [
    ...getInfoColumns(condition, nivel),
    "Novo Valor",
    "Data Início",
    "Data Fim",
  ];
};
