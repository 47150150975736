import React, { ReactNode } from "react";
import { Container, Title, IconWrapper } from "./styles";

interface PageTitleProps {
  icon?: ReactNode;
  title: string;
  linkLabel?: string;
  ButtonAction?: () => JSX.Element;
  containerStyle?: object;
  onClick?: () => void;
}

const PageTitle: React.FC<PageTitleProps> = ({
  children,
  icon,
  title,
  linkLabel,
  ButtonAction,
  containerStyle = {},
  onClick,
}) => {
  return (
    <Container style={containerStyle} onClick={onClick}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Title>
        {title}
        {linkLabel && (
          <span
            style={{
              fontSize: "16px",
              color: "rgb(142, 141, 141)",
              marginLeft: "10px",
            }}
          >
            / {linkLabel}
          </span>
        )}
      </Title>
      {ButtonAction && (
        <div
          style={{
            marginLeft: "auto",
            width: "120px",
          }}
        >
          <ButtonAction />
        </div>
      )}
    </Container>
  );
};

export default PageTitle;
