import { useCallback, useEffect, useState } from "react";
import { FaCheck as SelectAllSvg } from "react-icons/fa";

import api from "../../../services/api";
import Button from "../../Button";
import { IFilter } from "../types";
import { Multiselect } from "../../AutoComplete";

interface IProps {
  filter: IFilter;
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: any[] }>>;
}

const FilterInput: React.FC<IProps> = ({ filter, setFilters }) => {
  const [value, setValue] = useState<any>([]);
  const [options, setOptions] = useState<any[]>([]);

  const getOptions = useCallback(() => {
    api[filter.method](filter.endpoint).then((res) => {
      setOptions(filter.mapOptions(res.data));
    });
  }, [filter]);
  useEffect(getOptions, [getOptions]);

  const updateFilters = useCallback(() => {
    setFilters((current) => ({
      ...current,
      [filter.accessor]: value.map((v: any) => v.value),
    }));
  }, [filter.accessor, setFilters, value]);
  useEffect(updateFilters, [updateFilters]);

  const selectAll = useCallback(() => {
    if (value.length === options.length) {
      setValue([]);
      setFilters((current) => ({
        ...current,
        [filter.accessor]: [],
      }));
    } else {
      setValue(options.map((o) => ({ ...o.label })));
      setFilters((current) => ({
        ...current,
        [filter.accessor]: options.map((o: any) => o.label.value),
      }));
    }
  }, [filter.accessor, options, setFilters, value.length]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Multiselect
        label={filter.label}
        value={value}
        options={options}
        setValue={setValue}
      />
      {filter.allowSelectAll && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: "1.2rem",
          }}
        >
          <input
            type="checkbox"
            checked={value.length === options.length}
            onChange={selectAll}
          />
          <span style={{ paddingTop: "0.2rem" }}>
            Selecionar todos ({filter.label})
          </span>
        </div>
      )}
    </div>
  );
};

export default FilterInput;
