import { useEffect, useState } from "react";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import api from "../../../../../../services/api";
import Paper from "../../../../../../components/Paper";

import { Table } from "./styles";
import { columns } from "./constants";
import { InputDeflator } from "./components";

const TableRangeDePreço: React.FC<any> = () => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    api.get("/param/list/range").then((res) => {
      setRows(res.data);
    });
  }, []);

  return (
    <Paper containerStyle={{ padding: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, index) => (
            <TableRow key={`${row.atividade}-${index}`}>
              <TableCell style={{ textTransform: "capitalize" }}>
                {row.atividade}
              </TableCell>
              <TableCell>
                <InputDeflator row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TableRangeDePreço;
