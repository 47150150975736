import PropTypes from "prop-types";

import Input from "../../Input";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <>
      <Input
        // icon={<SearchIcon />}
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        name="global"
        placeholder={`Buscar`}
        inputProps={{ "aria-label": "search" }}
        containerStyle={{
          width: "300px",
          border: "none",
          background: "#F2F3F5",
          borderRadius: "200px",
        }}
      />
    </>
  );
};

GlobalFilter.propTypes = {
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
