import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;

  button {
    width: auto;
    height: 25px;
    padding: 0 1.5rem;
  }
`;
