import { Grid } from "@material-ui/core";
import _ from "lodash";
import { memo, useCallback, useMemo } from "react";

import { Multiselect } from "../../AutoComplete";

const Input: React.FC<any> = ({ input, filters, options, setFilters }) => {
  const onChange = useCallback(
    (newValue: any) => {
      const newValues = _.uniqBy(
        newValue.map((v: any) =>
          typeof v === "string" ? { label: v, value: v } : v
        ),
        (obj: any) => obj.value
      );

      setFilters((current: any) => {
        return { ...current, [input.accessor]: newValues };
      });
    },
    [input.accessor, setFilters]
  );

  const inputOptions = useMemo(() => {
    return options[input.accessor] || [];
  }, [input.accessor, options]);

  if (!options?.[input.accessor] || options[input.accessor].length === 0) {
    return null;
  }
  return (
    <Grid item xs={6}>
      <Multiselect
        label={input.Header}
        value={filters[input.accessor] || []}
        options={inputOptions}
        setValue={onChange}
      />
    </Grid>
  );
};

export default memo(Input);
