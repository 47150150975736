import { useState, useRef, useEffect, useMemo, memo, useCallback } from "react";
import XLSX from "xlsx";
import moment from "moment";

import api from "../../../../../services/api";
import Table from "../../../../../components/TableSmallViewOnly";
import Button from "../../../../../components/Button";
import Status from "../../../../../components/Status";
import AlertModal from "../../../../../components/AlertModal";
import isDmxOrigin from "../../../../../utils/isDmxOrigin";
import LoadingScreen from "../../../../../components/LoadingScreen";
import isDiscountOrigin from "../../../../../utils/isDiscountOrigin";
import { scenValue } from "../../../../../utils/scenValue";
import { usePriceManagement } from "../../../hooks";
import {
  truthTable,
  truthTableYTags,
} from "../../../../../utils/shouldRenderSection";

import CellVariation from "./CellVariation";
import { HeaderModal } from "./styles";

const HistoryModal: React.FC<any> = ({ clickedRow, closeModal }) => {
  const { setTabList, setTabRequest, navigate } = usePriceManagement();

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const errorRef = useRef<any>(null);

  const toggleError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  useEffect(() => {
    const { id, level, condition } = clickedRow.base;

    const request = { id, level, condition };

    api
      .post("/price/list/service-orders/materials", request)
      .then((response: any) => {
        setTableData(
          response.data.items.map((item: any) => {
            return {
              id: item.id,

              material: item.material || "-",
              hierarquia: item.hierarquia || "-",
              grupo_preco: item.grupo_preco || "-",
              regiao_uf: item.regiao_uf || "-",
              regiao_vendas: item.regiao_vendas || "-",
              canal_pricing: item.canal_pricing || "-",
              grupo_cliente: item.grupo_cliente || "-",
              raiz_cnpj: item.raiz_cnpj || "-",
              codigo_cliente: item.codigo_cliente || item.cliente || "-",
              lista_preco: item.lista_preco || "-",
              cond_expedicao: item.cond_expedicao || item.cond_expecicao || "-",
              categoria_item: item.categoria_item || "-",
              tipo_doc_vendas: item.tipo_doc_vendas || "-",
              adicionais: clickedRow.base.adicionais || "-",
              newPrice: item.new_value ? scenValue(item.new_value) : "-",
              currentPrice: item.valor?.trim().replace(".", ",") || "-",
              startDate:
                moment.utc(item.data_ini_mat).format("DD/MM/YYYY") || "-",
              endDate:
                moment.utc(item.data_fim_mat).format("DD/MM/YYYY") || "-",
            };
          })
        );
        setLoading(false);
      });
  }, [clickedRow]);

  const columns = useMemo(() => {
    let finalColumns = [];

    const truthTableX = truthTable[clickedRow.base_origin];
    const hasMaterial = truthTableX[truthTableYTags["material"]];
    const hasHierarchy = truthTableX[truthTableYTags["hierarquia"]];

    if (hasMaterial) {
      finalColumns.push({
        Header: "Material",
        accessor: "material",
      });
    }

    if (hasHierarchy) {
      finalColumns.push({
        Header: "Hierarquia",
        accessor: "hierarquia",
      });
    }

    const optinalColumns = [
      {
        Header: "Filial",
        accessor: "regiao_vendas",
      },
      {
        Header: "Região",
        accessor: "regiao_uf",
      },
      {
        Header: "Grupo de Preço",
        accessor: "grupo_preco",
      },
      {
        Header: "Canal Pricing",
        accessor: "canal_pricing",
      },
      {
        Header: "Grupo de Cliente",
        accessor: "grupo_cliente",
      },
      {
        Header: "Raíz CNPJ",
        accessor: "raiz_cnpj",
      },
      {
        Header: "Cliente",
        accessor: "codigo_cliente",
      },
      {
        Header: "Cliente",
        accessor: "cliente",
      },
      {
        Header: "Lista de Preços",
        accessor: "lista_preco",
      },
      {
        Header: "Condição de Expedição",
        accessor: "cond_expedicao",
      },
      {
        Header: "Categoria de Itens",
        accessor: "categoria_item",
      },
      {
        Header: "Tipo de Documento de Vendas",
        accessor: "tipo_doc_vendas",
      },
    ];

    optinalColumns.forEach((col) => {
      if (
        tableData.find(
          (item: any) => item[col.accessor] && item[col.accessor] !== "-"
        )
      ) {
        finalColumns.push(col);
      }
    });

    if (isDiscountOrigin(clickedRow.base_origin)) {
      return [
        ...finalColumns,
        {
          Header: "Desconto Atual %",
          accessor: "currentPrice",
        },
        {
          Header: "Novo Desconto %",
          accessor: "newPrice",
        },
        {
          Header: "Data Início",
          accessor: "startDate",
        },
        {
          Header: "Data Fim",
          accessor: "endDate",
        },
      ];
    }

    if (isDmxOrigin(clickedRow.base_origin)) {
      return [
        ...finalColumns,
        {
          Header: "Dmx Atual %",
          accessor: "currentPrice",
        },
        {
          Header: "Novo Dmx %",
          accessor: "newPrice",
        },
        {
          Header: "Data Início",
          accessor: "startDate",
        },
        {
          Header: "Data Fim",
          accessor: "endDate",
        },
      ];
    }

    return [
      ...finalColumns,
      {
        Header: "Preço Atual R$",
        accessor: "currentPrice",
      },
      {
        Header: "Novo preço R$",
        accessor: "newPrice",
      },
      {
        Header: "Variação %",
        accessor: "variation",
        Cell: ({ row }: any) => <CellVariation row={row} />,
      },
      {
        Header: "Data Início",
        accessor: "startDate",
      },
      {
        Header: "Data Fim",
        accessor: "endDate",
      },
    ];
  }, [clickedRow, tableData]);

  const onContinueEditing = useCallback(() => {
    const url = `price/list/saved/${clickedRow.id}`;

    api
      .get(url)
      .then((response) => {
        setTabList(response.data);
        setTabRequest({
          condition: response.data[0].condicao,
          nivel: response.data[0].nivel_tabela,
          proposal: `${response.data[0].condicao}-${response.data[0].nivel_tabela}`,
          serviceOrder: clickedRow.id,
        });
        setTimeout(() => {
          closeModal();
          navigate.tableProposal();
        }, 1000);
      })
      .catch(() => {
        toggleError();
      });
  }, [
    clickedRow,
    navigate,
    setTabList,
    setTabRequest,
    closeModal,
    toggleError,
  ]);

  const downloadExcel = useCallback(() => {
    const header = columns
      .map((col) => col.Header)
      .filter((col) => col !== "Variação %");

    const data = tableData.map((tableRow) => {
      const excelRow: any = {};

      columns.forEach((col) => {
        if (col.Header === "Variação %") return;
        excelRow[col.Header] = tableRow[col.accessor];
      });

      return excelRow;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(data, { header });

    XLSX.utils.book_append_sheet(wb, ws1);
    XLSX.writeFile(
      wb,
      `GestaoDePreco-ID_${clickedRow.id}-Origem_${clickedRow.base_origin}-Solicitante_${clickedRow.requester}.xlsx`
    );
  }, [columns, tableData, clickedRow]);

  const formatApprovalUser = useCallback((approval) => {
    const { date } = approval;
    const { firstName, lastName } = approval.user;

    const formattedDate = moment.utc(date).format("DD/MM/YYYY HH:mm");

    return `${formattedDate} ${firstName} ${lastName}`;
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ height: 500 }}>
          <LoadingScreen />
        </div>
      ) : (
        <>
          <HeaderModal>
            <h1>
              <b>ID: {clickedRow.id}</b>
              <Status status={clickedRow.status} />
            </h1>
            <p>
              <b>Solicitante:</b>
              {clickedRow.requester}
            </p>
            <p>
              <b>Origem base:</b>
              {clickedRow.base_origin}
            </p>
            <p>
              <b>Observação:</b>
              {clickedRow.observation}
            </p>
            {clickedRow.base.users.n1_approval && (
              <p>
                <b>Aprovador N1:</b>
                {formatApprovalUser(clickedRow.base.users.n1_approval)}
              </p>
            )}
            {clickedRow.base.users.n2_approval && (
              <p>
                <b>Aprovador N2:</b>
                {formatApprovalUser(clickedRow.base.users.n2_approval)}
              </p>
            )}
            {clickedRow.base.users.n3_approval && (
              <p>
                <b>Aprovador N3:</b>
                {formatApprovalUser(clickedRow.base.users.n3_approval)}
              </p>
            )}
          </HeaderModal>
          <Button
            width="200px"
            onClick={downloadExcel}
            containerStyle={{ margin: "1rem 0 1rem 2rem" }}
          >
            Exportar Excel
          </Button>
          {clickedRow.status.includes("Editando") && (
            <Button
              onClick={onContinueEditing}
              containerStyle={{ width: "95%", margin: "2rem auto 0" }}
            >
              Continuar edição
            </Button>
          )}
          {tableData.length > 0 && (
            <Table data={tableData} columns={columns} setData={setTableData} />
          )}
        </>
      )}

      <AlertModal
        ref={errorRef}
        type="error"
        message="Erro, tente novamente."
      />
    </>
  );
};

export default memo(HistoryModal);
