import { useState, useMemo, useCallback } from "react";
import { FaSearch as SearchSvg, FaFilter as FilterSvg } from "react-icons/fa";

import { Autocomplete } from "../../../../../components/AutoComplete";

import { Modal, ModalHeader, CloseSvg, StatusSvg } from "./styles";

const SelectColumnFilter: React.FC<any> = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows, id } = column;

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const toggleOpenFilter = useCallback(() => {
    setOpenFilter((current) => !current);
  }, []);

  const handleFilterOnClick = useCallback(() => {
    if (filterValue) return setFilter(undefined);
    return toggleOpenFilter();
  }, [filterValue, setFilter, toggleOpenFilter]);

  const options = useMemo<any>(() => {
    const rowValuesSet = new Set();

    preFilteredRows.forEach((row: any) => {
      rowValuesSet.add(row.values[id]);
    });

    const optionsArray = [...rowValuesSet.values()]
      .filter((value) => value)
      .map((value) => ({
        key: value,
        label: value,
        value,
      }));

    return optionsArray;
  }, [id, preFilteredRows]);

  return (
    <>
      {openFilter && (
        <Modal>
          <ModalHeader>
            <div>
              <FilterSvg />
              <span>Filtrar</span>
            </div>
            <CloseSvg onClick={toggleOpenFilter} />
          </ModalHeader>
          <Autocomplete
            value={filterValue}
            options={options}
            setValue={(value: string) => {
              toggleOpenFilter();
              setFilter(value || undefined);
            }}
          />
        </Modal>
      )}
      <StatusSvg onClick={handleFilterOnClick}>
        {filterValue ? <FilterSvg /> : <SearchSvg />}
      </StatusSvg>
    </>
  );
};

export default SelectColumnFilter;
