import { InputDeflator } from "./components";

export const columns = [
  {
    Header: "Sku",
    accessor: "sku",
  },
  {
    Header: "Sigla",
    accessor: "sigla",
  },
  {
    Header: "Descrição",
    accessor: "descricao",
  },
  {
    Header: "Nível 01",
    accessor: "nivel01",
  },
  {
    Header: "Nível 02",
    accessor: "nivel02",
  },
  {
    Header: "Nível 03",
    accessor: "nivel03",
  },
  {
    Header: "Nível 04",
    accessor: "nivel04",
  },
  {
    Header: "Nível 05",
    accessor: "nivel05",
  },
  {
    Header: "Ativo",
    accessor: ({ row }: any) => <InputDeflator row={row} />,
  },
];
