import { memo, useCallback, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { TableRow, TableCell } from "@material-ui/core";

import Cell from "../../Cell";
import IColumn from "../../types/IColumn";

interface IRowProps {
  row: { [key: string]: any };
  cols: IColumn[];
  rowIndex: number;
  cellProps: { [key: string]: any };
  stickyPos: number;
  subRowComponent?: React.FC<any>;
}

const Row: React.FC<IRowProps> = ({
  row,
  cols,
  rowIndex,
  cellProps,
  stickyPos,
  subRowComponent: SubRowComponent,
}) => {
  const [data, setData] = useState(row);
  const [showSubRow, setShowSubRow] = useState(false);

  const cellsRef = useRef<any[]>([]);

  const updateRow = useCallback((newRow: { [key: string]: any }) => {
    setData(newRow);
  }, []);

  const updateCell = useCallback((accessor: string, newValue: any) => {
    setData((current) => ({ ...current, [accessor]: newValue }));
  }, []);

  const toggleSubRow = useCallback(() => {
    setShowSubRow((current) => !current);
  }, []);

  return (
    <>
      <TableRow style={{ backgroundColor: rowIndex & 1 ? "#f9f9f9" : "#fff" }}>
        {cols.map((col, colIndex) => (
          <Cell
            key={uuid()}
            cellsRef={cellsRef}
            rowIndex={rowIndex}
            colIndex={colIndex}
            stickyPos={stickyPos}
          >
            {typeof col.accessor === "string"
              ? data[col.accessor]
              : col.accessor({
                  row: data,
                  col,
                  rowIndex,
                  colIndex,
                  updateRow,
                  updateCell,
                  showSubRow,
                  toggleSubRow,
                  ...cellProps,
                })}
          </Cell>
        ))}
      </TableRow>
      {SubRowComponent && showSubRow && (
        <TableRow
          style={{
            borderTop: "1px solid #f2f3f5",
            borderBottom: "1px solid #f2f3f5",
          }}
        >
          <TableCell colSpan={cols.length} style={{ padding: "0 !important" }}>
            <SubRowComponent
              row={row}
              cols={cols}
              rowIndex={rowIndex}
              updateRow={updateRow}
              updateCell={updateCell}
              showSubRow={showSubRow}
              toggleSubRow={toggleSubRow}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(Row);
