import Input from "./Input";

import shouldRenderSection from "../../../../../utils/shouldRenderSection";

import { IProps } from "./types";

const InputChannel: React.FC<IProps> = ({
  value,
  region,
  setValue,
  proposal,
}) => {
  if (/np/i.test(proposal)) return null;
  if (!shouldRenderSection(proposal, "canal_pricing")) return null;
  return <Input value={value} region={region} setValue={setValue} />;
};

export default InputChannel;
