import { useMemo, memo } from "react";

import { Props } from "./interfaces";
import { keyGen } from "./actions";
import { Container } from "./styles";
import { textColors, backgroundColors } from "./constants";

const Status: React.FC<Props> = ({ status }) => {
  const key = useMemo(() => keyGen(status), [status]);

  const textColor = useMemo(
    () => (key ? textColors[key] : "transparent"),
    [key]
  );

  const backgroundColor = useMemo(
    () => (key ? backgroundColors[key] : "transparent"),
    [key]
  );

  if (!status) return null;
  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      <span>{status}</span>
    </Container>
  );
};

export default memo(Status);
