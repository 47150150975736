export const getNewPrice = (
  inputType: string,
  inputValue: string,
  currentPrice: string
) => {
  if (!inputValue) {
    return "";
  }

  if (inputType === "Novo Preço (R$)") {
    return inputValue;
  }

  const currentPriceNum = +currentPrice?.replace(",", ".");

  if (inputType === "Aumento (+R$)") {
    return currentPriceNum + +inputValue;
  }

  if (inputType === "Desconto (-R$)") {
    const calc = currentPriceNum - +inputValue;
    return calc > 0 ? calc : 0;
  }

  const inputValuePercent = +inputValue / 100;
  const variation = currentPriceNum * inputValuePercent;

  if (inputType === "Aumento (+%)") {
    return (variation + currentPriceNum).toFixed(2);
  }

  if (inputType === "Desconto (-%)") {
    return (-variation + currentPriceNum).toFixed(2);
  }

  return "";
};
