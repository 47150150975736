import { useState, useCallback, memo, useEffect } from "react";

import Status from "../../../../../components/Status";
import { usePriceManagement } from "../../../hooks";

import { Container } from "./styles";
import { getSvg, validateInputs } from "./actions";

const CellValidate: React.FC<any> = ({ row, column, updateTableData }) => {
  const { tabRequest } = usePriceManagement();

  const [errorMsg, setErrorMsg] = useState("");
  const [validationStr, setValidationStr] = useState("");

  const handleUpdateTableData = useCallback(
    (newValidationStr: string, newErrorMsg: string) => {
      // Prevents useless re-renders & bugs
      if (newValidationStr === validationStr && errorMsg === newErrorMsg) {
        return;
      }

      if (newValidationStr) {
        setErrorMsg(newErrorMsg);
        setValidationStr(newValidationStr);
        return updateTableData(row.index, column.id, newValidationStr);
      }

      setErrorMsg(newErrorMsg);
      setValidationStr(newValidationStr);
      return updateTableData(row.index, column.id, "missing_inputs");
    },
    [validationStr, errorMsg, row, column.id, updateTableData]
  );

  const validateRow = useCallback(() => {
    validateInputs(row.original, tabRequest, handleUpdateTableData);
  }, [row.original, tabRequest, handleUpdateTableData]);

  useEffect(() => {
    !/aguardando/i.test(row.original.order_status) && validateRow();
  }, [row.original.order_status, validateRow]);

  if (
    row.original.blockEditing &&
    /aguardando/i.test(row.original.order_status)
  ) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Status status={row.original.order_status} />
      </div>
    );
  }

  return (
    <Container validationStr={validationStr}>
      {getSvg(validationStr)} {errorMsg}
    </Container>
  );
};

export default memo(CellValidate);
