import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import api from "../../../../../../services/api";
import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import Radio from "../../../../../../components/Radio";
import Button from "../../../../../../components/Button";
import Divider from "../../../../../../components/Divider";
import useToggle from "../../../../../../hooks/useToggle";
import LoadingModal from "../../../../../../components/LoadingModal";
import { money } from "../../../../../../utils/masks";

const EditAllButton: React.FC<any> = ({ setLoading, toggleBigDataReset }) => {
  const { id } = useParams<any>();
  const { state } = useLocation<any>();

  const [isOpen, toggleOpen] = useToggle();

  const [ip, setIp] = useState("ip_final");
  const [ipFinal, setIpFinal] = useState("");
  const [ppcFinal, setPpcFinal] = useState("");

  const loadingRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setIp("ip_final");
      setIpFinal("");
      setPpcFinal("");
    }
  }, [isOpen]);

  const onEdit = useCallback(() => {
    const request: any = {
      scenery_id: id,
    };

    if (ip === "ip_final") {
      request.ip_final = +ipFinal;
    } else {
      request.ip = ip;
    }

    if (ppcFinal) {
      request.ppc_final = +ppcFinal;
    }

    openLoading(
      "Alterando...",
      api.put("/param/update/nielsen-macro/all", request).then((response) => {
        setLoading(true);
        toggleBigDataReset();
        toggleOpen();
      })
    );
  }, [
    id,
    ip,
    ppcFinal,
    openLoading,
    ipFinal,
    setLoading,
    toggleBigDataReset,
    toggleOpen,
  ]);

  if (state?.sceneryViewOnly) return null;
  return (
    <>
      <Button
        containerStyle={{ width: "14rem", height: "3rem" }}
        onClick={toggleOpen}
      >
        Alterar Selecionados
      </Button>
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "420px" }}
        children={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <LoadingModal ref={loadingRef} />
            <Radio
              label="IP Target"
              checked={ip === "target"}
              onChange={() => setIp("target")}
            />
            <Radio
              label="IP Otimizador"
              checked={ip === "target_otimizador"}
              onChange={() => setIp("target_otimizador")}
            />
            <Box sx={{ display: "flex", height: "22px" }}>
              <Radio
                label="IP Final"
                checked={ip === "ip_final"}
                onChange={() => setIp("ip_final")}
              />
              {ip === "ip_final" && (
                <Input
                  name="ip_final"
                  value={ipFinal}
                  onChange={(e) => setIpFinal(money(e.target.value))}
                  containerStyle={{
                    width: "80px",
                    height: "22px",
                    padding: "0.2rem",
                    marginLeft: "1rem",
                  }}
                  inputStyle={{
                    padding: "10px",
                    width: "100%",
                    color: "#003b74",
                    fontSize: "1.25rem",
                  }}
                />
              )}
            </Box>
            <Divider />
            <Input
              label="Alterar PPC Final"
              name="ppc_final"
              value={ppcFinal}
              onChange={(e) => setPpcFinal(money(e.target.value))}
            />
            <Button onClick={onEdit} containerStyle={{ marginTop: "2rem" }}>
              Alterar Selecionados
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default EditAllButton;
