import StatusUpload from "../../../../components/StatusUpload";

export const getColumns = () => {
  return [
    {
      Header: "Visualizar",
      accessor: "edit",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Solicitante",
      accessor: "requester",
    },
    {
      Header: "Origem Base",
      accessor: "base_origin",
    },
    {
      Header: "Data de solicitação",
      accessor: "date_request",
    },
    {
      Header: "Status",
      accessor: "uploadStatus",
      Cell: ({ row }: any) => (
        <StatusUpload status={row.original.uploadStatus} />
      ),
    },
  ];
};
