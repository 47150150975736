import { useState } from "react";
import { Body, Header } from "./components";

const TableRelaçãoNielsen: React.FC = () => {
  const [rows, setRows] = useState<any[]>([]);

  const [uf, setUf] = useState("");
  const [captain, setCaptain] = useState("");

  return (
    <>
      <Header setUf={setUf} setRows={setRows} setCaptain={setCaptain} />
      {/* <Body uf={uf} rows={rows} setRows={setRows} captain={captain} /> */}
    </>
  );
};

export default TableRelaçãoNielsen;
