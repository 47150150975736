import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FaTimesCircle as DeleteSvg } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";

import api from "../../../../../../services/api";
import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import useToggle from "../../../../../../hooks/useToggle";
import AlertModal from "../../../../../../components/AlertModal";
import LoadingModal from "../../../../../../components/LoadingModal";
import { useToast } from "../../../../../../contexts/ToastContext";
import { Autocomplete } from "../../../../../../components/AutoComplete";

import { Container, Dates, Inputs } from "./styles";
import { monthOpt, yearsOpt } from "./constants";
import { AsyncSelectPaginated } from "../../../../../../components/AsyncSelectPaginated";

interface OptionProps {
  label: string;
  value: string | number;
}

const ButtonNewScenery: React.FC = () => {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [dates, setDates] = useState<string[]>([]);
  const [isOpen, toggleOpen] = useToggle();
  const [sceneryAlias, setSceneryAlias] = useState("");

  const [isOpenSelectNivel02, setIsOpenSelectNivel02] = useState(false);
  const [isOpenSelectNivel03, setIsOpenSelectNivel03] = useState(false);
  const [isOpenSelectNivel04, setIsOpenSelectNivel04] = useState(false);

  const { control, watch, reset, resetField } = useForm();

  const [watchNivel02, watchNivel03, watchNivel04, watchSku] = watch([
    "nivel02",
    "nivel03",
    "nivel04",
    "sku",
  ]);

  const handleButtonClick = (setState: any) => {
    setState(false);

    setTimeout(() => {
      setState(true);
    }, 1000);
  };

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel02);

    resetField("nivel03");
  }, [watchNivel02, resetField]);

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel03);

    resetField("nivel04");
  }, [watchNivel03, resetField]);

  useEffect(() => {
    handleButtonClick(setIsOpenSelectNivel04);

    resetField("sku");
  }, [watchNivel04, resetField]);

  const history = useHistory();
  const { addToast } = useToast();

  const errorRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);

  const openError = useCallback(() => {
    errorRef.current.toggle();
  }, []);

  const openLoading = useCallback((message: string, promise: any) => {
    loadingRef.current.open(message, promise);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setYear("");
      setMonth("");
      setDates([]);
      setSceneryAlias("");
      // setNivel01([]);
      // setNivel02([]);
      // setNivel03([]);
      // setNivel04([]);
      // setNivel05([]);
      // setSkuOpt([]);
    }
  }, [isOpen]);

  const createDate = useCallback(() => {
    if (month && year) {
      setDates((current) => {
        const newDate = `${year}/${month}`;
        setYear("");
        setMonth("");
        if (current.includes(newDate)) return current;
        if (current.length === 3) current.shift();
        return [...current, newDate];
      });
    }
  }, [month, year]);
  useEffect(createDate, [createDate]);

  const deleteDate = useCallback((date: string) => {
    setDates((current) => current.filter((d) => d !== date));
  }, []);

  const allFiltersSelected = useMemo(() => {
    return {
      nivel01: ["IN NATURA"],
      nivel02: watchNivel02
        ? watchNivel02.map((item: OptionProps) => item.value)
        : null,
      nivel03: watchNivel03
        ? watchNivel03.map((item: OptionProps) => item.value)
        : null,
      nivel04: watchNivel04
        ? watchNivel04.map((item: OptionProps) => item.value)
        : null,
      nivel05: null,
      sku: watchSku ? watchSku.map((item: OptionProps) => item.value) : null,
    };
  }, [watchNivel02, watchNivel03, watchNivel04, watchSku]);

  const createScenery = useCallback(() => {
    if (!sceneryAlias || dates.length === 0) {
      return addToast({
        type: "error",
        title: "Erro",
        description: "Todos os campos são obrigatórios.",
      });
    }

    const monthArr = dates.map((date) => {
      return date.split("/").join("");
    });

    const req = {
      sceneryAlias,
      month: monthArr,
    };

    openLoading(
      "Criando cenário...",
      api
        .post("/price/service-orders/scenery", {
          ...req,
          filters: {
            ...allFiltersSelected,
          },
        })
        .then((res) => {
          history.push({
            pathname: `/price-base/new-scenery/${res.data.data}/A`,
            state: { sceneryMonths: monthArr },
          });
        })
        .catch(() => {
          openError();
        })
    );
  }, [
    addToast,
    allFiltersSelected,
    dates,
    history,
    openError,
    openLoading,
    sceneryAlias,
  ]);

  function toggleModal() {
    reset();
    toggleOpen();
  }

  return (
    <>
      <Button onClick={toggleModal}>Criar Cenário</Button>
      <Modal
        open={isOpen}
        setOpen={toggleModal}
        containerStyle={{ width: "700px" }}
        children={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
            }}
          >
            <span
              style={{
                fontSize: "2.5rem",
                fontWeight: "bolder",
                color: "#003b74",
              }}
            >
              Criar Cenário (IN NATURA)
            </span>
            <Input
              name="input"
              label="Nome"
              value={sceneryAlias}
              onChange={(e) => setSceneryAlias(e.target.value)}
            />
            <p
              style={{
                marginTop: "1.5rem",
                color: "#003b74",
                fontSize: "1.3rem",
                fontWeight: "bolder",
              }}
            >
              Cenário base
            </p>
            <Inputs>
              <Autocomplete
                label="Mês"
                value={month}
                options={monthOpt}
                setValue={setMonth}
              />
              <Autocomplete
                label="Ano"
                value={year}
                options={yearsOpt}
                setValue={setYear}
              />
            </Inputs>
            <Dates>
              {dates.map((date, i) => (
                <div
                  key={date + i}
                  style={{
                    marginTop: "2rem",
                    width: "auto",
                    padding: "0.5rem 1rem",
                    border: "1px solid gray",
                    borderRadius: "12px",
                    fontSize: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {date}
                  <DeleteSvg
                    style={{
                      color: "red",
                      fontSize: "1.5rem",
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteDate(date)}
                  />
                </div>
              ))}
            </Dates>
            <p
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.25rem",
                color: "#003b74",
                fontSize: "1.3rem",
                fontWeight: "bolder",
              }}
            >
              Níveis
            </p>
            <Container style={{ marginBottom: "1rem" }}>
              <Controller
                name="nivel02"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <AsyncSelectPaginated
                    name={name}
                    isRequired
                    label="Nivel02"
                    serviceProps={{
                      method: "POST",
                      url: "/material/list/all/filters",
                      data: {
                        field: "nivel02",
                        type: "natura",
                      },
                    }}
                    mapOptions={(item) => ({
                      label: item.nivel02,
                      value: item.nivel02,
                    })}
                    isMulti
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {isOpenSelectNivel02 ? (
                <Controller
                  name="nivel03"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <AsyncSelectPaginated
                      name={name}
                      label="Nivel03"
                      serviceProps={{
                        method: "POST",
                        url: "/material/list/all/filters",
                        filters:
                          watchNivel02 &&
                          watchNivel02.length > 0 &&
                          watchNivel02.map((item: any) => item.value),
                        data: {
                          field: "nivel03",
                          type: "natura",
                        },
                      }}
                      mapOptions={(item) => ({
                        label: item.nivel03,
                        value: item.nivel03,
                      })}
                      isMulti
                      onChange={onChange}
                      value={value}
                      isReadOnly={
                        !isOpenSelectNivel02 ||
                        !watchNivel02 ||
                        watchNivel02?.length === 0
                      }
                    />
                  )}
                />
              ) : (
                <AsyncSelectPaginated
                  label="Nivel03"
                  name="default"
                  isReadOnly
                />
              )}
            </Container>
            <Container style={{ marginBottom: "1rem" }}>
              {isOpenSelectNivel03 ? (
                <Controller
                  name="nivel04"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <AsyncSelectPaginated
                      name={name}
                      label="Nivel04"
                      serviceProps={{
                        method: "POST",
                        url: "/material/list/all/filters",
                        filters:
                          watchNivel03 &&
                          watchNivel03.length > 0 &&
                          watchNivel03.map((item: any) => item.value),
                        data: {
                          field: "nivel04",
                          type: "natura",
                        },
                      }}
                      mapOptions={(item) => ({
                        label: item.nivel04,
                        value: item.nivel04,
                      })}
                      isMulti
                      onChange={onChange}
                      value={value}
                      isReadOnly={
                        !isOpenSelectNivel03 ||
                        !watchNivel03 ||
                        watchNivel03?.length === 0
                      }
                    />
                  )}
                />
              ) : (
                <AsyncSelectPaginated
                  label="Nivel04"
                  name="default"
                  isReadOnly
                />
              )}
              {isOpenSelectNivel04 ? (
                <Controller
                  name="sku"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <AsyncSelectPaginated
                      name={name}
                      label="SKU"
                      serviceProps={{
                        method: "POST",
                        url: "/material/list/all/filters",
                        filters:
                          watchNivel04 &&
                          watchNivel04.length > 0 &&
                          watchNivel04.map((item: any) => item.value),
                        data: {
                          field: "sku",
                          type: "natura",
                        },
                      }}
                      mapOptions={(item) => ({
                        label: +item.sku,
                        value: item.sku,
                      })}
                      isMulti
                      onChange={onChange}
                      value={value}
                      isReadOnly={
                        !isOpenSelectNivel04 ||
                        !watchNivel04 ||
                        watchNivel04?.length === 0
                      }
                    />
                  )}
                />
              ) : (
                <AsyncSelectPaginated label="SKU" name="default" isReadOnly />
              )}
            </Container>
            <Button
              onClick={createScenery}
              containerStyle={{ marginTop: "2rem" }}
            >
              Criar
            </Button>
            <LoadingModal ref={loadingRef} />
            <AlertModal
              ref={errorRef}
              type="error"
              message="Erro ao criar, tente novamente."
            />
          </Box>
        }
      />
    </>
  );
};

export default ButtonNewScenery;
