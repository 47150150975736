import { useCallback, useState } from "react";

import api from "../../../../../../../../services/api";
import { useAuth } from "../../../../../../../../contexts/AuthContext";

interface IProps {
  row: any;
}

const Checkbox: React.FC<IProps> = ({ row }) => {
  const { isUserSupport } = useAuth();

  const [father, setFather] = useState(!!row.father);

  const onChange = useCallback(
    (e) => {
      const url = "/param/update/relativity-channel";
      const req = {
        region: row.region,
        channel: row.channel,
        aggregate_channel: row.aggregate_channel,
        father: !father,
      };

      api.post(url, req).then(() => {
        setFather((current) => !current);
      });
    },
    [row, father]
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
      <input
        type="checkbox"
        checked={father}
        onChange={onChange}
        disabled={isUserSupport}
      />
      {row.channel}
    </div>
  );
};

export default Checkbox;
