import moment from "moment";

import api from "../../../../../services/api";

const requester = (user: any) => {
  return `${user.firstName} ${user.lastName}`;
};

const base_origin = (so: any) => {
  return so.condition && so.level
    ? `${so.condition}-${so.level.slice(so.level.length - 2, so.level.length)}`
    : "";
};

const filial = (name: string) => {
  return name?.replace(/,/g, "; ");
};

const date_request = (date: Date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

export const getTableData = (callback: (args1: any) => void, request: any) => {
  const { page, limit } = request;

  api
    .get(`price/list/service-orders?page=${page}&limit=${limit}`)
    .then((response: any) => {
      const formattedData = response.data.data.service_orders.map((so: any) => {
        return {
          id: so.id,
          requester: requester(so.users.creation.user),
          base_origin: base_origin(so),
          filial: filial(so.filial.name),
          date_request: date_request(so.users.creation.date),
          adicionais: so.adicionais,
          status: so.order_status,
          edit: "-",
          observation: so.reason || "-",
          base: so,
        };
      });

      callback({
        error: false,
        total: response.data.data.total,
        totalPages: response.data.data.totalPages,
        tableData: formattedData,
      });
    })
    .catch((error) => {
      return {
        error: false,
        message: error,
      };
    });
};
