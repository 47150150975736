import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fafafa;

  .MuiCircularProgress-colorPrimary {
    color: #003b74;
  }
`;
