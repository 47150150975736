import { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronLeft,
  FaCopy,
  FaTimesCircle,
} from "react-icons/fa";

import Button from "../../../../../components/Button";

import * as S from "./styles";

interface IProps {
  i: number;
  validation: { label: string; param: string };
  validationsResults: {
    label: string;
    success: boolean;
    message: string;
  }[][];
}

const ValidationStep: React.FC<IProps> = ({
  i,
  validation,
  validationsResults,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const results = useMemo(() => {
    return validationsResults?.[i];
  }, [i, validationsResults]);

  const status = useMemo(() => {
    if (!results) return "loading";
    if (results.find((validation) => !validation.success)) return "error";
    return "success";
  }, [results]);

  const StatusIcon = useMemo(() => {
    if (status === "loading") return <CircularProgress size={20} />;
    if (status === "error") return <FaTimesCircle color="red" size={20} />;
    return <FaCheckCircle color="green" size={20} />;
  }, [status]);

  return (
    <S.Container key={validation.param}>
      <S.Header>
        <div>{StatusIcon}</div>
        <S.StepLabel>
          Passo {i + 1}: {validation.label}{" "}
          {results?.length > 0 &&
            `(${results.length} teste${
              results.length > 1 ? "s" : ""
            } realizado${results.length > 1 ? "s" : ""})`}
        </S.StepLabel>
        {results && (
          <S.ExpandButtonContainer
            onClick={() => setIsExpanded((current) => !current)}
          >
            <span>{isExpanded ? "Ocultar detalhes" : "Mostrar detalhes"}</span>
            {isExpanded ? <FaChevronDown /> : <FaChevronLeft />}
          </S.ExpandButtonContainer>
        )}
      </S.Header>
      {isExpanded &&
        results?.map((test, j) => (
          <S.Results key={j}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              <S.ResultLabel color={test.success ? "green" : "red"}>
                Teste {i + 1}.{j + 1}
                {") "}
                {test.label}
              </S.ResultLabel>
              {!test.success && (
                <Button
                  width="10rem"
                  containerStyle={{ height: "2rem", fontSize: "1.1rem" }}
                  onClick={() => {
                    const split = test.message.split(":");

                    navigator.clipboard.writeText(split[2] || split[1]);

                    setIsCopied(true);

                    setTimeout(() => {
                      setIsCopied(false);
                    }, 3000);
                  }}
                >
                  <FaCopy style={{ marginRight: "1ch" }} />
                  {isCopied ? "Copiado!" : "Copiar chaves"}
                </Button>
              )}
            </div>
            <S.ResultMessage>
              <b>Resposta: </b>
              {test.message}
            </S.ResultMessage>
          </S.Results>
        ))}
    </S.Container>
  );
};

export default ValidationStep;
