import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import Input from "../../Input";

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
  gotoPage,
}) => {
  const [value, setValue] = useState(globalFilter || "");

  useEffect(() => {
    gotoPage(0);
    setCurrentPage(0);
  }, [value, gotoPage, setCurrentPage]);

  return (
    <>
      <Input
        // icon={<SearchIcon />}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value); // Set undefined to remove the filter entirely
        }}
        name="global"
        placeholder={`Buscar`}
        inputProps={{ "aria-label": "search" }}
        containerStyle={{
          width: "200px",
          height: "4rem",
          border: "none",
          background: "#F2F3F5",
          borderRadius: "200px",
        }}
      />
    </>
  );
};

GlobalFilter.propTypes = {
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
