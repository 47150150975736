import styled from "styled-components";

export const Title = styled.h1`
  color: #003b74;
  font-weight: 500;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 2rem;
`;

interface ITableButtonProps {
  selected: boolean;
}

export const TableButton = styled.div<ITableButtonProps>`
  cursor: pointer;
  user-select: none;

  height: 30px;
  margin: 2rem 0;
  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  border-radius: 8px;

  color: ${({ selected }) => (selected ? "#003b74" : "#808080")};
  border: ${({ selected }) => `1px solid ${selected ? "#003b74" : "#E8E8E8"}`};
`;
