import UploadButton from "./UploadButton";
import SelectConditionNivel from "./SelectConditionNivel";
import DownloadExampleButton from "./DownloadExampleButton";

import { Container } from "./styles";

const Header: React.FC = () => {
  return (
    <Container>
      <p>Escolha a opção de upload excel (.xlsx)</p>
      <DownloadExampleButton />
      <SelectConditionNivel />
      <UploadButton />
    </Container>
  );
};

export default Header;
