import {
  FaCheckDouble as UploadActiveSku,
  FaFileSignature as UploadUpdateLinesSvg,
  FaFileDownload as DownloadBaseSvg,
} from "react-icons/fa";

import { IUploadOption } from "../../../../components/UploadParam/types";

export const options: {
  [key: string]: IUploadOption;
} = {
  "Volume M + 1": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-volume",
    exportEndpoint: "/param/export?param=volume",
    roleType: "natura",
  },
  Mercado: {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-mercado",
    exportEndpoint: "/param/export?param=mercado",
    roleType: "natura",
  },
  Deflator: {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-deflator",
    exportEndpoint: "/param/export?param=deflator",
    roleType: "natura",
  },
  "Relatividade Canal": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-relatividade",
    exportEndpoint: "/param/export?param=relatividade_canal",
    roleType: "natura",
    filters: [
      {
        method: "get",
        label: "UF",
        accessor: "uf",
        endpoint: "/price/list/regions",
        mapOptions: (resData: any) => {
          return resData.regions.map((i: any) => ({
            key: i.id,
            label: { value: i.id, label: i.name },
          }));
        },
        allowSelectAll: true,
      },
      {
        method: "get",
        label: "Canal",
        accessor: "canal",
        endpoint: "/price/list/channels",
        mapOptions: (resData: any) => {
          return resData.channels.map((i: any) => ({
            key: i.channel,
            label: { value: i.channel, label: i.channel },
          }));
        },
        allowSelectAll: true,
      },
      {
        method: "post",
        label: "Sku",
        accessor: "sku",
        endpoint: "/material/list/natura",
        mapOptions: (resData: any) => {
          return resData.material.map((i: any) => ({
            key: +i.sku + "",
            label: { value: +i.sku + "", label: +i.sku + "" },
          }));
        },
      },
      {
        method: "get",
        label: "Categoria",
        accessor: "categoria",
        endpoint: "/material/natura/category",
        mapOptions: (resData: any) => {
          return resData.material.map((i: any) => ({
            key: i.name,
            label: { value: i.name, label: i.name },
          }));
        },
      },
    ],
  },
  "Base Sp": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-base",
    exportEndpoint: "/param/export?param=base_sp",
    roleType: "natura",
  },
  "Código Capitão": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-codigo-capitao",
    exportEndpoint: "/param/export?param=codigo_capitao",
    roleType: "natura",
  },
  Elasticidade: {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-elasticidade",
    exportEndpoint: "/param/export?param=elasticidade",
    roleType: "natura",
  },
  "Adicional Financeiro Médio": {
    buttons: {
      uploadSvg: UploadUpdateLinesSvg,
      uploadText: "Upload atualizar registros",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar base de dados",
    },
    endpoint: "/param/upload-price-final",
    exportEndpoint: "/param/export?param=adicional_financeiro_medio",
    roleType: "natura",
  },
  Materiais: {
    buttons: {
      uploadSvg: UploadActiveSku,
      uploadText: "Upload materiais ativos",
      downloadSvg: DownloadBaseSvg,
      downloadText: "Exportar materiais ativos",
    },
    endpoint: "/param/upload-material?role=natura",
    exportEndpoint: "/param/export?param=materiais",
    roleType: "natura",
  },
};
