import { useCallback, useEffect, useState } from "react";
import { FaInfoCircle as ErrorSvg } from "react-icons/fa";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import api from "../../../../../../../../services/api";
import Paper from "../../../../../../../../components/Paper";
import { Autocomplete } from "../../../../../../../../components/AutoComplete";

import { columns } from "./constants";
import { Table, Error } from "./styles";

interface IProps {
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setCaptain: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<IProps> = ({ setRows, setCaptain }) => {
  const [sku, setSku] = useState("");
  const [skuOpt, setSkuOpt] = useState<any>([]);

  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setCaptain(sku);
  }, [sku, setCaptain]);

  const getSku = useCallback(() => {
    api.get("/material/list").then((res) => {
      setSkuOpt(
        res.data.material.map(
          (item: any, index: number) =>
            +item.sku && {
              key: index + 1,
              label: +item.sku,
              value: +item.sku,
            }
        )
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  const getInfo = useCallback(() => {
    setRows([]);
    setDesc("");
    setError("");

    if (!sku) return;

    api.post("/param/info/sku-capitan", { sku }).then((res) => {
      if (res.data.message) {
        setError(res.data.message);
      } else {
        setDesc(res.data.response.WhoIsCapitanDescription);
        setRows(res.data.response.data);
      }
    });
  }, [sku, setRows]);
  useEffect(getInfo, [getInfo]);

  return (
    <Paper containerStyle={{ padding: "2rem", marginBottom: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
            </TableCell>
            <TableCell>{desc}</TableCell>
            <TableCell>
              {error && (
                <Error>
                  <ErrorSvg /> {error}
                </Error>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Header;
