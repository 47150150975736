import { scenValue } from "../../../../utils/scenValue";

export const formatData = (data: any[]) => {
  return data.map((row: any) => ({
    ...row,
    volume_medio: scenValue(row.volume_medio),
    rob: scenValue(row.rob),
    rob_goal: scenValue(row.rob_goal),
    rob_challenge: scenValue(row.rob_challenge),
    preco_base: scenValue(row.preco_base),
    volume_m1: scenValue(row.volume_m1),
    price_tecn: scenValue(row.price_tecn),
    additional: scenValue(row.additional),
    mercado: ((row.mercado * 100).toFixed(3) + "").replace(".", ","),
    deflator: row.deflator.replace(".", ","),
    elasticity: row.elasticity.replace(".", ","),
    // Inputs
    price_goal: scenValue(row.price_goal, true).replace(".", ","),
    price_challenge: scenValue(row.price_challenge, true).replace(".", ","),
  }));
};
