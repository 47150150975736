import { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Paper from "../../../components/Paper";
import Button from "../../../components/Button";
import PageContainer from "../../../components/PageContainer";

import { tables } from "./constants";
import { Header, Inputs } from "./components";
import { Title, Buttons, TableButton } from "./styles";

const PriceBaseParameters: React.FC = () => {
  const history = useHistory();

  const [inputs, setInputs] = useState({});
  const [selected, setSelected] = useState(tables[0]);

  useEffect(() => {
    setInputs({});
  }, [selected]);

  const onEdit = useCallback(() => {
    // console.log("inputs", inputs);

    const tableCoded = window.btoa(window.btoa(selected));
    const inputsCoded = window.btoa(window.btoa(JSON.stringify(inputs)));
    history.push(`/price-base/parameters/${tableCoded}@${inputsCoded}`);
  }, [inputs, history, selected]);

  return (
    <PageContainer>
      <Header />
      <Paper containerStyle={{ padding: "2rem" }}>
        <Title>Escolha qual table voce queira alterar</Title>
        <Buttons>
          {tables.map((table) => (
            <TableButton
              key={table}
              selected={selected === table}
              onClick={() => setSelected(table)}
            >
              {table}
            </TableButton>
          ))}
        </Buttons>
        <Inputs table={selected} setInputs={setInputs} />
        <Button width="200px" onClick={onEdit}>
          Editar
        </Button>
      </Paper>
    </PageContainer>
  );
};

export default PriceBaseParameters;
