import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { AiFillEye as EditSvg } from "react-icons/ai";

import { useAuth } from "../../../../contexts/AuthContext";

const Edit: React.FC<any> = ({ row }) => {
  const history = useHistory();
  const { isUserSupport } = useAuth();

  const onEdit = useCallback(() => {
    history.push({
      pathname: `/price-base/new-scenery/${row.base.scenery_id}/A`,
      state: {
        sceneryStatus: row.status,
        sceneryMonths: row.base.month,
        sceneryViewOnly: isUserSupport || !/editando/i.test(row.status),
      },
    });
  }, [history, isUserSupport, row.base.month, row.base.scenery_id, row.status]);

  return (
    <EditSvg
      onClick={onEdit}
      style={{ fontSize: "1.8rem", color: "#003b74", cursor: "pointer" }}
    />
  );
};

export default Edit;
