import { useCallback } from "react";
import { Box } from "@material-ui/core";
import { FaTrash as DeleteSvg } from "react-icons/fa";

import api from "../../../../services/api";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import useToggle from "../../../../hooks/useToggle";

const Delete: React.FC<any> = ({ row }) => {
  const [isOpen, toggleOpen] = useToggle();

  const onDelete = useCallback(() => {
    api.delete(`/price/list/service-orders/nielsen/${row.id}`).then(() => {
      window.location.reload();
    });
  }, [row.id]);

  return (
    <>
      <DeleteSvg
        onClick={toggleOpen}
        style={{ fontSize: "1.25rem", color: "red", cursor: "pointer" }}
      />
      <Modal
        open={isOpen}
        setOpen={toggleOpen}
        containerStyle={{ width: "300px" }}
        children={
          <Box
            sx={{
              paddingTop: "1rem",
              height: "120px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              marginTop: "2rem",
              color: "red",
              fontSize: "1.2rem",
            }}
          >
            <p>Essa ação não poderá ser desfeita!</p>
            <p>ID: {row.id}</p>
            <Button onClick={onDelete}>Excluír</Button>
          </Box>
        }
      />
    </>
  );
};

export default Delete;
