// Used to format values in all scenery steps (In-Natura & Processados)
import { value } from "./masks";

const toFixed = (val: any) => {
  if (!val) return "";
  return val.toString().match(/^[-]?\d+(?:\.\d{0,2})?/)?.[0] || val;
};

export const scenValue = (val: string | number, isInput = false) => {
  if (isInput) return toFixed(val);
  return value(toFixed(val));
};
