import styled from "styled-components";

export const ModalContainer = styled.div`
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Inputs = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
`;
