import { HTMLAttributes } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { InputIconStyled, SelectWrapperStyled } from "./styles";

interface OptionProps {
  key: any;
  value: any;
  label: string;
}

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options: OptionProps[];
  value: string;
  width?: string;
  filter?: boolean;
}

const Select = ({
  label,
  options,
  width = "100%",
  filter = false,
  ...rest
}: SelectProps) => {
  return (
    <SelectWrapperStyled
      label={label}
      width={width}
      filter={filter}
      style={rest.style}
    >
      {label && <label>{label}</label>}
      <select {...rest}>
        {options.map((option) => (
          <option key={option.key} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <InputIconStyled>
        <IoMdArrowDropdown size={32} />
      </InputIconStyled> */}
    </SelectWrapperStyled>
  );
};

export default Select;
