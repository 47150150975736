import { useState, useEffect, useMemo, memo } from "react";
import moment from "moment";

import api from "../../../services/api";
import LoadingScreen from "../../../components/LoadingScreen";
import { scenValue } from "../../../utils/scenValue";

import Info from "./Info";
import Table from "./Table";
import Buttons from "./Buttons";
import generateColumns from "./generateColumns";

const ContainerModal: React.FC<any> = ({ clickedRow, updatePageState }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const { id, level, condition } = clickedRow.original.base;

    const request = { id, level, condition };

    api
      .post("/price/list/service-orders/approval/materials", request)
      .then((response: any) => {
        setTableData(
          response.data.items.map((item: any) => {
            return {
              id: item.id,
              material: item.material || "-",
              hierarquia: item.hierarquia || "-",
              grupo_preco: item.grupo_preco || "-",
              regiao_uf: item.regiao_uf || "-",
              regiao_vendas: item.regiao_vendas || "-",
              canal_pricing: item.canal_pricing || "-",
              grupo_cliente: item.grupo_cliente || "-",
              raiz_cnpj: item.raiz_cnpj || "-",
              codigo_cliente: item.codigo_cliente || item.cliente || "-",
              lista_preco: item.lista_preco || "-",
              cond_expedicao: item.cond_expedicao || item.cond_expecicao || "-",
              categoria_item: item.categoria_item || "-",
              tipo_doc_vendas: item.tipo_doc_vendas || "-",
              adicionais: clickedRow.original.base.adicionais || "-",
              newPrice: item.new_value ? scenValue(item.new_value) : "-",
              currentPrice: item.valor?.trim().replace(".", ",") || "-",
              y500n1_value: item.y500n1_value?.trim().replace(".", ",") || "-",
              startDate:
                moment.utc(item.data_ini_mat).format("DD/MM/YYYY") || "-",
              endDate:
                moment.utc(item.data_fim_mat).format("DD/MM/YYYY") || "-",
            };
          })
        );
        setLoading(false);
      });
  }, [clickedRow]);

  const columns = useMemo(() => {
    return generateColumns(clickedRow, tableData);
  }, [clickedRow, tableData]);

  if (loading) {
    return (
      <div style={{ height: 500 }}>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <Info clickedRow={clickedRow} />
      <Buttons
        columns={columns}
        tableData={tableData}
        clickedRow={clickedRow}
        updatePageState={updatePageState}
      />
      <Table
        columns={columns}
        tableData={tableData}
        setTableData={setTableData}
      />
    </>
  );
};

export default memo(ContainerModal);
