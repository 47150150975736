import React from "react";
import "jspdf-autotable";
import XLSX from "xlsx";
import Papa from "papaparse";
import JsPDF from "jspdf";
import { CssBaseline } from "@material-ui/core";
import { MdVisibility } from "react-icons/md";
import { useExportData } from "react-table-plugins";
import {
  Checkbox,
  Table as MUITable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useRowState,
} from "react-table";

import TableToolbar from "./TableToolbar";

import Status from "../../Status";

import { ExportText, ExportButton, TableCellStyled } from "../styles";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Checkbox ref={resolvedRef} {...rest} color="primary" />;
  }
);

function getExportFileBlob({ columns, data, fileType, fileName }) {
  for (let i = 0; i < data.length; i++) {
    data[i].pop();
  }
  columns.pop();

  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new JsPDF(orientation, unit, size);

    doc.setFontSize(15);

    doc.autoTable({
      head: [headerNames],
      body: data,
      startY: 50,
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

export default function EnhancedTable({
  data,
  columns,
  filters,
  openModal,
  showToolbar,
  toolBarButtons,
  showExportOptions,
  showSelectOptions,
  toolBarLeftComponents,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    exportData,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState,
    useExportData,
    (hooks) => {
      if (showSelectOptions) {
        hooks.allColumns.push((columns) => {
          return [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                );
              },
              Cell: ({ row }) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
              width: "8%",
            },
            ...columns,
          ];
        });
      }
    }
  );

  const verifyStatus = (cell) => {
    if (cell.column.id === "status") {
      if (cell.value.toUpperCase().search("AGUARDANDO APROVAÇÃO") >= 0) {
        return "aguardando";
      } else if (cell.value.toUpperCase() === "APROVADO") {
        return "aprovado";
      } else {
        return "reprovado";
      }
    }
  };

  return (
    <>
      {/* <CssBaseline /> */}
      {showToolbar && (
        <TableToolbar
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter || ""}
          filters={filters}
          buttons={toolBarButtons}
          toolBarLeftComponents={toolBarLeftComponents}
          showExportOptions={showExportOptions}
          exportOptions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ExportText>Exportar como: </ExportText>
              <ExportButton
                onClick={() => {
                  exportData("csv", true);
                }}
              >
                CSV
              </ExportButton>
              <ExportButton
                onClick={() => {
                  exportData("xlsx", true);
                }}
              >
                EXCEL
              </ExportButton>
              <ExportButton
                onClick={() => {
                  exportData("pdf", true);
                }}
              >
                PDF
              </ExportButton>
            </div>
          }
        />
      )}
      <TableContainer>
        <MUITable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.Header} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCellStyled key={row.id + i} {...cell.getCellProps()}>
                      {cell.column.id === "status" ? (
                        <Status status={cell.value}>
                          {cell.render("Cell")}
                        </Status>
                      ) : cell.column.id === "edit" ? (
                        <MdVisibility
                          size={20}
                          color="#8e8d8d"
                          style={{ margin: "0 auto", cursor: "pointer" }}
                          onClick={() => openModal(row)}
                        />
                      ) : (
                        cell.render("Cell")
                      )}
                    </TableCellStyled>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
    </>
  );
}
