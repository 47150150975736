import { useMemo } from "react";

import TableSmallViewOnly from "../../../../../../components/TableSmallViewOnly";
import { getColumns } from "../../../actions";
import { usePriceManagement } from "../../../../hooks";

const EditionsTable: React.FC<any> = ({ checkedOnlyRows }) => {
  const { tabRequest } = usePriceManagement();

  const columns = useMemo(() => getColumns(tabRequest, true), [tabRequest]);

  return (
    <TableSmallViewOnly
      data={checkedOnlyRows}
      columns={columns}
      rowsPerPage={5}
    />
  );
};

export default EditionsTable;
