import { memo } from "react";
import { IoPricetagsSharp } from "react-icons/io5";

import PageTitleComponent from "../../../../../components/PageTitle";

const PageTitle: React.FC<any> = () => {
  return <PageTitleComponent icon={<IoPricetagsSharp />} title="Processados" />;
};

export default memo(PageTitle);
