import styled from "styled-components";

import { Form as UForm } from "@unform/web";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fafafa;

  .MuiCircularProgress-colorPrimary {
    color: #003b74;
  }
`;

export const BackgroundBRF = styled.img`
  height: 100vh;
  width: 50vw;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  height: 100vh;
  width: 50vw;
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 60%;
`;

export const LogoImg = styled.img`
  width: 40%;
  object-fit: scale-down;
`;

export const Title = styled.h1`
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 2.4rem;
  color: #003b74;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: #003b74;
  font-weight: 500;

  text-align: center;
`;
