import { useCallback } from "react";
import { FaEdit as ParametersSvg } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import Button from "../../../../../../components/Button";
import PageTitle from "../../../../../../components/PageTitle";
import HeaderContainer from "../../../../../../components/HeaderContainer";

interface IProps {
  table: string;
}

const Header: React.FC<IProps> = ({ table }) => {
  const history = useHistory();

  const onBack = useCallback(() => {
    history.push("/processed/parameters");
  }, [history]);

  return (
    <HeaderContainer>
      <PageTitle
        title="Alteração de parâmetros"
        linkLabel={table}
        icon={<ParametersSvg />}
      />
      <Button
        width="160px"
        containerStyle={{ height: "40px" }}
        onClick={onBack}
      >
        Voltar
      </Button>
    </HeaderContainer>
  );
};

export default Header;
