import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 180px;
  }
`;

export const Divider = styled.div`
  height: 4rem;
  margin: 0 2rem;

  border-left: 1px solid #ddd7e6;
`;
