import { useEffect, useState, useCallback } from "react";

import Input from "../../../../../components/Input";
import { money } from "../../../../../utils/masks";
import { usePriceManagement } from "../../../hooks";

const CellInputPercent: React.FC<any> = ({ row, column, updateTableData }) => {
  const { tabRequest } = usePriceManagement();

  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(row.original.newPrice || "");
  }, [row.original.newPrice]);

  const validatedValue = useCallback((valueToValidate) => {
    let validatedValue: string | number = valueToValidate;

    if (!validatedValue || +validatedValue >= 1000 || +validatedValue <= 0) {
      validatedValue = 0;
      setValue("");
    }

    return validatedValue;
  }, []);

  const onBlur = useCallback(() => {
    if (value === row.original.newPrice) return;
    updateTableData(row.index, column.id, validatedValue(value));
  }, [row, column.id, updateTableData, validatedValue, value]);

  const onChange = useCallback(
    (e) => {
      const split = e.target.value.split(/;| /);

      if (split.length > 1) {
        const onlyValues = split
          .map((v: any) => v.replace(",", "."))
          .filter((v: any) => +v);

        let variation = 0;

        return onlyValues.forEach((v: string, i: number) => {
          if (
            column.filteredRows[row.index + i + variation]?.original
              .blockEditing
          ) {
            variation += 1;
          }

          updateTableData(
            row.index + i + variation,
            column.id,
            validatedValue(v)
          );
        });
      }

      return setValue(money(e.target.value));
    },
    [updateTableData, row.index, column, validatedValue]
  );

  const onPressEnter = useCallback((e) => {
    e.key === "Enter" && e.currentTarget.blur();
  }, []);

  if (tabRequest.requestId) {
    return <span>{row.original.newPrice || "-"}</span>;
  }

  if (row.original.blockEditing) {
    return <span>-</span>;
  }

  return (
    <Input
      type="number"
      name={row.original.id}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyPress={onPressEnter}
      maxLength={5}
      containerStyle={{
        width: "80px",
        height: "22px",
        padding: "0.2rem",
      }}
      inputStyle={{
        padding: "10px",
        width: "100%",
        color: "#003b74",
      }}
    />
  );
};

export default CellInputPercent;
