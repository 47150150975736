interface IExampleRow {
  [key: string]: string;
}

export const examples: { [key: string]: string } = {
  Filial: "MG0001",
  "Canal Pricing": "01",
  Categoria: "",
  Material: "496156",
  "Grupo de Cliente": "V4",
  "Raíz CNPJ": "25171034",
  Cliente: "101264",
  "Canal de Distribuição": "",
  Empresa: "2500",
  Região: "MG",
  "Lista de Preços": "31",
  "Condição de Expedição": "",
  "Documento de Venda": "B2",
  Hierarquia: "1101003",
  "Categoria de Itens": "Z101",
  "Novo Valor": "28,00",
  "Data Início": "01/03/2022",
  "Data Fim": "31/03/2022",
  "Grupo de Preço": "AQ",
};

export const getExampleRow = (header: string[]): IExampleRow[] => {
  const exampleRow: IExampleRow = {};

  header.forEach((col: string) => {
    exampleRow[col] = examples[col];
  });

  return [exampleRow];
};
