import { scenValue } from "../../../../utils/scenValue";

export const formatData = (data: any[]) => {
  return data.map((row: any) => ({
    ...row,
    ppc_min: scenValue(row.ppc_min),
    ppc_max: scenValue(row.ppc_max),
    ppc_medio: scenValue(row.ppc_medio),
    desc_acao: scenValue(row.desc_acao),
    mrg_valor: scenValue(row.mrg_valor),
    ppv_bruto: scenValue(row.ppv_bruto),
    st_efetiva: scenValue(row.st_efetiva),
    min_margin: scenValue(row.min_margin + ""),
    max_margin: scenValue(row.max_margin + ""),
    investiment: scenValue(row.investiment),
    ppv_liquido: scenValue(row.ppv_liquido),
    icms_efetiva: scenValue(row.icms_efetiva),
    ppv_final_kg: scenValue(row.ppv_final_kg),
    relatividade: scenValue(row.relatividade),
    encargo_medio: scenValue(row.encargo_medio),
    valor_imposto: scenValue(row.valor_imposto),
    max_margin_real: scenValue(row.max_margin_real + ""),
    min_margin_real: scenValue(row.min_margin_real + ""),
    ppc_min_unidade: scenValue(row.ppc_min_unidade),
    ppc_max_unidade: scenValue(row.ppc_max_unidade),
    ppv_bruto_c_add: scenValue(row.ppv_bruto_c_add),
    p1_tabela_unitario: scenValue(row.p1_tabela_unitario),
    ppv_final_unitario: scenValue(row.ppv_final_unitario),
    relatividade_canal: scenValue(row.relatividade_canal),
    margem_varejo_media: scenValue(row.margem_varejo_media),
    ppc_com_relatividade: scenValue(row.ppc_com_relatividade),
    p1_tabela_com_acao_kg: scenValue(row.p1_tabela_com_acao_kg),
    p1_tabela_com_acao_unitario: scenValue(row.p1_tabela_com_acao_unitario),

    // Inputs
    aumento_reducao: scenValue(row.aumento_reducao, true),
    ppc_com_relatividade_canal: scenValue(row.ppc_com_relatividade_canal, true),
  }));
};
