export const columns = [
  {
    Header: "Sku",
    accessor: "",
  },
  {
    Header: "Sigla",
    accessor: "",
  },
  {
    Header: "Descrição",
    accessor: "",
  },
  {
    Header: "Nível 01",
    accessor: "",
  },
  {
    Header: "Nível 02",
    accessor: "",
  },
  {
    Header: "Nível 03",
    accessor: "",
  },
  {
    Header: "Nível 04",
    accessor: "",
  },
  {
    Header: "Nível 05",
    accessor: "",
  },
  {
    Header: "Ativo",
    accessor: "",
  },
];
