import { useCallback, useEffect, useState } from "react";
import { FaInfoCircle as ErrorSvg } from "react-icons/fa";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import api from "../../../../../../../../services/api";
import Paper from "../../../../../../../../components/Paper";
import { Autocomplete } from "../../../../../../../../components/AutoComplete";

import { columns } from "./constants";
import { Table, Error } from "./styles";

interface IProps {
  setUf: React.Dispatch<React.SetStateAction<string>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setCaptain: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<IProps> = ({ setUf, setRows, setCaptain }) => {
  const [nielsen, setNielsen] = useState("");
  const [nielsenOpt, setNielsenOpt] = useState<any>([]);
  const [sku, setSku] = useState("");
  const [skuOpt, setSkuOpt] = useState<any>([]);

  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setUf(sku);
  }, [sku, setUf]);

  useEffect(() => {
    setCaptain(nielsen);
  }, [nielsen, setCaptain]);

  const getSku = useCallback(() => {
    api.get("/material/list").then((res) => {
      setSkuOpt(
        res.data.material.map(
          (item: any, index: number) =>
            +item.sku && {
              key: index + 1,
              label: +item.sku,
              value: +item.sku,
            }
        )
      );
    });
  }, []);
  useEffect(getSku, [getSku]);

  const getCategory = useCallback(() => {
    api.get("/param/list/nielsen-categories").then((res) => {
      setNielsenOpt(
        res.data.data.map((item: any, index: number) => ({
          key: index + 1,
          label: item,
          value: item,
        }))
      );
    });
  }, []);
  useEffect(getCategory, [getCategory]);

  const getInfo = useCallback(() => {
    setRows([]);
    setDesc("");
    setError("");

    if (!sku || !nielsen) return;

    api
      .post("param/list/nielsen-macro", {
        sku: sku,
        nielsen: nielsen,
      })
      .then((res) => {
        if (res.data.message) {
          setError(res.data.message);
        } else {
          setDesc(res.data.response.WhoIsCapitanDescription);
          setRows(res.data.response.data);
        }
      });
  }, [setRows, sku, nielsen]);
  useEffect(getInfo, [getInfo]);

  return (
    <Paper containerStyle={{ padding: "2rem", marginBottom: "2rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={nielsen}
                options={nielsenOpt}
                setValue={setNielsen}
              />
            </TableCell>
            <TableCell>{desc}</TableCell>
            <TableCell>
              {error && (
                <Error>
                  <ErrorSvg /> {error}
                </Error>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Header;
