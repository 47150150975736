import styled from "styled-components";
import { Table as MUITable } from "@material-ui/core";

export const Table = styled(MUITable)`
  thead tr {
    background-color: #f2f3f5;
  }

  tbody {
    tr,
    td {
      transition: background-color 0.3s;
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem 1rem;

    color: #003b74;
    font-size: 1rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :first-child,
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      width: 200px;
    }
  }

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    font-size: 1rem;
  }
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: red;

  svg {
    font-size: 18px;
    margin-right: 1ch;
  }
`;
